import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import Modal from "react-modal";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Slider from "react-slick";
import { getCountry, getState } from "../../../../redux/services/common";
import {
  Update_billing_detail,
  getAuthSettingDetail,
  getPlanFeatureActive,
  get_customer_all_cards,
} from "../../../../redux/services/AuthSettings";
import { clearStateAndPersistedData } from "../../../../redux/store";
import {
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import {
  BillingFrequencySvg,
  SavedCardsSvg,
  AddNewCardSvg,
  BillingInformationSvg,
  OrderSummarySvg,
} from "../../../../Images/UpdateSubscriptionSvgs";
import { customAlert } from "../../../../utils/alert";
import { reSetSubscription } from "../../../../redux/features/subscription";

const customModalStyles = {
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    zIndex: 1000,
  },
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    border: "none",
    maxWidth: "750px",
    width: "60%",
    background: "#fff",
    borderRadius: "8px",
    padding: "20px",
    height: "65%",
    zIndex: "99",
    transition: "all 1s ease-in-out",
  },
};
const UpdateSubscription = ({ amount1, modalIsOpen, setModalIsOpen }) => {
  const stripe = useStripe();
  const elements = useElements();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  var settings = {
    dots: false, // Enable pagination dots
    infinite: false, // Disable infinite loop to avoid repeating the card
    speed: 500, // Animation speed
    slidesToShow: 1, // Show one slide at a time
    slidesToScroll: 1, // Scroll one slide at a time
    centerMode: true, // Disable center mode (only applies to multiple items)
    focusOnSelect: true, // Focus on the selected card
    adaptiveHeight: true, // Adjust height to the content
  };

  const billingInfo = useSelector(
    (state) => state?.AuthSettings?.settingInfo?.billingPlan
  );
  const OrganizationDetail = useSelector(
    (state) => state?.AuthSettings?.settingInfo?.organization
  );
  //subscription plan detail
  const subscriptionPlan = useSelector((state) => state?.suscription?.planInfo);
  const subscriptionPlanDiscount = useSelector(
    (state) => state?.suscription?.planDiscount
  );
  const CountryData = useSelector((state) => state?.common?.CountryData);
  const stateData = useSelector((state) => state?.common?.StateData);
  const CustomerAllCard = useSelector(
    (state) => state?.AuthSettings?.CustomerAllCard
  );

  const [showBillingError, setShowBillingError] = useState(false);
  const [showMsg, setShowMsg] = useState({ expiry: true, country: true });
  const [countryId, setCountryID] = useState("");
  const [selectedPlan, setSelectedPlan] = useState(
    subscriptionPlan?.selectYear
  );
  // Update selectedPlan whenever subscriptionPlan changes
  const [planeAmount, setPlaneAmount] = useState(
    selectedPlan === 1
      ? subscriptionPlan?.yearlyPriceWithDiscount
      : subscriptionPlan?.monthAmount
  );
  const [totalAmount, setTotalAmount] = useState(planeAmount);

  const [cardNumberError, setCardNumberError] = useState(null);
  const [cvcError, setCvcError] = useState(null);
  const [expirationError, setExpirationError] = useState(null);
  const [complete, setComplete] = useState({
    cardNumber: false,
    cvc: false,
    expiration: false,
  });
  const [paymentMethodId, setPaymentMethodId] = useState(false);
  const [openAccordion, setOpenAccordion] = useState(0);
  const [openSubAccordion, setOpenSubAccordion] = useState(null);
  const [cardError, setCardError] = useState("");
  const [checkStepOne, setCheckStepOne] = useState(false);
  const [checkSelectCard, setcheckSelectCard] = useState(false);

  // First form instance
  const {
    register: register1,
    handleSubmit: handleSubmit1,
    formState: { errors: errors1 },
    setValue: setValue1,
    getValues: getValues1,
  } = useForm();

  // Second form instance
  const {
    register: register2,
    handleSubmit: handleSubmit2,
    formState: { errors: errors2 },
    setValue: setValue2,
    getValues: getValues2,
    clearErrors,
    setError,
  } = useForm();

  useEffect(() => {
    dispatch(get_customer_all_cards());
  }, []);

  useEffect(() => {
    setSelectedPlan(subscriptionPlan?.selectYear);
  }, [subscriptionPlan]);

  useEffect(() => {
    setPlaneAmount(
      selectedPlan === 1
        ? subscriptionPlan?.yearlyPriceWithDiscount
        : subscriptionPlan?.monthAmount
    );
  }, [selectedPlan]);

  //country and state api
  useEffect(() => {
    dispatch(getCountry());
    if (countryId) {
      dispatch(getState(countryId));
    }
  }, [countryId]);

  const calculateTotalAmount = () => {
    if (selectedPlan === 1) {
      const yearAmount = subscriptionPlan?.yearlyPriceWithDiscount;
      return yearAmount + planeAmount;
    } else if (selectedPlan === 0) {
      const monthAmount = subscriptionPlan?.monthAmount;
      return monthAmount + planeAmount;
    } else {
      return planeAmount;
    }
  };

  const handlePlanChange = (event) => {
    setSelectedPlan(parseInt(event.target.value));
  };
  const form = {
    no_of_licence: 0,
    total_amount: planeAmount,
    subscription_plan_id: subscriptionPlan?.id,
    subscription_id: (billingInfo && billingInfo?.id) || "",
    billing_frequency_type: selectedPlan,
    country_id: getValues2("country_id"),
    State_id: getValues2("State_id"),
    City: getValues2("City"),
  };
  const onSubmit = async () => {
    if (paymentMethodId !== "") {
      form.paymentMethodId = paymentMethodId;
      setCardError("");
      try {
        const response = await dispatch(Update_billing_detail(form));
        if (response?.payload?.status) {
          dispatch(getAuthSettingDetail());
          dispatch(getPlanFeatureActive());
          onClose();
          localStorage.clear();
          clearStateAndPersistedData();
          navigate("/login");
        }
      } catch (error) {
        console.error("Error during signup:", error);
      }
    } else {
      setcheckSelectCard(true);
      setCardError("Please select card ");
    }
  };
  const AddNewCard = async () => {
    const formValues = getValues2();
    let errors = {};
    try {
      const resp = await stripe.createPaymentMethod({
        type: "card",
        billing_details: {
          name: OrganizationDetail?.organization_name,
          address: {
            city: formValues.City || "", // Ensure required fields exist
          },
        },
        card: elements.getElement(CardNumberElement),
      });
      if (!formValues.country_id) {
        errors.country_id = { type: "manual", message: "Country is required" };
      }
      if (!formValues.State_id) {
        errors.State_id = { type: "manual", message: "State is required" };
      }
      if (!formValues.City) {
        errors.City = { type: "manual", message: "City is required" };
      }
      if (!formValues.address1) {
        errors.address1 = { type: "manual", message: "Address is required" };
      }
      if (Object.keys(errors).length > 0) {
        Object.entries(errors).forEach(([key, value]) => setError(key, value));
        return;
      }

      if (resp?.paymentMethod) {
        setPaymentMethodId(resp?.paymentMethod?.id);
        dispatch(get_customer_all_cards());
        customAlert("Card details saved successfully", "success");
      } else {
        customAlert(resp.error.message);
        console.error("Payment Method Error: ", resp.error.message);
      }
    } catch (error) {
      console.error("Stripe API Error: ", error);
      customAlert("Something went wrong. Please try again.", "error");
    }
  };

  const onClose = () => {
    setOpenAccordion(0);
    setModalIsOpen(false);
    setValue1("card_number", "");
    setValue1("Expiry", "");
    setValue1("cvc", "");
    setSelectedPlan();
    setCardNumberError(null);
    setExpirationError(null);
    setCvcError(null);
    clearErrors("country_id");
    clearErrors("State_id");
    clearErrors("City");
    clearErrors("address1");
    setValue2("country_id", "");
    setValue2("State_id", "");
    setValue2("City", "");
    setValue2("address1", "");
    setCheckStepOne(false);
    dispatch(reSetSubscription());
  };

  const Logout = () => {
    // setShow(true);
    window.location.reload();
    localStorage.clear();
    clearStateAndPersistedData();
    navigate("/login");
  };

  const handleCardNumberChange = (event) => {
    setComplete((prev) => ({ ...prev, cardNumber: event.complete }));
    setCardNumberError(event.error ? event.error.message : null);
  };

  const handleCvcChange = (event) => {
    setComplete((prev) => ({ ...prev, cvc: event.complete }));
    setCvcError(event.error ? event.error.message : null);
  };

  const handleExpirationChange = (event) => {
    setComplete((prev) => ({ ...prev, expiration: event.complete }));
    setExpirationError(event.error ? event.error.message : null);
  };

  // Toggle accordion open/close
  const handleToggle = (index) => {
    if (openAccordion === index) {
      setOpenAccordion(null); // Close if the same accordion is clicked
    } else {
      setOpenAccordion(index); // Open the selected accordion
    }
  };

  // Toggle subaccordion open/close
  const handleSubToggle = (index) => {
    if (openSubAccordion === index) {
      setOpenSubAccordion(null); // Close if the same accordion is clicked
    } else {
      setOpenSubAccordion(index); // Open the selected accordion
    }
  };

  const sortedCountryData = CountryData
    ? [
        CountryData.find((elem) => elem.iso2 === "US"),
        ...CountryData.filter((elem) => elem.iso2 !== "US"),
      ]
    : [];

  let monthAmountYerDiscount = subscriptionPlan?.yearlyPriceWithDiscount / 12;

  const handleSliderSelect = (id) => {
    if (paymentMethodId === id) {
      // Deselect if the same card is clicked again
      setPaymentMethodId("");
    } else {
      // Select the card
      setPaymentMethodId(id);
    }
    setCardError("");
  };

  const errorFunction = () => {
    let errorOne =
      !errors2?.country_id &&
      !errors2?.State_id &&
      !errors2?.City &&
      !errors2?.address1;
    setCheckStepOne(!errorOne);
  };
  useEffect(() => {
    errorFunction();
  }, [errors2]);

  return (
    <Modal
      isOpen={modalIsOpen}
      onRequestClose={() => onClose()}
      style={customModalStyles}
      shouldCloseOnOverlayClick={false}
      contentLabel="Create Role Modal"
    >
      <div className="modal-dialog modal-lg permision-popup payment-popup">
        <div className="modal-content">
          <div className="modal-header role-icon payment">
            <div className="logo-popup">
              <img
                src="/static/media/logo-dark.8b5ea850cb98041cfced.png"
                alt="LIFETRACKA"
                title="LIFETRACKA"
              />
            </div>
            <div className="plan-name">
              {subscriptionPlan?.type == "update"
                ? `${subscriptionPlan?.name} Plan - Add more license`
                : `${subscriptionPlan?.name} Plan - ${subscriptionPlan?.actionText}`}
            </div>
            <div className="cros_button">
              <button
                type="button"
                className="btn-close"
                aria-label="Close"
                onClick={() => onClose()}
              ></button>
            </div>
          </div>
          <div className="modal-body">
            <div className="sub-payment row">
              <div className="accordion accordion-flush sap-accor-form">
                <>
                  <div className="accordion-item">
                    <h2 className="accordion-header">
                      <button
                        className={`accordion-button ${
                          openAccordion === 0 ? "show" : "collapsed"
                        }`}
                        type="button"
                        onClick={() => handleToggle(0)}
                      >
                        <div className="saf-title-head">
                          <div className="font-18 hd-title mb-3 mt-4">
                            <BillingFrequencySvg />
                            Billing Frequency
                          </div>
                        </div>
                      </button>
                    </h2>
                    <div
                      id="flush-collapseFour"
                      className={`accordion-collapse ${
                        openAccordion === 0 ? "show" : "collapse"
                      }`}
                      data-bs-parent="#accordionFlushExample"
                    >
                      <div className="accordion-body">
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="flexRadioDefault"
                            id="flexRadioDefault1"
                            value={1}
                            checked={selectedPlan === 1}
                            onChange={handlePlanChange}
                          />
                          <label
                            className="form-check-label"
                            for="flexRadioDefault1"
                          >
                            <div className="sub-payment-month-head">
                              <div
                                className="font-16 hd-title"
                                for="flexRadioDefault1"
                              >
                                Yearly{" "}
                                <span className="badge text-bg-success">
                                  Save {subscriptionPlanDiscount?.year}%
                                </span>
                              </div>
                              <div className="spmh-text">
                                <div className="font-16 hd-title">
                                  ${monthAmountYerDiscount}/Month
                                </div>
                                <div className="font-16 hd-subtitle">
                                  ${subscriptionPlan?.yearlyPriceWithDiscount}{" "}
                                  billed yearly
                                </div>
                              </div>
                            </div>
                          </label>
                        </div>
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="flexRadioDefault"
                            id="flexRadioDefault2"
                            value={0}
                            checked={selectedPlan === 0}
                            onChange={handlePlanChange}
                          />
                          <label
                            className="form-check-label"
                            for="flexRadioDefault2"
                          >
                            <div className="sub-payment-month-head">
                              <div
                                className="font-16 hd-title"
                                for="flexRadioDefault2"
                              >
                                Monthly
                              </div>
                              <div className="spmh-text">
                                <div className="font-16 hd-title">
                                  ${subscriptionPlan?.monthAmount}/Month
                                </div>
                              </div>
                            </div>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
                <div className="accordion-item">
                  <h2 className="accordion-header">
                    <button
                      className={`accordion-button ${
                        openAccordion === 1 || checkSelectCard
                          ? ""
                          : "collapsed"
                      }`}
                      type="button"
                      onClick={() => handleToggle(1)}
                    >
                      <div className="saf-title-head">
                        <div className="font-18 hd-title mb-3 mt-4">
                          <SavedCardsSvg />
                          Saved Cards
                        </div>
                      </div>
                    </button>
                  </h2>
                  <div
                    id="flush-collapseFour1"
                    className={`accordion-collapse ${
                      openAccordion === 1 || checkSelectCard
                        ? "show"
                        : "collapse"
                    }`}
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div className="accordion-body">
                      {CustomerAllCard && CustomerAllCard.length > 0 ? (
                        <Slider {...settings}>
                          {CustomerAllCard.map((item, index) => (
                            <div key={index}>
                              <div
                                className={`debit-card ${
                                  paymentMethodId === item.id ? "active" : ""
                                }`}
                                onClick={() => handleSliderSelect(item?.id)}
                                style={{ cursor: "pointer" }} // Keep pointer for all cards
                              >
                                <img
                                  src="https://image.ibb.co/gDVR4x/master_card_logo.png"
                                  className="master-card-logo"
                                  alt="MasterCard Logo"
                                />
                                <div className="card-number-block">
                                  <p>xxxx xxxx xxxx {item?.card?.last4}</p>
                                </div>
                                <div className="card-holder-block">
                                  <div className="block-lebel">Card Holder</div>
                                  <p>{item?.customer_name}</p>
                                </div>
                              </div>
                            </div>
                          ))}
                        </Slider>
                      ) : (
                        <div className="billing_no_card">
                          No customer cards available
                        </div>
                      )}
                    </div>
                    {cardError !== "" && (
                      <span className="error">{cardError}</span>
                    )}
                  </div>
                </div>

                {!paymentMethodId && (
                  <div className="accordion-item">
                    <h2 className="accordion-header">
                      <button
                        className={`accordion-button ${
                          openAccordion === 2 || checkStepOne
                            ? "show"
                            : "collapsed"
                        }`}
                        type="button"
                        onClick={() => handleToggle(2)}
                      >
                        <div className="saf-title-head">
                          <div className="font-18 hd-title mb-3 mt-4">
                            <AddNewCardSvg />
                            Add new card
                          </div>
                        </div>
                      </button>
                    </h2>
                    <div
                      id="flush-collapseFour2"
                      className={`accordion-collapse ${
                        openAccordion === 2 || checkStepOne
                          ? "show"
                          : "collapse"
                      }`}
                      data-bs-parent="#accordionFlushExample"
                    >
                      <div className="accordion-body">
                        <form onSubmit={handleSubmit1(AddNewCard)}>
                          <div className="row">
                            <div className="col-md-12 mb-3 custom-form-group">
                              <label
                                htmlFor="cardNumber"
                                className="form-label"
                              >
                                Card Number<sup>*</sup>
                              </label>
                              <CardNumberElement
                                className="form-control stripe-element"
                                id="CardNumberElement"
                                onChange={handleCardNumberChange}
                              />
                              {cardNumberError && (
                                <p className="error">{cardNumberError}</p>
                              )}
                            </div>
                            <div className="col-md-6 mb-3 custom-form-group">
                              <label htmlFor="expiry" className="form-label">
                                Expiration<sup>*</sup>
                              </label>
                              <CardExpiryElement
                                className="form-control stripe-element"
                                id="CardExpiryElement"
                                onChange={handleExpirationChange}
                              />
                              {expirationError && (
                                <p className="error">{expirationError}</p>
                              )}
                            </div>
                            <div className="col-md-6 mb-3 custom-form-group">
                              <label htmlFor="cvc" className="form-label">
                                CVC<sup>*</sup>
                              </label>
                              <CardCvcElement
                                className="form-control stripe-element"
                                id="CardCvcElement"
                                onChange={handleCvcChange}
                              />
                              {cvcError && <p className="error">{cvcError}</p>}
                            </div>
                            <div className="form-check">
                              <input
                                className="form-check-input"
                                type="checkbox"
                              />
                              <label className="form-check-label">
                                Save card details
                              </label>
                            </div>
                            <div className="col-md-12 mt-3">
                              <button type="submit" className="btn btn-primary">
                                Save card
                              </button>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                )}
                {!paymentMethodId && (
                  <div className="accordion-item">
                    <h2 className="accordion-header">
                      <button
                        className={`accordion-button ${
                          openAccordion === 3 || checkStepOne
                            ? "show"
                            : "collapsed"
                        }`}
                        type="button"
                        onClick={() => handleToggle(3)}
                      >
                        <div className="saf-title-head">
                          <div className="font-18 hd-title mb-3 mt-4">
                            <BillingInformationSvg />
                            Billing Information
                          </div>
                        </div>
                      </button>
                    </h2>
                    <div
                      id="flush-collapseFour2"
                      className={`accordion-collapse ${
                        openAccordion === 3 || checkStepOne
                          ? "show"
                          : "collapse"
                      }`}
                      data-bs-parent="#accordionFlushExample"
                    >
                      <div className="accordion-body">
                        <div className="row">
                          <div className="col-md-12 mb-3">
                            <label className="form-label">Country*</label>
                            <select
                              className="form-select"
                              {...register2("country_id", {
                                required: "Country is required",
                              })}
                              onChange={(e) => {
                                if (e.target.value) {
                                  clearErrors("country_id");
                                }
                              }}
                            >
                              <option value="">Select country</option>
                              {sortedCountryData?.map((elem) => (
                                <option key={elem?.id} value={elem?.id}>
                                  {elem?.country}
                                </option>
                              ))}
                            </select>
                            {errors2?.country_id && (
                              <span className="error">
                                {errors2?.country_id?.message}
                              </span>
                            )}
                          </div>
                          <div className="flex gap-3">
                            <div className="col-md-6 mb-3">
                              <label className="form-label">State*</label>
                              <select
                                className="form-select"
                                {...register2("State_id", {
                                  required: "State is required",
                                })}
                                onChange={(e) => {
                                  if (e.target.value) {
                                    clearErrors("State_id");
                                  }
                                }}
                              >
                                <option value="">Select state</option>
                                {stateData?.map((elem) => (
                                  <option key={elem?.id} value={elem?.id}>
                                    {elem?.address_state}
                                  </option>
                                ))}
                              </select>
                              {errors2?.State_id && (
                                <span className="error">
                                  {errors2?.State_id?.message}
                                </span>
                              )}
                            </div>

                            <div className="col-md-6 mb-3">
                              <label className="form-label">City*</label>
                              <input
                                type="text"
                                className="form-control"
                                {...register2("City", {
                                  required: "City is required",
                                })}
                                onChange={(e) => {
                                  if (e.target.value) {
                                    clearErrors("City");
                                  }
                                }}
                              />
                              {errors2?.City && (
                                <span className="error">
                                  {errors2?.City?.message}
                                </span>
                              )}
                            </div>
                          </div>

                          <div className="col-md-12 mb-3">
                            <label className="form-label">Address line*</label>
                            <input
                              type="text"
                              className="form-control"
                              {...register2("address1", {
                                required: "Address is required",
                              })}
                              onChange={(e) => {
                                if (e.target.value.trim()) {
                                  setValue2("address1", e.target.value, {
                                    shouldValidate: true,
                                  });
                                  clearErrors("address1");
                                }
                              }}
                              onBlur={() => {
                                setValue2("address1", getValues2("address1"), {
                                  shouldValidate: true,
                                });
                              }}
                            />
                            {errors2?.address1 && (
                              <span className="error">
                                {errors2?.address1?.message}
                              </span>
                            )}

                            {errors2?.address1 && (
                              <span className="error">
                                {errors2?.address1?.message}
                              </span>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                <div className="accordion-item">
                  <h2 className="accordion-header">
                    <button
                      className={`accordion-button ${
                        openAccordion === 4 ? "" : "collapsed"
                      }`}
                      type="button"
                      onClick={() => handleToggle(4)}
                    >
                      <div className="saf-title-head">
                        <div className="font-18 hd-title mb-3 mt-4">
                          <OrderSummarySvg />
                          Order Summary
                        </div>
                      </div>
                    </button>
                  </h2>
                  <div
                    id="flush-collapseFour4"
                    className={`accordion-collapse ${
                      openAccordion === 4 ? "show" : "collapse"
                    }`}
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div className="accordion-body">
                      <div className="">
                        <div className="font-16 hd-subtitle">
                          Unlock unlimited access for only (
                          {subscriptionPlan?.name}) ${planeAmount} USD per{" "}
                          {selectedPlan == 1 ? "year" : "Month"}. Charged next
                          billing cycle{" "}
                          {selectedPlan == 1 ? "yearly" : "Monthly"}, our
                          subscription offers unbeatable value and convenience.
                          Cancel anytime and enjoy peace of mind with all sales
                          final. Subscribe now!
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  {showBillingError ? (
                    <p>Billing Information is required</p>
                  ) : (
                    ""
                  )}
                </div>
                <div
                  className="col-md-12 d-flex justify-content-end p-2 mt-3"
                  onClick={handleSubmit2(() => onSubmit())}
                >
                  <button type="submit" className="btn btn-primary">
                    {[
                      `${subscriptionPlan?.actionText} to ${subscriptionPlan?.name}`,
                    ]}{" "}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default UpdateSubscription;
