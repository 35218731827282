import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';

const customModalStyles = {
    overlay: {
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        zIndex: 1000
    },
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        border: 'none',
        maxWidth: '750px',
        background: '#fff',
        borderRadius: '8px',
        padding: '20px',
        zIndex: '99',
        transition: 'all 1s ease-in-out'
    }
};

const TimeZoneModal = ({ modalIsOpen, setModalIsOpen, timezones, selectedTimezone, setSelectedTimezone }) => {
    const [value, setValue] = useState('');

    // Pre-select the current timezone when the modal opens
    useEffect(() => {
        setValue(selectedTimezone);
    }, [selectedTimezone]);

    const handleTimezone = (e) => {
        setValue(e.target.value);
    };

    const handleSave = () => {
        if (value) {
            setSelectedTimezone(value);  // Update the selected timezone in the parent
            setModalIsOpen(false);       // Close the modal
        }
    };

    return (
        <Modal
            isOpen={modalIsOpen}
            onRequestClose={() => setModalIsOpen(false)}
            style={customModalStyles}
            shouldCloseOnOverlayClick={false}
            contentLabel="Select Timezone Modal"
        >
            <div className="modal-dialog modal-lg communication_edit_modal">
                <div className="modal-content">
                    <div className="modal-header close-deactivate">
                        <button type="button" className="btn-close" onClick={() => setModalIsOpen(false)}></button>
                    </div>
                    <div className="modal-body">
                        <div className="font-18 hd-title mb-3">Select Timezone*</div>
                        <select
                            className="form-select"
                            value={value}
                            onChange={handleTimezone}
                            aria-label="Default select example"
                        >
                            <option value="">Select Timezone</option>
                            {timezones?.map((ele) => (
                                <option
                                    key={ele.zoneName}
                                    value={`${ele.zoneName} (${ele.gmtOffsetName})`}
                                >
                                    {`${ele.zoneName} (${ele.gmtOffsetName})`}
                                </option>
                            ))}
                        </select>

                        <div className="pop-action-btn">
                            <button
                                type="button"
                                className="btn btn-light"
                                onClick={handleSave}
                            >
                                Save
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    );
};

export default TimeZoneModal;
