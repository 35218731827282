import React from 'react'
import memberEmpty from '../../../Images/member-empty.png'
import { Link } from 'react-router-dom'
const ResultNotFound = () => {
    return (
        <div className="main-content-sec">
            <div className="empty-sec mb-3">
                <div className="font-16 hd-title mb-3">Result not found</div>
                <img src={memberEmpty} alt="" title="" />
            </div>
        </div>
    )
}

export default ResultNotFound