import React, { useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { admin_update_status_comment, edit_member_medication } from '../../../redux/services/AuthMar'

const AdminStatusChange = ({ handleCommentToggle }) => {
    const dispatch = useDispatch();
    const EditMemberDetails = useSelector(state => state?.AuthMar?.EditMemberDetails);
    const [approvalComment, setApprovalComment] = useState('')
    const [approvalStatus, setApprovalStatus] = useState('')
    
    // New state for validation errors
    const [errors, setErrors] = useState({
        approvalStatus: '',
        approvalComment: ''
    });

    // Validation function
    const validateForm = () => {
        let isValid = true;
        let newErrors = {
            approvalStatus: '',
            approvalComment: ''
        };

        // Validate Approval Status
        if (!approvalStatus) {
            newErrors.approvalStatus = 'Status is required';
            isValid = false;
        }

        // Validate Approval Comment
        if (!approvalComment.trim()) {
            newErrors.approvalComment = 'Comment is required';
            isValid = false;
        } else if (approvalComment.trim().length < 10) {
            newErrors.approvalComment = 'Comment must be at least 10 characters long';
            isValid = false;
        }

        setErrors(newErrors);
        return isValid;
    }

    const handleChange = (e) => {
        setApprovalComment(e.target.value);
        // Clear comment error when typing
        if (errors.approvalComment) {
            setErrors(prev => ({...prev, approvalComment: ''}));
        }
    }

    const handleStatusChange = (e) => {
        setApprovalStatus(e.target.value);
        // Clear status error when selecting
        if (errors.approvalStatus) {
            setErrors(prev => ({...prev, approvalStatus: ''}));
        }
    }

    const handleSubmit = async () => {
        // Validate form before submission
        if (!validateForm()) {
            return;
        }

        try {
            let data = {
                'mar_detail_id': EditMemberDetails?.id,
                'approval_status': approvalStatus,
                'approval_comment': approvalComment
            }
            const response = await dispatch(admin_update_status_comment(data));
            
            if (response?.payload?.status) {
                // Trigger re-fetch of member medication details
                dispatch(edit_member_medication(EditMemberDetails?.id));
                
                // Close the modal
                handleCommentToggle(false)
            } else {
                console.error("Failed to update status:", response);
            }
        } catch (error) {
            console.error("Error during add Comment:", error);
        }
    }

    return (
        <>
            <div className="modal fade new_medi_modal show">
                <div className="modal-dialog modal-lg permision-popup">
                    <div className="modal-content ">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M15 4.6C15 4.03995 15 3.75992 14.891 3.54601C14.7951 3.35785 14.6422 3.20487 14.454 3.10899C14.2401 3 13.9601 3 13.4 3H10.6C10.0399 3 9.75992 3 9.54601 3.10899C9.35785 3.20487 9.20487 3.35785 9.10899 3.54601C9 3.75992 9 4.03995 9 7.4V7.4C9 7.96005 9 8.24008 8.89101 8.45399C8.79513 8.64215 8.64215 8.79513 8.45399 8.89101C8.24008 9 7.96005 9 7.4 9H4.6C4.03995 9 3.75992 9 3.54601 9.10899C3.35785 9.20487 3.20487 9.35785 3.10899 9.54601C3 9.75992 3 10.0399 3 10.6V13.4C3 13.9601 3 14.2401 3.10899 14.454C3.20487 14.6422 3.35785 14.7951 3.54601 14.891C3.75992 15 4.03995 15 4.6 15H7.4C7.96005 15 8.24008 15 8.45399 15.109C8.64215 15.2049 8.79513 15.3578 8.89101 15.546C9 15.7599 9 16.0399 9 16.6V19.4C9 19.9601 9 20.2401 9.10899 20.454C9.20487 20.6422 9.35785 20.7951 9.54601 20.891C9.75992 21 10.0399 21 10.6 21H13.4C13.9601 21 14.2401 21 14.454 20.891C14.6422 20.7951 14.7951 20.6422 14.891 20.454C15 20.2401 15 19.9601 15 19.4V16.6C15 16.0399 15 15.7599 15.109 15.546C15.2049 15.3578 15.3578 15.2049 15.546 15.109C15.7599 15 16.0399 15 16.6 15H19.4C19.9601 15 20.2401 15 20.454 14.891C20.6422 14.7951 20.7951 14.6422 20.891 14.454C21 14.2401 21 13.9601 21 13.4V10.6C21 10.0399 21 9.75992 20.891 9.54601C20.7951 9.35785 20.6422 9.20487 20.454 9.10899C20.2401 9 19.9601 9 19.4 9L16.6 9C16.0399 9 15.7599 9 15.546 8.89101C15.3578 8.79513 15.2049 8.64215 15.109 8.45399C15 8.24008 15 7.96005 15 7.4V4.6Z" stroke="#344054" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                </svg>
                            </h5>
                            <button type="button" className="btn-close" onClick={() => handleCommentToggle(false)}></button>
                        </div>
                        <div className="modal-body medication_modal">
                            <form action="" className="row">
                                <div className="col-12">
                                    <div className="member_vital_basic_title">
                                        <h4>Change Status</h4>
                                    </div>
                                </div>
                                <hr />
                                <div className="col-12 mb-3">
                                    <label htmlFor="statusSelect" className="form-label">
                                        Status<span >*</span>
                                    </label>
                                    <select 
                                        id="statusSelect"
                                        className={`form-select ${errors.approvalStatus ? 'is-invalid' : ''}`}
                                        aria-label="Default select example" 
                                        value={approvalStatus}
                                        onChange={(e) => handleStatusChange(e)}
                                    >
                                        <option value="">Select Status</option>
                                        <option value="Pending">Pending</option>
                                        <option value="approved">Approved</option>
                                    </select>
                                    {errors.approvalStatus && (
                                        <div className="invalid-feedback">
                                            {errors.approvalStatus}
                                        </div>
                                    )}
                                </div>

                                <div className="col-12 mb-3">
                                    <label htmlFor="commentInput" className="form-label">
                                        Comment<span >*</span>
                                    </label>
                                    <input 
                                        type="text" 
                                        className={`form-control ${errors.approvalComment ? 'is-invalid' : ''}`}
                                        id="commentInput" 
                                        placeholder="Message goes here" 
                                        value={approvalComment} 
                                        onChange={(e) => handleChange(e)} 
                                    />
                                    {errors.approvalComment && (
                                        <div className="invalid-feedback">
                                            {errors.approvalComment}
                                        </div>
                                    )}
                                </div>
                                <div className="up_vitalm_btn d-flex">
                                    <button type="button" className="w-50" onClick={() => handleCommentToggle()}>Cancel</button>
                                    <button type="button" className="w-50" onClick={() => handleSubmit()}>Done</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default AdminStatusChange