import React from "react";
import { useSelector } from "react-redux";
import { Pagination } from "../../Pagination";
import { getMonthDate } from "../../../components/Common/Common";

const Types = [
  { id: "1", name: "Health" },
  { id: "2", name: "US/Arizona" },
  { id: "3", name: "US/Arizona" },
  { id: "4", name: "US/Arizona" },
];
const TableView = ({
  communicationList,
  currentPage,
  totalPages,
  handlePageChange,
  onRowClick,
}) => {
  const homeMemberList = useSelector(
    (state) => state?.AuthCommunication?.homeMemberList
  );

  return (
    <div className="main-content-sec vit_info_data medicat_tb_cont">
      <div className="row">
        <div className="col-md-12 col-12">
          <div className="table-sec">
            <div className="table-responsive">
              <table className="table table-hover">
                <thead>
                  <tr>
                    <th scope="col">
                      <div className="table-profile">
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            id="flexCheckIndeterminate"
                          />
                        </div>
                        Member
                        <svg
                          className="ms-1"
                          width="12"
                          height="12"
                          viewBox="0 0 12 12"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M6 1.33337V10.6667M6 10.6667L10.6667 6.00004M6 10.6667L1.33333 6.00004"
                            stroke="#475467"
                            strokeWidth="0.888889"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </div>
                    </th>
                    <th scope="col">Home</th>
                    <th scope="col">Title</th>
                    <th scope="col">Type</th>
                    <th scope="col">Date</th>
                  </tr>
                </thead>
                <tbody>
                  {communicationList &&
                    communicationList.map((item, index) => {
                      let shortName = "";
                      let member =
                        homeMemberList &&
                        homeMemberList.find((elm) => elm.id === item.member_id);
                      if (member) {
                        shortName =
                          member?.first_name?.charAt(0)?.toUpperCase() +
                            member?.last_name?.charAt(0)?.toUpperCase() || "";
                      }
                      let type = Types.find((elm) => elm.id === item.type);
                      return (
                        <tr>
                          <td>
                            <div className="table-profile">
                              <div className="form-check appointment_check">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  value=""
                                  id="flexCheckIndeterminate"
                                />
                              </div>
                              <div
                                className="profile-sec  appointment-member"
                                onClick={() => onRowClick(item)}
                              >
                                <span className="name ">
                                  {member?.profile_pic ? (
                                    <img src={member?.profile_pic} />
                                  ) : (
                                    <img src="/pro.png" alt={`'s profile`} />
                                  )}
                                </span>
                                <div className="profile-info">
                                  <p className="font-14 hd-title">
                                    {member?.first_name} {member?.last_name}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </td>
                          <td className="hd-subtitle text-dark">
                            {item.home_name}
                          </td>
                          <td className="hd-subtitle text-dark">
                            {item.title}
                          </td>

                          <td className="hd-subtitle text-dark">{type.name}</td>
                          <td className="approval-status-tbl">
                            {getMonthDate(item.date)}
                          </td>
                        </tr>
                      );
                    })}
                  <tr>
                    <td colSpan="7">
                      <Pagination
                        currentPage={currentPage}
                        totalPages={totalPages}
                        onPageChange={handlePageChange}
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TableView;
