import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import UrlBar from "./UrlBar";

const fileFormate = [
  {
    lable: "DOCX",
    value:
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  },
  {
    lable: "XLSX",
    value: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  },
  { lable: "PDF", value: "application/pdf" },
  { lable: "PNG", value: "image/png" },
  { lable: "JPEG", value: "image/jpeg" },
  { lable: "JPG", value: "image/jpg" },
];
const Header = ({ handleSearchBar, search, setSearch, onModalToggle }) => {
  const dispatch = useDispatch();

  const userPermissions = useSelector(
    (state) => state?.common?.userPermissions
  );
  const moduleData = useSelector((state) => state?.common?.moduleData);

  const [showFilter, setShowFilter] = useState(false);

  const handleAddNew = () => {
    onModalToggle(true);
  };

  const handleStatusChange = (e) => {
    const { name, value } = e.target;
    setSearch({
      ...search,
      [name]: value,
    });
  };

  const clear_all_filters = () => {
    setSearch({
      ...search,
      category_id: "",
      file_type: "",
      page: 1,
      listData: 10,
      searchBar: "",
    });
  };

  return (
    <>
      <UrlBar />
      <div className="right-main-header adminstrat_btn_main">
        <h2>Documents</h2>
        <div className="adminstrat_btn">
          {userPermissions && userPermissions.includes("Upload") && (
            <button
              type="button"
              className="btn btn-primary"
              onClick={() => handleAddNew()}
            >
              <svg
                width="21"
                height="20"
                viewBox="0 0 21 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M10.5 4.16699V15.8337M4.66663 10.0003H16.3333"
                  stroke="white"
                  stroke-width="1.66667"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>
              </svg>
              New Documents
            </button>
          )}
        </div>
      </div>
      {!showFilter ? (
        <div className="table-btn-sec">
          <div className="form-group searchbar">
            <input
              type="text"
              name=""
              id=""
              className="form-control"
              placeholder="Search Name , Type"
              onChange={(e) => handleSearchBar(e)}
              value={search?.searchBar}
              maxLength={30}
            />
            <svg
              width="18"
              height="18"
              viewBox="0 0 18 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M16.5 16.5L13.5834 13.5833M15.6667 8.58333C15.6667 12.4954 12.4954 15.6667 8.58333 15.6667C4.67132 15.6667 1.5 12.4954 1.5 8.58333C1.5 4.67132 4.67132 1.5 8.58333 1.5C12.4954 1.5 15.6667 4.67132 15.6667 8.58333Z"
                stroke="#667085"
                stroke-width="1.66667"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>
          <button
            type="button"
            className="btn btn-light"
            onClick={() => setShowFilter(true)}
          >
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M5 10H15M2.5 5H17.5M7.5 15H12.5"
                stroke="#344054"
                stroke-width="1.66667"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            More Filters
          </button>
        </div>
      ) : (
        ""
      )}

      {showFilter && (
        <div className="table-btn-sec more-filter">
          <button
            type="button"
            className="btn btn-light"
            onClick={() => (setShowFilter(false), clear_all_filters())}
          >
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M15 5L5 15M5 5L15 15"
                stroke="#344054"
                stroke-width="1.66667"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            Close Filters
          </button>
          <button className="clear-filter" onClick={() => clear_all_filters()}>
            Clear all filters
          </button>
          <div className="filter-option">
            <div className="form-group searchbar">
              <label className="form-label">Search</label>
              <input
                type="text"
                name=""
                id=""
                className="form-control"
                placeholder="Search File Name..."
                onChange={(e) => handleSearchBar(e)}
                value={search?.searchBar}
                maxLength={30}
              />
              <svg
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M16.5 16.5L13.5834 13.5833M15.6667 8.58333C15.6667 12.4954 12.4954 15.6667 8.58333 15.6667C4.67132 15.6667 1.5 12.4954 1.5 8.58333C1.5 4.67132 4.67132 1.5 8.58333 1.5C12.4954 1.5 15.6667 4.67132 15.6667 8.58333Z"
                  stroke="#667085"
                  stroke-width="1.66667"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>

            <div className="form-group">
              <label className="form-label">Category</label>
              <select
                className="form-select"
                aria-label="Default select example"
                name="category_id"
                value={search.category_id}
                onChange={(e) => handleStatusChange(e)}
              >
                <option value="">Select</option>
                {moduleData &&
                  moduleData.map((item) => (
                    <option
                      key={item.id || item.module_id}
                      value={item.id || item.module_id}
                    >
                      {item.module_name}
                    </option>
                  ))}
              </select>{" "}
            </div>

            <div className="form-group">
              <label for="" className="form-label">
                Type
              </label>
              <select
                className="form-select"
                aria-label="Default select example"
                name="file_type"
                value={search.file_type}
                onChange={(e) => handleStatusChange(e)}
              >
                <option value="">Select</option>
                {fileFormate.map((item) => (
                  <option value={item.value}>{item.lable}</option>
                ))}
              </select>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
export default Header;
