import React, { useEffect, useState } from "react";
import { useFieldArray, useFormContext } from "react-hook-form";

const AddHealthInfo = ({
  stateData,
  setActiveTab1,
  EditHealthData,
  id,
  onSubmit,
  profileStatus,
}) => {
  const formMethods = useFormContext();
  const {
    register,
    trigger,
    setValue,
    formState: { errors },
    control,
  } = formMethods;
  const [activeTab, setActiveTab] = useState("PrimaryCare");
  //add more ALTCS
  const { fields, append, remove } = useFieldArray({
    control,
    name: "healthCare_altcs",
  });
  const ChangeTab = async (name) => {
    let valid = true;
    if (activeTab === "PrimaryCare") {
      valid = await trigger([
        "p_firstName",
        "p_lastName",
        "p_phoneNo",
        "p_streetAddress",
        "p_city",
        "p_state",
        "p_zipCode",
      ]);
      if (valid) {
        setActiveTab(name);
      }
    } else if (activeTab === "behaviouralHealth") {
      valid = await trigger([
        "b_firstName",
        "b_lastName",
        "b_phoneNo",
        "b_streetAddress",
        "b_city",
        "b_state",
        "b_zipCode",
      ]);
      if (valid) {
        setActiveTab(name);
      }
    } else if (activeTab === "ALTCS") {
      const healthAltcs = fields
        .map((_, index) => [
          `healthCare_altcs.${index}.ha_planProvider`,
          `healthCare_altcs.${index}.ha_IdNumber`,
          `healthCare_altcs.${index}.ha_phoneNo`,
          `healthCare_altcs.${index}.ha_phoneNo`,
          // `healthCare_altcs.${index}.ha_authorizationProcess`,
        ])
        .flat();
      valid = await trigger(healthAltcs);
      if (valid) {
        setActiveTab(name);
      }
    } else if (activeTab === "MedicareHealth") {
      valid = await trigger([
        "m_madicareSection",
        "m_IdNumber",
        "m_phoneNumber",
        "m_planprovider",
        "m_policyHolder",
        "m_groupNumber",
        "m_planPhoneNumber",
      ]);
      if (valid) {
        setActiveTab(name);
      }
    } else {
      setActiveTab(name);
    }
  };

  //Edit healthinfo data
  useEffect(() => {
    if (id) {
      //Primary care
      setValue("p_firstName", EditHealthData?.PrimaryCare?.first_name);
      setValue("p_lastName", EditHealthData?.PrimaryCare?.last_name);
      setValue("p_phoneNo", EditHealthData?.PrimaryCare?.phoneNo);
      setValue("p_streetAddress", EditHealthData?.PrimaryCare?.streetAddress);
      setValue("p_city", EditHealthData?.PrimaryCare?.city);
      setValue("p_state", EditHealthData?.PrimaryCare?.state);
      setValue("p_zipCode", EditHealthData?.PrimaryCare?.zipCode);
      setValue("p_email", EditHealthData?.PrimaryCare?.email);
      //behaviouralHealth
      setValue("b_firstName", EditHealthData?.behaviouralHealth?.first_name);
      setValue("b_lastName", EditHealthData?.behaviouralHealth?.last_name);
      setValue("b_phoneNo", EditHealthData?.behaviouralHealth?.phoneNo);
      setValue(
        "b_streetAddress",
        EditHealthData?.behaviouralHealth?.streetAddress
      );
      setValue("b_city", EditHealthData?.behaviouralHealth?.city);
      setValue("b_state", EditHealthData?.behaviouralHealth?.state);
      setValue("b_zipCode", EditHealthData?.behaviouralHealth?.zipCode);
      //ALTCS
      const lengthDifference = EditHealthData.ALTCS.length - fields.length;
      if (lengthDifference > 0) {
        for (let i = 0; i < lengthDifference; i++) {
          append({});
        }
      }
      EditHealthData.ALTCS.forEach((item, index) => {
        setValue(
          `healthCare_altcs.${index}.ha_planProvider`,
          item.planProvider || ""
        );
        setValue(`healthCare_altcs.${index}.ha_phoneNo`, item.phoneNo || "");
        setValue(`healthCare_altcs.${index}.ha_website`, item.website || "");
        setValue(`healthCare_altcs.${index}.ha_IdNumber`, item.IdNumber || "");
        setValue(
          `healthCare_altcs.${index}.ha_authorizationProcess`,
          item.authorizationProcess || ""
        );
      });
      //MedicareHealth
      setValue("m_IdNumber", EditHealthData?.MedicareHealth?.IdNumber);
      setValue(
        "m_madicareSection",
        EditHealthData?.MedicareHealth?.madicareSection
      );
      setValue("m_phoneNumber", EditHealthData?.MedicareHealth?.phoneNumber);
      setValue("m_planprovider", EditHealthData?.MedicareHealth?.planprovider);
      setValue("m_policyHolder", EditHealthData?.MedicareHealth?.policyHolder);
      setValue("m_groupNumber", EditHealthData?.MedicareHealth?.groupNumber);
      setValue(
        "m_planPhoneNumber",
        EditHealthData?.MedicareHealth?.planPhoneNumber
      );
    }
  }, [EditHealthData]);
  return (
    <>
      <div
        className="tab-content"
        id="v-pills-health"
        role="tabpanel"
        aria-labelledby="v-pills-health-tab"
      >
        <div className="vital_stepone_forth">
          <div className="steps-progress">
            <h5 className="sap-card-title">
              {(activeTab == "PrimaryCare" && 1) ||
                (activeTab == "behaviouralHealth" && 2) ||
                (activeTab == "ALTCS" && 3) ||
                (activeTab == "MedicareHealth" && 4)}
              /4
            </h5>
            <div className="progress">
              <div
                className="progress-bar"
                role="progressbar"
                style={{
                  width:
                    (activeTab == "PrimaryCare" && "25%") ||
                    (activeTab == "behaviouralHealth" && "50%") ||
                    (activeTab == "ALTCS" && "75%") ||
                    (activeTab == "MedicareHealth" && "100%"),
                }}
                aria-valuenow="25"
                aria-valuemin="0"
                aria-valuemax="100"
              >
                25%
              </div>
            </div>
          </div>
        </div>
        {/* Primary care physician or provider */}
        {activeTab === "PrimaryCare" && (
          <>
            <div className="member_vital_basic_title">
              <h4>Primary care physician or provider</h4>
            </div>
            <div className="member_vitalstep_one_form">
              <div action="" className="row">
                <div className="col-md-6 mb-3">
                  <label for="" className="form-label">
                    First Name
                  </label>
                  <input
                    type="input"
                    className="form-control"
                    id=""
                    placeholder="First Name"
                    {...register("p_firstName")}
                    maxLength={20}
                  />
                  {errors.p_firstName &&
                    errors.p_firstName.type === "required" && (
                      <span className="error">First Name is required!</span>
                    )}
                </div>
                <div className="col-md-6 mb-3">
                  <label for="" className="form-label">
                    Last Name
                  </label>
                  <input
                    type="input"
                    className="form-control"
                    id=""
                    placeholder="Last Name"
                    {...register("p_lastName")}
                    maxLength={20}
                  />
                  {errors.p_lastName &&
                    errors.p_lastName.type === "required" && (
                      <span className="error">Last Name is required!</span>
                    )}
                </div>
                <div className="col-md-6 mb-3">
                  <label for="" className="form-label">
                    Phone number
                  </label>
                  <input
                    type="input"
                    className="form-control"
                    id=""
                    placeholder="Enter phone number"
                    {...register("p_phoneNo", {
                      pattern: {
                        value: /^\d{10,15}$/,
                        message: "Phone number must be 10 to 15 digits",
                      },
                    })}
                    maxLength={15}
                    onKeyPress={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    onPaste={(event) => {
                      // Prevent pasting any non-numeric characters
                      const pastedData = event.clipboardData.getData("text");
                      if (!/^\d{1,5}$/.test(pastedData)) {
                        event.preventDefault();
                      }
                    }}
                  />
                  {errors.p_phoneNo && errors.p_phoneNo.type === "required" && (
                    <span className="error">Phone number is required</span>
                  )}
                  {errors.p_phoneNo && errors.p_phoneNo.type === "pattern" && (
                    <span className="error">{errors?.p_phoneNo?.message}</span>
                  )}
                </div>
                <div className="col-md-6 mb-3">
                  <label for="" className="form-label">
                    Street address
                  </label>
                  <input
                    type="input"
                    className="form-control"
                    id=""
                    placeholder="Enter address"
                    {...register("p_streetAddress")}
                    maxLength={25}
                  />
                  {errors.p_streetAddress &&
                    errors.p_streetAddress.type === "required" && (
                      <span className="error">Street address is required!</span>
                    )}
                </div>
                <div className="col-md-6 mb-3">
                  <label for="" className="form-label">
                    City
                  </label>
                  <input
                    type="input"
                    className="form-control"
                    id=""
                    placeholder="Enter city"
                    {...register("p_city")}
                    maxLength={25}
                  />
                  {errors.p_city && errors.p_city.type === "required" && (
                    <span className="error">City is required!</span>
                  )}
                </div>
                <div className="col-md-6 mb-3">
                  <label for="" className="form-label">
                    State
                  </label>
                  <select
                    className="form-select"
                    aria-label="Default select example"
                    {...register("p_state")}
                  >
                    <option value="">Select state</option>
                    {stateData &&
                      stateData?.map((elem) => {
                        return (
                          <option value={elem?.id}>
                            {elem?.address_state}
                          </option>
                        );
                      })}
                  </select>
                  {errors.p_state && errors.p_state.type === "required" && (
                    <span className="error">State is required!</span>
                  )}
                </div>
                <div className="col-md-6 mb-3">
                  <label for="" className="form-label">
                    Zip code
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Zip code"
                    onKeyPress={(event) => {
                      // Allow only numbers (0-9) when typing
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    maxLength={5}
                    onPaste={(event) => {
                      // Prevent pasting any non-numeric characters
                      const pastedData = event.clipboardData.getData("text");
                      if (!/^\d{1,5}$/.test(pastedData)) {
                        event.preventDefault();
                      }
                    }}
                    {...register("p_zipCode", {
                      pattern: {
                        value: /^\d{5}$/,
                        message: "ZIP code must be exactly 5 digits",
                      },
                    })}
                  />
                  {errors.p_zipCode && errors.p_zipCode.type === "required" && (
                    <span className="error">Zipcode is required!</span>
                  )}
                  {errors.p_zipCode && errors.p_zipCode.type === "pattern" && (
                    <span className="error">{errors?.p_zipCode?.message}</span>
                  )}
                </div>
                <div className="col-12 mb-3">
                  <label for="" className="form-label">
                    Hospital/Urgent care preference
                  </label>
                  <textarea
                    className="form-control"
                    id=""
                    placeholder="Hospital/Urgent care preference"
                    {...register("p_email")}
                    maxLength={250}
                  />
                  {/* {errors.p_email && errors.p_email.type === "required" && (<span className="error">Hospital/Urgent care preference is required!</span>)} */}
                </div>
              </div>
              <div className="col-md-12 login-btn">
                <div className="three-btn-sec">
                  <div className="two-btn-sec">
                    {profileStatus == true || id == undefined ? (
                      <button
                        type="button"
                        className="btn btn-light"
                        onClick={() => onSubmit(null, 4)}
                      >
                        Save as draft
                      </button>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="two-btn-sec">
                    <button
                      type="button"
                      className="btn btn-light me-md-2"
                      onClick={() => setActiveTab1("AdditionalContacts")}
                    >
                      <svg
                        width="16"
                        height="14"
                        viewBox="0 0 10 10"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M7.91683 5.00016H2.0835M2.0835 5.00016L5.00016 7.91683M2.0835 5.00016L5.00016 2.0835"
                          stroke="black"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                      Go Back
                    </button>
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={() => ChangeTab("behaviouralHealth")}
                    >
                      Next
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
        {/* Behavioural health provider */}
        {activeTab === "behaviouralHealth" && (
          <>
            <div className="member_vital_basic_title ">
              <h4>Behavioural health provider</h4>
            </div>
            <div className="member_vitalstep_one_form">
              <div className="row">
                <div className="col-md-6 mb-3">
                  <label for="" className="form-label">
                    First Name
                  </label>
                  <input
                    type="input"
                    className="form-control"
                    id=""
                    placeholder="First Name"
                    {...register("b_firstName")}
                    maxLength={20}
                  />
                  {errors.b_firstName &&
                    errors.b_firstName.type === "required" && (
                      <span className="error">First Name is required!</span>
                    )}
                </div>
                <div className="col-md-6 mb-3">
                  <label for="" className="form-label">
                    Last Name
                  </label>
                  <input
                    type="input"
                    className="form-control"
                    id=""
                    placeholder="Last Name"
                    {...register("b_lastName")}
                    maxLength={20}
                  />
                  {errors.b_lastName &&
                    errors.b_lastName.type === "required" && (
                      <span className="error">Last Name is required!</span>
                    )}
                </div>
                <div className="col-md-6 mb-3">
                  <label for="" className="form-label">
                    Phone number
                  </label>
                  <input
                    type="input"
                    className="form-control"
                    id=""
                    placeholder="Enter phone number"
                    {...register("b_phoneNo", {
                      pattern: {
                        value: /^\d{10,15}$/,
                        message: "Phone number must be 10 to 15 digits",
                      },
                    })}
                    maxLength={15}
                    onKeyPress={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    onPaste={(event) => {
                      // Prevent pasting any non-numeric characters
                      const pastedData = event.clipboardData.getData("text");
                      if (!/^\d{1,5}$/.test(pastedData)) {
                        event.preventDefault();
                      }
                    }}
                  />
                  {errors.b_phoneNo && errors.b_phoneNo.type === "required" && (
                    <span className="error">Phone number is required</span>
                  )}
                  {errors.b_phoneNo && errors.b_phoneNo.type === "pattern" && (
                    <span className="error">{errors?.b_phoneNo?.message}</span>
                  )}
                </div>
                <div className="col-md-6 mb-3">
                  <label for="" className="form-label">
                    Street address
                  </label>
                  <input
                    type="input"
                    className="form-control"
                    id=""
                    placeholder="Enter address"
                    {...register("b_streetAddress")}
                    maxLength={25}
                  />
                  {errors.b_streetAddress &&
                    errors.b_streetAddress.type === "required" && (
                      <span className="error">Street address is required!</span>
                    )}
                </div>
                <div className="col-md-6 mb-3">
                  <label for="" className="form-label">
                    City
                  </label>
                  <input
                    type="input"
                    className="form-control"
                    id=""
                    placeholder="Enter city"
                    {...register("b_city")}
                    maxLength={25}
                  />
                  {errors.b_city && errors.b_city.type === "required" && (
                    <span className="error">City is required!</span>
                  )}
                </div>
                <div className="col-md-6 mb-3">
                  <label for="" className="form-label">
                    State
                  </label>
                  <select
                    className="form-select"
                    aria-label="Default select example"
                    {...register("b_state")}
                  >
                    <option value="">Select state</option>
                    {stateData &&
                      stateData?.map((elem) => {
                        return (
                          <option value={elem?.id}>
                            {elem?.address_state}
                          </option>
                        );
                      })}
                  </select>
                  {errors.b_state && errors.b_state.type === "required" && (
                    <span className="error">State is required!</span>
                  )}
                </div>
                <div className="col-md-6 mb-3">
                  <label for="" className="form-label">
                    Zip code
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Zip code"
                    onKeyPress={(event) => {
                      // Allow only numbers (0-9) when typing
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    maxLength={5}
                    onPaste={(event) => {
                      // Prevent pasting any non-numeric characters
                      const pastedData = event.clipboardData.getData("text");
                      if (!/^\d{1,5}$/.test(pastedData)) {
                        event.preventDefault();
                      }
                    }}
                    {...register("b_zipCode", {
                      pattern: {
                        value: /^\d{5}$/,
                        message: "ZIP code must be exactly 5 digits",
                      },
                    })}
                  />
                  {errors.b_zipCode && errors.b_zipCode.type === "required" && (
                    <span className="error">Zipcode is required!</span>
                  )}
                  {errors.b_zipCode && errors.b_zipCode.type === "pattern" && (
                    <span className="error">{errors?.b_zipCode?.message}</span>
                  )}
                </div>
              </div>
              <div className="col-md-12 login-btn">
                <div className="three-btn-sec">
                  <div className="two-btn-sec">
                    {profileStatus === true || id == undefined ? (
                      <button
                        type="button"
                        className="btn btn-light"
                        onClick={() => onSubmit(null, 5)}
                      >
                        Save as draft
                      </button>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="two-btn-sec">
                    <button
                      type="button"
                      className="btn btn-light me-md-2"
                      onClick={() => setActiveTab("PrimaryCare")}
                    >
                      <svg
                        width="16"
                        height="14"
                        viewBox="0 0 10 10"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M7.91683 5.00016H2.0835M2.0835 5.00016L5.00016 7.91683M2.0835 5.00016L5.00016 2.0835"
                          stroke="black"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                      Go Back
                    </button>
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={() => ChangeTab("ALTCS")}
                    >
                      Next
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
        {/* ALTCS/AHCCCS health plan */}
        {activeTab === "ALTCS" && (
          <>
            <div className="member_vital_basic_title">
              <h4>ALTCS/AHCCCS health plan</h4>
            </div>
            <div className="member_vitalstep_one_form">
              {fields.map((item, index) => (
                <div className="row" key={item.id}>
                  {index > 0 && <hr />}
                  {index > 0 &&
                    (id ? (
                      ""
                    ) : (
                      <div className="col-12 mb-3">
                        <a
                          className="stepone_remove"
                          onClick={() => remove(index)}
                        >
                          Remove
                        </a>
                      </div>
                    ))}
                  <div className="col-md-6 mb-3">
                    <label className="form-label">Plan provider</label>
                    <input
                      type="input"
                      className="form-control"
                      placeholder="Enter Plan provider"
                      {...register(`healthCare_altcs.${index}.ha_planProvider`)}
                      maxLength={20}
                    />
                  </div>
                  <div className="col-md-6 mb-3">
                    <label className="form-label">Phone number</label>
                    <input
                      type="input"
                      className="form-control"
                      placeholder="Enter phone number"
                      {...register(`healthCare_altcs.${index}.ha_phoneNo`, {
                        pattern: {
                          value: /^\d{10,15}$/,
                          message: "Phone number must be 10 to 15 digits",
                        },
                      })}
                      maxLength={15}
                      onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      onPaste={(event) => {
                        const pastedData = event.clipboardData.getData("text");
                        if (!/^\d{1,5}$/.test(pastedData)) {
                          event.preventDefault();
                        }
                      }}
                    />
                    {errors.healthCare_altcs?.[index]?.ha_phoneNo?.type ===
                      "pattern" && (
                      <span className="error">
                        {errors.healthCare_altcs[index].ha_phoneNo.message}
                      </span>
                    )}
                  </div>
                  <div className="col-md-6 mb-3">
                    <label className="form-label">Website</label>
                    <input
                      type="input"
                      className="form-control"
                      placeholder="Enter Website"
                      {...register(`healthCare_altcs.${index}.ha_website`)}
                    />
                  </div>
                  <div className="col-md-6 mb-3">
                    <label className="form-label">ID number</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter ID number"
                      {...register(`healthCare_altcs.${index}.ha_IdNumber`, {
                        pattern: {
                          value: /^[a-zA-Z0-9]+$/,
                          message: "Only alphanumeric characters are allowed",
                        },
                      })}
                      maxLength={20}
                    />
                    {errors.healthCare_altcs?.[index]?.ha_IdNumber?.type ===
                      "pattern" && (
                      <span className="error">
                        {errors.healthCare_altcs[index].ha_IdNumber.message}
                      </span>
                    )}
                  </div>
                  <div className="col-12 mb-3">
                    <label className="form-label">
                      The process for authorization of healthcare
                    </label>
                    <textarea
                      className="form-control"
                      placeholder="Authorization process"
                      {...register(
                        `healthCare_altcs.${index}.ha_authorizationProcess`
                      )}
                      maxLength={250}
                    />
                  </div>
                </div>
              ))}
              <div className="col-12">
                <div className="stepone_health_planbtn">
                  <button
                    type="button"
                    className="font-14"
                    onClick={() =>
                      append({
                        ha_planProvider: "",
                        ha_IdNumber: "",
                        ha_phoneNo: "",
                        ha_website: "",
                        ha_authorizationProcess: "",
                      })
                    }
                  >
                    <svg
                      width="12"
                      height="12"
                      viewBox="0 0 12 12"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M6.00016 1.33325V10.6666M1.3335 5.99992H10.6668"
                        stroke="#667085"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>{" "}
                    Add another health plan
                  </button>
                </div>
              </div>
              <div className="col-md-12 login-btn">
                <div className="three-btn-sec">
                  <div className="two-btn-sec">
                    {profileStatus === true || id == undefined ? (
                      <button
                        type="button"
                        className="btn btn-light"
                        onClick={() => onSubmit(null, 6)}
                      >
                        Save as draft
                      </button>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="two-btn-sec">
                    <button
                      type="button"
                      className="btn btn-light me-md-2"
                      onClick={() => setActiveTab("behaviouralHealth")}
                    >
                      <svg
                        width="16"
                        height="14"
                        viewBox="0 0 10 10"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M7.91683 5.00016H2.0835M2.0835 5.00016L5.00016 7.91683M2.0835 5.00016L5.00016 2.0835"
                          stroke="black"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                      Go Back
                    </button>
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={() => ChangeTab("MedicareHealth")}
                    >
                      Next
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
        {activeTab === "MedicareHealth" && (
          <>
            <div className="member_vital_basic_title">
              <h4>Medicare health plan</h4>
            </div>
            <div className="member_vitalstep_one_form">
              <div className="row">
                <div className="col-12 mb-3">
                  <label for="" className="form-label">
                    Medicare sections
                  </label>
                  <input
                    type="input"
                    className="form-control"
                    id=""
                    placeholder="Enter Medicare sections"
                    {...register("m_madicareSection")}
                    maxLength={20}
                  />
                  {errors.m_madicareSection &&
                    errors.m_madicareSection.type === "required" && (
                      <span className="error">
                        Medicare sections is required!
                      </span>
                    )}
                </div>
                <div className="col-12 mb-3">
                  <label for="" className="form-label">
                    ID number
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter ID number"
                    {...register("m_IdNumber", {
                      pattern: {
                        value: /^[a-zA-Z0-9]+$/,
                        message: "Only alphanumeric characters are allowed",
                      },
                    })}
                    maxLength={20}
                  />
                  {errors.m_IdNumber &&
                    errors.m_IdNumber.type === "required" && (
                      <span className="error">ID number is required</span>
                    )}
                  {errors.m_IdNumber &&
                    errors.m_IdNumber.type === "pattern" && (
                      <span className="error">
                        {errors?.m_IdNumber?.message}
                      </span>
                    )}
                </div>
                <div className="col-12 mb-3">
                  <label for="" className="form-label">
                    Phone number
                  </label>
                  <input
                    type="input"
                    className="form-control"
                    id=""
                    placeholder="Enter phone number"
                    {...register("m_phoneNumber", {
                      pattern: {
                        value: /^\d{10,15}$/,
                        message: "Phone number must be 10 to 15 digits",
                      },
                    })}
                    maxLength={15}
                    onKeyPress={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    onPaste={(event) => {
                      // Prevent pasting any non-numeric characters
                      const pastedData = event.clipboardData.getData("text");
                      if (!/^\d{1,5}$/.test(pastedData)) {
                        event.preventDefault();
                      }
                    }}
                  />
                  {errors.m_phoneNumber &&
                    errors.m_phoneNumber.type === "required" && (
                      <span className="error">Phone number is required</span>
                    )}
                  {errors.m_phoneNumber &&
                    errors.m_phoneNumber.type === "pattern" && (
                      <span className="error">
                        {errors?.m_phoneNumber?.message}
                      </span>
                    )}
                </div>
                <div className="col-12">
                  <div className="member_vital_basic_title">
                    <h4>Other health plans</h4>
                  </div>
                </div>
                <div className="col-12 mb-3">
                  <label for="" className="form-label">
                    Plan provider
                  </label>
                  <input
                    type="input"
                    className="form-control"
                    id=""
                    placeholder="Enter Plan provider"
                    {...register("m_planprovider")}
                    maxLength={20}
                  />
                  {errors.m_planprovider &&
                    errors.m_planprovider.type === "required" && (
                      <span className="error">Plan provider is required!</span>
                    )}
                </div>
                <div className="col-12 mb-3">
                  <label className="form-label">Policy holder</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter policy holder"
                    {...register("m_policyHolder")}
                    maxLength={15}
                    onKeyPress={(event) => {
                      if (!/[a-zA-Z0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                  />
                </div>
                <div className="col-12 mb-3">
                  <label for="" className="form-label">
                    ID/Group number
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter ID/Group number"
                    {...register("m_groupNumber")}
                    maxLength={15}
                  />
                  {errors.m_groupNumber &&
                    errors.m_groupNumber.type === "required" && (
                      <span className="error">Group number is required</span>
                    )}
                  {errors.m_groupNumber &&
                    errors.m_groupNumber.type === "pattern" && (
                      <span className="error">
                        {errors?.m_groupNumber?.message}
                      </span>
                    )}
                </div>
                <div className="col-12 mb-3">
                  <label for="" className="form-label">
                    Phone number
                  </label>
                  <input
                    type="text" // Changed from "input" to "text" as "input" is not a valid type
                    className="form-control"
                    placeholder="Enter phone number"
                    {...register("m_planPhoneNumber")}
                    maxLength={15}
                    onKeyPress={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    onPaste={(event) => {
                      // Prevent pasting non-numeric characters
                      const pastedData = event.clipboardData.getData("text");
                      if (!/^\d+$/.test(pastedData)) {
                        // Updated regex to allow any number of digits up to maxLength
                        event.preventDefault();
                      }
                    }}
                  />
                  {errors.m_planPhoneNumber &&
                    errors.m_planPhoneNumber.type === "required" && (
                      <span className="error">Phone number is required</span>
                    )}
                  {errors.m_planPhoneNumber &&
                    errors.m_planPhoneNumber.type === "pattern" && (
                      <span className="error">
                        {errors?.m_planPhoneNumber?.message}
                      </span>
                    )}
                </div>
                <div className="col-md-12 login-btn">
                  <div className="three-btn-sec">
                    <div className="two-btn-sec">
                      {profileStatus || id == undefined ? (
                        <button
                          type="button"
                          className="btn btn-light"
                          onClick={() => onSubmit(null, 7)}
                        >
                          Save as draft
                        </button>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="two-btn-sec">
                      <button
                        type="button"
                        className="btn btn-light me-md-2"
                        onClick={() => setActiveTab("ALTCS")}
                        me-md-2
                      >
                        <svg
                          width="16"
                          height="14"
                          viewBox="0 0 10 10"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M7.91683 5.00016H2.0835M2.0835 5.00016L5.00016 7.91683M2.0835 5.00016L5.00016 2.0835"
                            stroke="black"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                        Go Back
                      </button>
                      <button type="submit" className="btn btn-primary">
                        Submit
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default AddHealthInfo;
