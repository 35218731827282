import React,{useEffect, useState} from "react";
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Pagination } from '../../Pagination';
import { formatAMPM, monthDayYearFormat } from '../../../components/Common/Common';

const TableView = ({ marList, currentPage, totalPages, handlePageChange, onRowClick }) => {
    const navigate = useNavigate();
    const approvalStatus = useSelector(state => state?.AuthMar?.EditMemberDetails?.approval_status);

    const [sortOrder, setSortOrder] = useState('asc'); // Default to ascending order
    const [sortedMar, setSortedMar] = useState([]); // Default sort field


    useEffect(() => {
        
        if (marList && marList.length > 0) {
            const sortedTasks = [...marList].sort((a, b) => {
                const aValue = a?.member?.first_name || ''; // Default to an empty string if undefined/null
                const bValue = b?.member?.first_name || ''; // Default to an empty string if undefined/null
    
                // Ensure proper comparison based on the sortOrder
                if (sortOrder === 'asc') {
                    return aValue.localeCompare(bValue); // String comparison in ascending order
                } else {
                    return bValue.localeCompare(aValue); // String comparison in descending order
                }
            });
            
            setSortedMar(sortedTasks);
        }
    }, [marList, sortOrder]);
    

    

    return (
        <div className="main-content-sec vit_info_data medicat_tb_cont">
            <div className="row">
                <div className="col-md-12 col-12">
                    <div className="table-sec">
                        <div className="table-responsive">
                            <table className="table table-hover">
                                <thead>
                                    <tr>
                                        <>
                                            <th scope="col">
                                                <div className="table-profile" style={{marginBottom:"0px"}}>
                                                    <div className="form-check">
                                                        <input className="form-check-input" type="checkbox" id="flexCheckIndeterminate" />
                                                    </div>
                                                    <div className="mar_col_head">Individual Name</div>
                                                    <svg className="ms-1" width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={() => setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc')}>
                                                        <path d="M6 1.33337V10.6667M6 10.6667L10.6667 6.00004M6 10.6667L1.33333 6.00004" stroke="#475467" strokeWidth="0.888889" strokeLinecap="round" strokeLinejoin="round" />
                                                    </svg>
                                                </div>
                                            </th>
                                            <th scope="col">Medication Name</th>
                                            <th scope="col">Last Administered Date</th>
                                            <th scope="col">Last Administered Status</th>
                                            <th scope="col">Approval Status</th>
                                            <th scope="col">Action</th>
                                        </>
                                    </tr>
                                </thead>
                                <tbody>
                                    {marList && marList.map((item) => {
                                     
                                        const initials = item?.member?.first_name.charAt(0) + item?.member?.last_name.charAt(0);
                                        let date = item?.administer_date !== 'N/A'
                                            ? monthDayYearFormat(item?.administer_date.split(' ')[0])
                                            : item?.administer_date

                                        return (
                                            <tr key={item?.id}>
                                                <>
                                                    <td>
                                                        <div className="table-profile">
                                                            <div className="form-check">
                                                                <input className="form-check-input" type="checkbox" id={`checkbox-${item?.id}`} />
                                                            </div>
                                                            <div className="profile-sec" onClick={() => onRowClick(item?.id)}>
                                                                <span className="name green">
                                                                    {item?.member?.profile_pic ? (
                                                                        <img src={item?.member?.profile_pic} alt={`${item?.member?.first_name} profile`} />
                                                                    ) : (
                                                                        initials
                                                                    )}
                                                                </span>
                                                                <div className="profile-info">
                                                                    <div className="font-14 hd-title">{item?.member?.first_name} {item?.member?.last_name}</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td>{item?.masterMedication?.name}</td>
                                                    <td>
                                                        {date}
                                                        <br />
                                                        {item?.administer_date.split(' ').length === 2 ? (
                                                            <span className="d-block">{formatAMPM(item?.administer_date.split(' ')[1])}</span>
                                                        ) : null}
                                                    </td>
                                                    <td>
                                                        <div className="badges">
                                                            <span className={item?.administer_status === 'due' ? 'actives' : 'overdue'}></span>
                                                            {item?.administer_status}
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="badges">
                                                            <span className={item?.approval_status === 'pending' ? 'pend' : 'actives'}></span>
                                                            {item?.approval_status}
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <a className={item?.approval_status} onClick={() => item?.approval_status === 'pending' ? null : navigate(`/auth/open-mar/${item?.member?.id}`)}>
                                                            Open MAR
                                                        </a>
                                                    </td>
                                                </>
                                            </tr>
                                        );
                                    })}
                                    <tr>
                                        <td colSpan="7">
                                            <Pagination
                                                currentPage={currentPage}
                                                totalPages={totalPages}
                                                onPageChange={handlePageChange}
                                            />
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TableView;
