import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { customAlert } from "../../utils/alert";
import { HTTPURL } from "../../constant/Matcher";
export const VerifyOtp = createAsyncThunk(
    "auth/verifyOtp",
    async (formData, thunkAPI) => {
        try {
            let config = {
                method: 'Post',
                url: `${HTTPURL}api/verify-otp-number`,
                headers: {
                    "Content-Type": "application/json",
                },
                data:formData
            };
            const res = await axios.request(config);            
            if (res?.data?.status) {
                customAlert(res?.data?.message,'success');
            } else {
                customAlert(res?.data?.message);
            }
            return res.data;
        } catch (error) {            
            customAlert(error?.response?.data? error?.response?.data?.message : error?.message);
        }
    }
);


export const Send_Otp_Change_Number = createAsyncThunk(
    "auth/send-otp",
    async (formData, thunkAPI) => {
        const state = thunkAPI.getState();
        const token = state?.auth?.token;
        const refreshToken = localStorage.getItem('refreshToken');
        try {
            let config = {
                method: 'Post',
                url: `${HTTPURL}api/send-otp`,
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'refreshToken': `${refreshToken}`,
                },
                data:formData
            };
            const res = await axios.request(config);
            if (res?.data?.status) {
                customAlert(res?.data?.message,'success');
            } else {
                customAlert(res?.data?.message);
            }
            return res.data;
        } catch (error) {
            customAlert(error?.response?.data? error?.response?.data?.message : error?.message);
        }
    }
);

export const Verify_Otp_Change_Number = createAsyncThunk(
    "auth/verify-otp",
    async (formData, thunkAPI) => {
        
        const state = thunkAPI.getState();
        const token = state?.auth?.token;
        const refreshToken = localStorage.getItem('refreshToken');
        try {
            let config = {
                method: 'Post',
                url: `${HTTPURL}api/verify-otp`,
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'refreshToken': `${refreshToken}`,
                },
                data:formData
            };
            const res = await axios.request(config);
            if (res?.data?.status) {
                customAlert(res?.data?.message,'success');
            } else {
                customAlert(res?.data?.message);
            }
            return res.data;
        } catch (error) {
            customAlert(error?.response?.data? error?.response?.data?.message : error?.message);
        }
    }
);

export const Verify_text_otp_number = createAsyncThunk(
    "auth/verify-txt-otp-number",
    async (formData, thunkAPI) => {
        
        const state = thunkAPI.getState();
        const token = state?.auth?.token;
        const refreshToken = localStorage.getItem('refreshToken');
        try {
            let config = {
                method: 'Post',
                url: `${HTTPURL}api/verify-txt-otp-number`,
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'refreshToken': `${refreshToken}`,
                },
                data:formData
            };
            const res = await axios.request(config);
            if (res?.data?.status) {
                customAlert(res?.data?.message,'success');
            } else {
                customAlert(res?.data?.message);
            }
            return res.data;
        } catch (error) {
            customAlert(error?.response?.data? error?.response?.data?.message : error?.message);
        }
    }
);