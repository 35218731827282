import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  get_all_users,
  export_excel_file_users,
} from "../../../redux/services/AuthUser";
import { useDispatch, useSelector } from "react-redux";
import DeActivateUser from "./DeActivateUser";
import { Pagination } from "../../Pagination";
import { get_all_roles } from "../../../redux/services/common";
import NoUsers from "./NoUserData";
import { setDataTableCheck } from "../../../redux/features/commonSlice";
import { get_loggedin_user_homes } from "../../../redux/services/AuthHomes";
import { ExportToExcel } from "../../ExportFile";
import { setUserPermissions } from "../../../redux/features/commonSlice";

const initSearch = {
  role: "",
  status: "",
  page: 1,
  listData: 10,
  home: "",
  searchBar: "",
};
const AllUsers = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const RoleData = useSelector((state) => state?.common?.RoleData);
  const UsersData = useSelector((state) => state?.AuthUsers?.AllUsers?.users);
  const userDataCheck = useSelector(
    (state) => state?.common?.DataTableCheck?.userData
  );
  const homeslist = useSelector((state) => state?.AuthHomes?.LoggedinUserHomes);
  const MemberDataCheck = useSelector((state) => state?.common?.DataTableCheck);
  const moduleData = useSelector((state) => state.common.moduleData);
  const userPermissions = useSelector((state) => state.common.userPermissions);
  const UserTotalPages = useSelector((state) => state?.AuthUsers?.AllUsers);

  const totalPages = UserTotalPages?.totalPages;

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [checked, setChecked] = useState(false);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [showFilter, setShowFilter] = useState(false);
  const [search, setSearch] = useState(initSearch);
  const [initialUsersDataFetched, setInitialUsersDataFetched] = useState(false);

  useEffect(() => {
    dispatch(get_all_roles());
    dispatch(get_loggedin_user_homes());
    dispatch(get_all_users(search));
  }, [search, currentPage]);

  useEffect(() => {
    // debugger
    if (
      (UsersData !== undefined &&
        !initialUsersDataFetched &&
        userDataCheck == undefined) ||
      userDataCheck == 0
    ) {
      if (UsersData?.length > 0) {
        dispatch(setDataTableCheck({ ...userDataCheck, userData: 1 }));
      } else {
        dispatch(setDataTableCheck({ ...userDataCheck, userData: 0 }));
      }
      setInitialUsersDataFetched(true);
    }
  }, [dispatch, UsersData, initialUsersDataFetched]);

  useEffect(() => {
    if (moduleData.length > 0) {
      const module = moduleData.find((item) => item.module_name === "Users");
      if (module) {
        const permissionsArray = module.permission.map(
          (item) => item.permission
        );
        dispatch(setUserPermissions(permissionsArray));
      }
    }
  }, [moduleData, dispatch]);

  const checkAll = () => {
    const allUserIds = UsersData?.filter(
      (user) => !user.is_active === false
    ).map((user) => user.ur_id);
    setSelectedUsers(checked ? [] : allUserIds);
    setChecked(!checked);
  };
  const handleSingleCheck = (userId) => {
    const currentIndex = selectedUsers.indexOf(userId);
    const newSelectedUsers = [...selectedUsers];
    if (currentIndex === -1) {
      newSelectedUsers.push(userId);
    } else {
      newSelectedUsers.splice(currentIndex, 1);
    }

    setSelectedUsers(newSelectedUsers);
  };
  const EditData = (id) => {
    if (userPermissions?.includes("Edit")) {
      navigate(`/auth/users-edit/${id}`);
    }
  };
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
    setSearch({
      ...search,
      page: pageNumber,
    });
  };
  const handleRoleChange = (e) => {
    setSearch({
      ...search,
      role: e.target.value,
    });
  };
  const handleStatusChange = (e) => {
    setCurrentPage(1);
    setSearch({
      ...search,
      page: 1,
      status: e.target.value,
    });
  };
  const handleHomeChange = (e) => {
    setSearch({
      ...search,
      home: e.target.value,
    });
  };
  const handleSearchBar = (e) => {
    setCurrentPage(1);
    setSearch({
      ...search,
      page: 1,
      searchBar: e.target.value,
    });
  };
  function transformUserData(users) {
    return users?.map((user) => ({
      Name: `${user?.first_name} ${user?.last_name}`.trim(),
      Status: user?.is_active ? "Active" : "Inactive",
      Role: user?.role_name,
      Username: user?.username,
      Home: user?.homeUser || "N/A",
      Email: user?.email_address || "N/A",
    }));
  }

  const exportToExcel = async () => {
    const response = await dispatch(export_excel_file_users());
    let userAllData = transformUserData(response?.payload?.data);
    if (response?.payload?.status == true) {
      ExportToExcel(userAllData, "Users");
    }
  };

  return (
    <>
      <div id="layoutSidenav_content">
        <main>
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12 col-lg-12 col-12">
                <div className="right-main-sec">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to="/auth/dashboard">
                        <svg
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M6.77168 11.6663C7.14172 13.104 8.4468 14.1663 10 14.1663C11.5532 14.1663 12.8583 13.104 13.2283 11.6663M9.18141 2.30297L3.52949 6.6989C3.15168 6.99275 2.96278 7.13968 2.82669 7.32368C2.70614 7.48667 2.61633 7.67029 2.56169 7.86551C2.5 8.0859 2.5 8.32521 2.5 8.80384V14.833C2.5 15.7664 2.5 16.2331 2.68166 16.5896C2.84144 16.9032 3.09641 17.1582 3.41002 17.318C3.76654 17.4996 4.23325 17.4996 5.16667 17.4996H14.8333C15.7668 17.4996 16.2335 17.4996 16.59 17.318C16.9036 17.1582 17.1586 16.9032 17.3183 16.5896C17.5 16.2331 17.5 15.7664 17.5 14.833V8.80384C17.5 8.32521 17.5 8.0859 17.4383 7.86551C17.3837 7.67029 17.2939 7.48667 17.1733 7.32368C17.0372 7.13968 16.8483 6.99275 16.4705 6.69891L10.8186 2.30297C10.5258 2.07526 10.3794 1.9614 10.2178 1.91763C10.0752 1.87902 9.92484 1.87902 9.78221 1.91763C9.62057 1.9614 9.47418 2.07526 9.18141 2.30297Z"
                            stroke="#667085"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </Link>
                    </li>
                    <li className="breadcrumb-item active">Users</li>
                  </ol>

                  <div className="right-main-header search-sec">
                    <div className="left-side-content">
                      <div className="font-30 hd-title">Users</div>
                    </div>
                    <div className="left-side-content">
                      <button
                        type="button"
                        className={`btn btn-light ${
                          selectedUsers.length == 0 ? "me-2" : ""
                        }`}
                        onClick={() => exportToExcel()}
                      >
                        <svg
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M11.6667 1.8916V5.33372C11.6667 5.80043 11.6667 6.03378 11.7575 6.21204C11.8374 6.36885
                                                 11.9649 6.49633 12.1217 6.57622C12.3 6.66705 12.5333 6.66705 13 6.66705H16.4422M7.50004 12.5003L10
                                                  15.0003M10 15.0003L12.5 12.5003M10 15.0003L10 10.0003M11.6667 1.66699H7.33337C5.93324 1.66699
                                                   5.23318 1.66699 4.6984 1.93948C4.22799 2.17916 3.84554 2.56161 3.60586 3.03202C3.33337 3.5668
                                                    3.33337 4.26686 3.33337 5.66699V14.3337C3.33337 15.7338 3.33337 16.4339 3.60586 16.9686C3.84554 
                                                    17.439 4.22799 17.8215 4.6984 18.0612C5.23318 18.3337 5.93324 18.3337 7.33337 18.3337H12.6667C14.0668
                                                     18.3337 14.7669 18.3337 15.3017 18.0612C15.7721 17.8215 16.1545 17.439 16.3942 16.9686C16.6667 16.4339
                                                      16.6667 15.7338 16.6667 14.3337V6.66699L11.6667 1.66699Z"
                            stroke="#344054"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                        Export
                      </button>
                      {selectedUsers.length > 0 ? (
                        <>
                          {userPermissions?.includes("Delete") && (
                            <button
                              type="button"
                              className="btn btn-danger mx-2"
                              onClick={() => setModalIsOpen(true)}
                            >
                              <svg
                                width="20"
                                height="20"
                                viewBox="0 0 20 20"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M4.10829 4.10866L15.8916 15.892M18.3333 10.0003C18.3333 14.6027 14.6023 18.3337 9.99996 18.3337C5.39759 18.3337 1.66663 14.6027 1.66663 10.0003C1.66663 5.39795 5.39759 1.66699 9.99996 1.66699C14.6023 1.66699 18.3333 5.39795 18.3333 10.0003Z"
                                  stroke="white"
                                  stroke-width="1.66667"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                              </svg>
                              Deactivate Selected
                            </button>
                          )}
                        </>
                      ) : (
                        ""
                      )}
                      {userPermissions?.includes("Add") && (
                        <Link
                          type="button"
                          className="btn btn-primary link"
                          to="/auth/users-add"
                        >
                          <svg
                            width="21"
                            height="20"
                            viewBox="0 0 21 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M10.5 4.16699V15.8337M4.66663 10.0003H16.3333"
                              stroke="white"
                              stroke-width="1.66667"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            ></path>
                          </svg>
                          Add New User
                        </Link>
                      )}
                    </div>
                  </div>
                  {!showFilter ? (
                    <div className="table-btn-sec">
                      <div className="form-group searchbar">
                        <input
                          type="text"
                          name=""
                          id=""
                          className="form-control"
                          placeholder="Search name, email"
                          onChange={(e) => handleSearchBar(e)}
                          value={search?.searchBar}
                          maxLength={20}
                        />
                        <svg
                          width="18"
                          height="18"
                          viewBox="0 0 18 18"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M16.5 16.5L13.5834 13.5833M15.6667 8.58333C15.6667 12.4954 12.4954 15.6667 8.58333 15.6667C4.67132 15.6667 1.5 12.4954 1.5 8.58333C1.5 4.67132 4.67132 1.5 8.58333 1.5C12.4954 1.5 15.6667 4.67132 15.6667 8.58333Z"
                            stroke="#667085"
                            stroke-width="1.66667"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </div>
                      <button
                        type="button"
                        className="btn btn-light"
                        onClick={() => setShowFilter(true)}
                      >
                        <svg
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M5 10H15M2.5 5H17.5M7.5 15H12.5"
                            stroke="#344054"
                            stroke-width="1.66667"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                        More Filters
                      </button>
                    </div>
                  ) : (
                    ""
                  )}
                  {showFilter ? (
                    <div className="table-btn-sec more-filter">
                      <button
                        type="button"
                        className="btn btn-light"
                        onClick={() => (
                          setSearch({
                            ...search,
                            role: "",
                            status: "",
                            searchBar: "",
                            home: "",
                          }),
                          setShowFilter(false)
                        )}
                      >
                        <svg
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M15 5L5 15M5 5L15 15"
                            stroke="#344054"
                            stroke-width="1.66667"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                        Close Filters
                      </button>
                      <button
                        className="clear-filter"
                        onClick={() =>
                          setSearch({
                            ...search,
                            role: "",
                            status: "",
                            searchBar: "",
                            home: "",
                          })
                        }
                      >
                        Clear all filters
                      </button>
                      <div className="filter-option">
                        <div className="form-group searchbar">
                          <label className="form-label">Search</label>
                          <input
                            type="text"
                            name=""
                            id=""
                            className="form-control"
                            placeholder="Search name, email"
                            onChange={(e) => handleSearchBar(e)}
                            value={search?.searchBar}
                            maxLength={20}
                          />
                          <svg
                            width="18"
                            height="18"
                            viewBox="0 0 18 18"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M16.5 16.5L13.5834 13.5833M15.6667 8.58333C15.6667 12.4954 12.4954 15.6667 8.58333 15.6667C4.67132 15.6667 1.5 12.4954 1.5 8.58333C1.5 4.67132 4.67132 1.5 8.58333 1.5C12.4954 1.5 15.6667 4.67132 15.6667 8.58333Z"
                              stroke="#667085"
                              stroke-width="1.66667"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                        </div>
                        <div className="form-group">
                          <label className="form-label">Role</label>
                          <select
                            className="form-select"
                            aria-label="Default select example"
                            onChange={(e) => handleRoleChange(e)}
                            value={search?.role}
                          >
                            <option value="">Select role</option>
                            {RoleData &&
                              RoleData?.map((elem) => {
                                return (
                                  <option value={elem?.id}>
                                    {elem?.role_name}
                                  </option>
                                );
                              })}
                          </select>
                        </div>
                        <div className="form-group">
                          <label className="form-label">Status</label>
                          <select
                            className="form-select"
                            aria-label="Default select example"
                            onChange={(e) => handleStatusChange(e)}
                            value={search?.status}
                          >
                            <option value="">Select status</option>
                            <option value={true}>Active</option>
                            <option value={false}>Inactive</option>
                          </select>
                        </div>
                        <div className="form-group">
                          <label className="form-label">Home</label>
                          <select
                            className="form-select"
                            aria-label="Default select example"
                            onChange={(e) => handleHomeChange(e)}
                            value={search?.home}
                          >
                            <option value="">Select Home</option>
                            {homeslist &&
                              homeslist?.map((elem) => {
                                return (
                                  <option value={elem?.id}>
                                    {elem?.home_name}
                                  </option>
                                );
                              })}
                          </select>
                        </div>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                  {UsersData && UsersData.length === 0 ? (
                    <NoUsers search={search} />
                  ) : (
                    <div className="row">
                      <div className="col-md-12 col-12">
                        <div className="table-sec">
                          <div className="table-responsive">
                            <table className="table table-hover">
                              <thead>
                                <tr>
                                  <th>
                                    <div className="form-check">
                                      <input
                                        className="form-check-input"
                                        type="checkbox"
                                        checked={checked}
                                        onChange={checkAll}
                                      />
                                      <label className="form-check-label">
                                        Name
                                      </label>
                                    </div>
                                  </th>
                                  <th scope="col">Status</th>
                                  <th scope="col">Role</th>
                                  <th scope="col">Username</th>
                                  <th scope="col">Home</th>
                                  <th scope="col">Email</th>
                                </tr>
                              </thead>
                              <tbody>
                                {UsersData &&
                                  UsersData?.map((elem, i) => {
                                    let fullName =
                                      elem?.first_name
                                        ?.charAt(0)
                                        ?.toUpperCase() +
                                      elem?.last_name?.charAt(0)?.toUpperCase();
                                    return (
                                      <tr key={elem.id}>
                                        <td>
                                          {
                                            <div className="td-form-check">
                                              <div className="form-check">
                                                <input
                                                  className="form-check-input"
                                                  type="checkbox"
                                                  checked={selectedUsers.includes(
                                                    elem.ur_id
                                                  )}
                                                  onChange={() =>
                                                    handleSingleCheck(
                                                      elem.ur_id
                                                    )
                                                  }
                                                  disabled={
                                                    elem?.is_active === false
                                                      ? true
                                                      : false
                                                  }
                                                />
                                              </div>
                                              <div className="profile-sec">
                                                <span className="name ">
                                                  {elem?.profile_img_url ? (
                                                    <img
                                                      src={
                                                        elem?.profile_img_url
                                                      }
                                                    />
                                                  ) : (
                                                    <img
                                                      src="/pro.png"
                                                      alt={`'s profile`}
                                                    />
                                                  )}
                                                </span>
                                                <div
                                                  className="font-14 hd-title"
                                                  onClick={() =>
                                                    EditData(elem?.ur_id)
                                                  }
                                                >
                                                  {elem?.first_name}{" "}
                                                  {elem?.last_name}
                                                </div>
                                              </div>
                                            </div>
                                          }
                                        </td>
                                        <td>
                                          {(() => {
                                            if (elem?.is_active === true) {
                                              return (
                                                <div className="badges">
                                                  <span className="actives"></span>
                                                  Active
                                                </div>
                                              );
                                            } else {
                                              return (
                                                <div className="badges">
                                                  <span
                                                    style={{
                                                      backgroundColor: "orange",
                                                    }}
                                                  ></span>
                                                  Inactive
                                                </div>
                                              );
                                            }
                                          })()}
                                        </td>
                                        <td>{elem?.role_name}</td>
                                        <td>{elem?.username}</td>
                                        <td>
                                          <div className="tooltipp">
                                            <span className="tooltipptext">
                                              {elem?.homeList ? (
                                                <ul>
                                                  {elem.homeList
                                                    .split(",")
                                                    .map((ele, index) => (
                                                      <li key={index}>
                                                        {ele.trim()}
                                                      </li>
                                                    ))}
                                                </ul>
                                              ) : (
                                                "No home assigned"
                                              )}
                                            </span>
                                            <p>
                                              {elem?.homeList
                                                ? `${
                                                    elem?.homeList?.split(
                                                      ","
                                                    )?.[0]
                                                  } ${
                                                    elem?.homeList?.split(",")
                                                      ?.length > 1
                                                      ? "..."
                                                      : ""
                                                  } `
                                                : "-"}
                                            </p>
                                          </div>
                                        </td>
                                        <td>{elem?.email_address}</td>
                                      </tr>
                                    );
                                  })}
                                <tr>
                                  <td colspan="7">
                                    <Pagination
                                      currentPage={currentPage}
                                      totalPages={totalPages}
                                      onPageChange={handlePageChange}
                                    />
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </main>
        {/* DeActivate User */}
        <DeActivateUser
          modalIsOpen={modalIsOpen}
          setModalIsOpen={setModalIsOpen}
          selectedUsers={selectedUsers}
          type={"Deactivate_All"}
          setSelectedUsers={setSelectedUsers}
          search={search}
        />
      </div>
    </>
  );
};

export default AllUsers;
