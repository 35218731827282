import moment from 'moment'

let monthNames = [
  "January", "February", "March", "April", "May", "June",
  "July", "August", "September", "October", "November", "December"
];
const ShortmonthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
let dayNames = [
  "Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"
];
let ShortDayNames = [
  "Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"
];

const getCurrentDateTime = () => {
  const now = new Date();
  const day = String(now.getDate()).padStart(2, '0');
  const month = String(now.getMonth() + 1).padStart(2, '0');
  const year = now.getFullYear();
  const hours = String(now.getHours()).padStart(2, '0');
  const minutes = String(now.getMinutes()).padStart(2, '0');

  return `${year}-${month}-${day} ${hours}:${minutes}`;
};

export const formatAMPM = (date) => {
  if (date) {
    const myArray = date.split(":");
    let hours = parseInt(myArray[0]);
    const minutes = myArray[1];
    const ampm = hours >= 12 ? ' PM' : ' AM';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    return `${hours}:${minutes}${ampm}`;
  }
};
export const monthDayYearFormat = (inputDate, isTableFormat = false) => {

  let date = isTableFormat ? inputDate : inputDate.split('-').reverse().join('-');
  
  let dateObj = new Date(date);

  let month = parseInt(dateObj.getMonth(), 10) + 1;
  let day = parseInt(dateObj.getDate(), 10);
  let year = parseInt(dateObj.getFullYear(), 10);

  let formattedDate = `${month < 10 ? '0' : ''}${month}-${day < 10 ? '0' : ''}${day}-${year}`;
  
  return formattedDate;
}
export const dayMonthYearFormat = (inputDate, isTableFormat = false) => {

  let date = isTableFormat ? inputDate : inputDate.split('-').reverse().join('-');
  
  let dateObj = new Date(date);

  let month = parseInt(dateObj.getMonth(), 10) + 1;
  let day = parseInt(dateObj.getDate(), 10);
  let year = parseInt(dateObj.getFullYear(), 10);

  let formattedDate = `${day < 10 ? '0' : ''}${day}-${month < 10 ? '0' : ''}${month}-${year}`;
  
  return formattedDate;
}

export const dateFormateOpenMAR = (inputDate) => {
  let dateObj = new Date(inputDate);
  let month = monthNames[dateObj.getMonth()];
  let year = dateObj.getFullYear();
  let formattedDate = `${month}, ${year}`;
  return formattedDate
}
export const dayDateFormateOpenMAR = (inputDate) => {
  let dateObj = new Date(inputDate);
  let dayName = dayNames[dateObj.getDay()];
  let month = monthNames[dateObj.getMonth()];
  let day = dateObj.getDate();

  let formattedDate = `${dayName}, ${month} ${day}`;

  return formattedDate;
}
export const monthDayFormateOpenMAR = (inputDate) => {  
  let date = inputDate.split('-').reverse().join('-')  
  let dateObj = new Date(date);
  let dayName = ShortDayNames[dateObj.getDay()];
  let day = dateObj.getDate();

  let formattedDate = `${dayName} ${day}`;

  return formattedDate;
}

export const calculateTimeDifference = (givenDateTime, isDueCheck = false) => {  
  let splitDate = givenDateTime.split(' ');
  let reverseDate = splitDate[0].split('-').reverse().join('-');
  let dateAndTime = `${reverseDate} ${splitDate[1]}`;

  const givenDate = new Date(dateAndTime.replace(' ', 'T')); 

  const currentDate = getCurrentDateTime().replace(' ', 'T');
  const currentDate2 = new Date(currentDate);

  if (isNaN(givenDate.getTime()) || isNaN(currentDate2.getTime())) {
    return 'Invalid date provided';
  }

  const differenceInMilliseconds = isDueCheck ? (currentDate2 - givenDate) : (givenDate - currentDate2);

  const totalHours = differenceInMilliseconds / (1000 * 60 * 60);

  const days = Math.floor(totalHours / 24);
  const hours = Math.floor(totalHours % 24);
  const minutes = Math.floor((differenceInMilliseconds % (1000 * 60 * 60)) / (1000 * 60));

  if (isDueCheck) {
    if (days > 0) {
      return `Due ${days} day${days > 1 ? 's' : ''} ago`;
    } else if (hours > 0) {
      return `Due ${hours} hour${hours > 1 ? 's' : ''} ago`;
    } else {
      return `Due ${Math.abs(minutes)} minute${Math.abs(minutes) !== 1 ? 's' : ''} ago`;
    }
  } else {
    if (days > 0) {
      return `${days} day${days > 1 ? 's' : ''}`;
    } else if (hours > 0) {
      return `${hours} hour${hours > 1 ? 's' : ''}`;
    } else {
      return `${Math.abs(minutes)} minute${Math.abs(minutes) !== 1 ? 's' : ''}`;
    }
  }
};
export const getMonthDate = (inputDate) => {
  let dateObj = new Date(inputDate);
  let month = ShortmonthNames[dateObj.getMonth()];
  let day = dateObj.getDate();
  let year = dateObj.getFullYear();

  let formattedDate = `${month} ${day}, ${year}`;

  return formattedDate;
}
export const AppointmentDates = (date, selectedFrequency, selectedEndDate) => {
  let selectedDate = moment(date);  
  let endDate = ''; 
  let appointmentDates = [];  
  if (selectedEndDate) {
    endDate = moment(selectedEndDate).format('YYYY-MM-DD');
  } else {
    if (selectedFrequency === 'daily') {
      endDate = selectedDate.clone().add(1, 'week').format('YYYY-MM-DD');
    } else if (selectedFrequency === 'weekly') {
      endDate = selectedDate.clone().add(1, 'month').format('YYYY-MM-DD');
    } else if (selectedFrequency === 'monthly') {
      endDate = selectedDate.clone().add(1, 'year').format('YYYY-MM-DD');
    } else if (selectedFrequency === 'does_not_repeat') {
      appointmentDates = [];
      endDate = '';
    }
  }

  let currentDate = selectedDate.clone();
  
  while (currentDate.isBefore(endDate) || currentDate.isSame(endDate, 'day')) {
    appointmentDates.push(currentDate.format('YYYY-MM-DD'));

    if (selectedFrequency === 'daily') {
      currentDate.add(1, 'day');
    } else if (selectedFrequency === 'weekly') {
      currentDate.add(1, 'week');
    } else if (selectedFrequency === 'monthly') {
      currentDate.add(1, 'month');
    } else {
      break;  
    }
  }
  return { appointmentDates: appointmentDates, endDate: endDate };
}

export const NotificationTimeFormate = (created_date) => {

  const createdDate = new Date(created_date);

  const now = new Date();

  const diffInMs = now - createdDate;


  const diffInMinutes = Math.floor(diffInMs / (1000 * 60));


  const diffInHours = Math.floor(diffInMs / (1000 * 60 * 60));
  const diffInDays = Math.floor(diffInMs / (1000 * 60 * 60 * 24));

  const rtf = new Intl.RelativeTimeFormat('en', { numeric: 'auto' });


  if (diffInDays > 0) {
    return rtf.format(-diffInDays, 'day'); 
  } else if (diffInHours > 0) {
    return rtf.format(-diffInHours, 'hour');
  } else if (diffInMinutes > 0) {
    return rtf.format(-diffInMinutes, 'minute'); 
  } else {
    return 'Just now'; 
  }
}

export const fileFormat = {
  'application/msword': 'msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document': 'doc',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'xls',
  'application/pdf': 'pdf',
  'image/png': 'png',
  'image/jpeg': 'jpeg',
  'image/jpg': 'jpg'
};