import React, { useEffect } from "react";
import { useForm, useFieldArray } from "react-hook-form";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import PcspFooter from "../../PcspFooter";
import { Get_selected_option_risks } from "../../../../../../redux/services/AuthMembers";

const RisksAssessment = ({ onHandleBackStep, onSubmit }) => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const allDddPcsp = useSelector(
    (state) => state?.AuthMember?.allDddPcsp?.risksAssemtents
  );
  const allSelectedOptionRisks = useSelector(
    (state) => state?.AuthMember?.allSelectedOptionRisks
  );

  const {
    control,
    handleSubmit,
    register,
    setValue,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    defaultValues: {
      riskaAssessments: [
        {
          date_identified: "",
          describe_risk: "",
          factors_contributing_risk: "",
          risk_preventions: "",
          member_dddpcsp_risks_id: "",
        },
      ],
    },
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "riskaAssessments",
  });

  useEffect(() => {
    dispatch(Get_selected_option_risks(id));
  }, [dispatch, id]);

  useEffect(() => {
    if (allDddPcsp) {
      allDddPcsp.forEach((item, index) => {
        setValue(`riskaAssessments.${index}.date_identified`, item.date_identified || "");
        setValue(`riskaAssessments.${index}.describe_risk`, item.describe_risk || "");
        setValue(`riskaAssessments.${index}.factors_contributing_risk`, item.factors_contributing_risk || "");
        setValue(`riskaAssessments.${index}.member_dddpcsp_risks_id`, item.member_dddpcsp_risks_id || "");
        setValue(`riskaAssessments.${index}.risk_preventions`, item.risk_preventions || "");
      });
    }
  }, [allDddPcsp, setValue]);

  return (
    <div
      className="tab-pane fade goal_tab show active"
      id="v-pills-ris"
      role="tabpanel"
      aria-labelledby="v-pills-ris-tab"
    >
      <form onSubmit={handleSubmit(onSubmit)} className="row">
        <div className="col-12">
          <div className="alert alert-light sap-alert-heading">
            <div className="hd-subtitle font-16">
              This section is only applicable if a member’s rights are being restricted...
            </div>
          </div>
        </div>

        {fields.map((member, index) => (
          <div key={member.id} className="row">
            {index > 0 && <hr />}

            {/* Risk Select Field */}
            <div className="col-12 mb-3">
              <label className="form-label">What is the risk*</label>
              <select
                className="form-select"
                {...register(`riskaAssessments.${index}.member_dddpcsp_risks_id`, {
                  required: "Risk is required!",
                })}
              >
                <option value="">Select</option>
                {allSelectedOptionRisks?.map((item) => (
                  <option key={item?.dddpcspRisksMaster?.id} value={item?.dddpcspRisksMaster?.id}>
                    {item?.dddpcspRisksMaster?.risk_name}
                  </option>
                ))}
              </select>
              {errors?.riskaAssessments?.[index]?.member_dddpcsp_risks_id && (
                <span className="error">{errors.riskaAssessments[index].member_dddpcsp_risks_id.message}</span>
              )}
            </div>

            {/* Date Identified */}
            <div className="col-12 mb-3">
              <label className="form-label">Date identified*</label>
              <input
                type="date"
                className="form-control"
                {...register(`riskaAssessments.${index}.date_identified`, {
                  required: "Date identified is required",
                })}
                onKeyDown={(e) => e.preventDefault()}
              />
              {errors?.riskaAssessments?.[index]?.date_identified && (
                <span className="error">{errors.riskaAssessments[index].date_identified.message}</span>
              )}
            </div>

            {/* Describe the risk */}
            <div className="col-12 mb-3">
              <label className="form-label">
                Describe the risk. What does it look like for the member?
              </label>
              <textarea
                className="form-control"
                {...register(`riskaAssessments.${index}.describe_risk`, {
                  required: "This field is required",
                })}
                maxLength={150}
              />
              {errors?.riskaAssessments?.[index]?.describe_risk && (
                <span className="error">{errors.riskaAssessments[index].describe_risk.message}</span>
              )}
            </div>

            {/* Factors contributing to risk */}
            <div className="col-12 mb-3">
              <label className="form-label">List the factors contributing to the risk*</label>
              <textarea
                className="form-control"
                {...register(`riskaAssessments.${index}.factors_contributing_risk`, {
                  required: "This field is required",
                })}
                maxLength={150}
              />
              {errors?.riskaAssessments?.[index]?.factors_contributing_risk && (
                <span className="error">{errors.riskaAssessments[index].factors_contributing_risk.message}</span>
              )}
            </div>

            {/* Risk Prevention Measures */}
            <div className="col-12 mb-3">
              <label className="form-label">
                What is currently working to prevent the risk?
              </label>
              <textarea
                className="form-control"
                {...register(`riskaAssessments.${index}.risk_preventions`, {
                  required: "This field is required",
                })}
                maxLength={150}
              />
              {errors?.riskaAssessments?.[index]?.risk_preventions && (
                <span className="error">{errors.riskaAssessments[index].risk_preventions.message}</span>
              )}
            </div>

            {/* Remove Button */}
            {index > 0 && (
              <div className="col-12 mb-3">
                <button
                  className="stepone_remove"
                  onClick={(e) => {
                    e.preventDefault();
                    remove(index);
                  }}
                >
                  Remove
                </button>
              </div>
            )}
          </div>
        ))}

        {/* Add Another Risk */}
        <div className="stepone_health_planbtn">
          <button type="button" className="font-14" onClick={() => append({})}>
            Add another health plan
          </button>
        </div>

        <PcspFooter onHandleBackStep={onHandleBackStep} />
      </form>
    </div>
  );
};

export default RisksAssessment;
