import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import AddRoles from "./Add";
import EditRoles from "./Edit";
import Avatar1 from "../../../../Images/Avatar-1.png";
import NoRoles from "./NoRoles";
const Roles = () => {
  const [id, setId] = useState("");
  const AllRoleDetail = useSelector(
    (state) => state?.AuthSettings?.settingInfo?.rolesPermission
  );
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [EditmodalIsOpen, setEditModalIsOpen] = useState(false);
  const [delteModal, setDeleteModal] = useState(false);
  return (
    <>
      {/* <div className="tab-pane fade" id="role" role="tabpanel" aria-labelledby="role-tab"> */}
      <div className="row" id="roles">
        <div className="col-md-12 login-btn pb-4">
          <button
            type="button"
            className="btn btn-primary"
            onClick={() => setModalIsOpen(true)}
          >
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M9.99935 4.16699V15.8337M4.16602 10.0003H15.8327"
                stroke="#FCFCFD"
                stroke-width="1.66667"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            New Role
          </button>
        </div>
        {AllRoleDetail && AllRoleDetail.length === 0 ? (
          <NoRoles />
        ) : (
          <>
            {AllRoleDetail?.map((elem) => {
              return (
                <div className="col-md-4 col-12">
                  <div
                    className="role-listing"
                    onClick={() => (
                      setId(elem?.id),
                      setEditModalIsOpen(true),
                      setDeleteModal(false)
                    )}
                  >
                    <a>
                      <div className="font-20 py-3">
                        {elem?.role_name.length > 20
                          ? elem?.role_name.substring(0, 20) + "..."
                          : elem?.role_name}
                      </div>
                    </a>
                    <p>
                      {elem?.role_desciption.length > 20
                        ? elem?.role_desciption.substring(0, 20) + "..."
                        : elem?.role_desciption}
                    </p>

                    <div
                      className="flex gap-2 center"
                      style={{
                        position: "relative",
                        marginTop: "20px",
                        alignItems: "center",
                      }}
                    >
                      {elem?.roleUser?.length > 0 ? (
                        <div
                          className="flex"
                          style={{
                            position: "relative",
                            height: "40px",
                            alignItems: "center",
                          }}
                        >
                          {elem?.roleUser
                            ?.slice(0, 5)
                            .reverse() // Reverse the order so latest comes on top
                            .map((ele, index) => {
                              return (
                                <div
                                  className="tooltipp"
                                  key={index}
                                  style={{
                                    position: "absolute",
                                    left: `${index * 27}px`, // Overlapping effect
                                    zIndex: index, // Latest appears on top
                                  }}
                                >
                                  <span
                                    className="tooltipptext"
                                    style={{
                                      color: "white",
                                      background: "black",
                                    }}
                                  >
                                    {ele?.user?.first_name +
                                      " " +
                                      ele?.user?.last_name}
                                  </span>
                                  <div className="profile-sec curve appointment-member">
                                    <span className="name">
                                      {ele.user?.profile_img_url ? (
                                        <img
                                          src={ele.user?.profile_img_url}
                                          alt={`${ele.user.first_name}'s profile`}
                                          style={{
                                            width: "38px",
                                            height: "38px",
                                            borderRadius: "50%",
                                            border: "3px solid #ffffff",
                                          }}
                                        />
                                      ) : (
                                        <img
                                          src="/pro.png"
                                          alt={`${ele.user.first_name}'s profile`}
                                          style={{
                                            width: "37px",
                                            height: "37px",
                                            borderRadius: "50%",
                                            border: "3px solid #ffffff",
                                          }}
                                        />
                                      )}
                                    </span>
                                  </div>
                                </div>
                              );
                            })}

                          {elem?.roleUser?.length > 5 && (
                            <div className="border-white">
                              <div
                                className="tooltipp appointment_plus others_docs"
                                style={{
                                  position: "absolute",
                                  left: "132px",
                                  bottom: "4px",
                                  zIndex: "20",
                                  cursor: "pointer",
                                  background: "#F2F4F7",
                                  color: "black",
                                  width: "40px",
                                  height: "40px",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  fontSize: "14px",
                                  fontWeight: "bold",
                                  border: "3px solid white",
                                  borderRadius: "50%",
                                }}
                              >
                                <div
                                  style={{
                                    border: "1px solid #DDDDDD",
                                    borderRadius: "50%",
                                    height:"34px",
                                    width:"34px",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    color:"#475467"
                                  }}
                                >
                                  +{elem.roleUser.length - 5}
                                </div>

                                <span
                                  className="tooltipptext"
                                  style={{
                                    color: "white",
                                    background: "black",
                                    position: "absolute",
                                    left: "30px",
                                  }}
                                >
                                  {elem?.roleUser.slice(5).map((e, idx) => (
                                    <div
                                      key={idx}
                                      style={{
                                        background: "black",
                                        color: "white",
                                        display: "flex",
                                        flexDirection: "column",
                                      }}
                                    >
                                      {e.user.first_name +
                                        " " +
                                        e.user.last_name}
                                    </div>
                                  ))}
                                </span>
                              </div>
                            </div>
                          )}
                        </div>
                      ) : (
                        <p>No members assigned</p>
                      )}
                    </div>
                  </div>
                </div>
              );
            })}
          </>
        )}
      </div>
      <AddRoles modalIsOpen={modalIsOpen} setModalIsOpen={setModalIsOpen} />
      <EditRoles
        id={id}
        EditmodalIsOpen={EditmodalIsOpen}
        setEditModalIsOpen={setEditModalIsOpen}
        setDeleteModal={setDeleteModal}
        delteModal={delteModal}
      />
    </>
  );
};

export default Roles;
