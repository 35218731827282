import React, { useEffect, useState } from "react";
import { useSelector } from 'react-redux';

const SideBar = () => {
	const [initials, setInitials] = useState('')

	const communicationMemberList = useSelector(state => state?.AuthCommunication?.communicationMemberList);

	useEffect(()=>{		
		if(communicationMemberList && Object.keys(communicationMemberList).length > 0){
			setInitials(communicationMemberList?.home_name.split(' ').map(word => word[0].toUpperCase()).join(''))
		}
	},[communicationMemberList])
		
    return (
        <>
            <div id="layoutSidenav_nav">
                <nav className="sb-sidenav accordion sb-sidenav-dark" id="sidenavAccordion">
                <div className="sb-sidenav-menu">
					<div className="profile_hg">
						<span className="font-24">{initials}</span>
					</div>
					<div className="messages_icon">
						<div className="members">
							<div className="members_svg">
								<svg width="16" height="17" viewBox="0 0 16 17" fill="none"
									xmlns="http://www.w3.org/2000/svg">
									<path
										d="M4.625 6.875H4.6325M8 6.875H8.0075M11.375 6.875H11.3825M4.25 12.5V14.2516C4.25 14.6513 4.25 14.8511 4.33192 14.9537C4.40317 15.043 4.5112 15.0949 4.6254 15.0948C4.75672 15.0946 4.91275 14.9698 5.22482 14.7201L7.01391 13.2889C7.37939 12.9965 7.56213 12.8503 7.76561 12.7463C7.94615 12.6541 8.13832 12.5867 8.33691 12.5459C8.56075 12.5 8.79477 12.5 9.26281 12.5H11.15C12.4101 12.5 13.0402 12.5 13.5215 12.2548C13.9448 12.039 14.289 11.6948 14.5048 11.2715C14.75 10.7902 14.75 10.1601 14.75 8.9V4.85C14.75 3.58988 14.75 2.95982 14.5048 2.47852C14.289 2.05516 13.9448 1.71095 13.5215 1.49524C13.0402 1.25 12.4101 1.25 11.15 1.25H4.85C3.58988 1.25 2.95982 1.25 2.47852 1.49524C2.05516 1.71095 1.71095 2.05516 1.49524 2.47852C1.25 2.95982 1.25 3.58988 1.25 4.85V9.5C1.25 10.1975 1.25 10.5462 1.32667 10.8323C1.53472 11.6088 2.1412 12.2153 2.91766 12.4233C3.20378 12.5 3.55252 12.5 4.25 12.5ZM5 6.875C5 7.08211 4.83211 7.25 4.625 7.25C4.41789 7.25 4.25 7.08211 4.25 6.875C4.25 6.66789 4.41789 6.5 4.625 6.5C4.83211 6.5 5 6.66789 5 6.875ZM8.375 6.875C8.375 7.08211 8.20711 7.25 8 7.25C7.79289 7.25 7.625 7.08211 7.625 6.875C7.625 6.66789 7.79289 6.5 8 6.5C8.20711 6.5 8.375 6.66789 8.375 6.875ZM11.75 6.875C11.75 7.08211 11.5821 7.25 11.375 7.25C11.1679 7.25 11 7.08211 11 6.875C11 6.66789 11.1679 6.5 11.375 6.5C11.5821 6.5 11.75 6.66789 11.75 6.875Z"
										stroke="#2342BF" stroke-width="2" stroke-linecap="round"
										stroke-linejoin="round" />
								</svg>
							</div>
							<p className="font-18 hd-title">{communicationMemberList?.message}<span className="d-block font-14 hd-subtitle">Messages</span>
							</p>
						</div>
						<div className="members">
							<div className="members_svg">
								<svg width="16" height="16" viewBox="0 0 16 16" fill="none"
									xmlns="http://www.w3.org/2000/svg">
									<path
										d="M7.99989 10.25C5.62233 10.25 3.50799 11.398 2.16188 13.1795C1.87217 13.5629 1.72731 13.7546 1.73205 14.0137C1.73571 14.2139 1.86141 14.4664 2.01891 14.59C2.22278 14.75 2.50529 14.75 3.07031 14.75H12.9295C13.4945 14.75 13.777 14.75 13.9809 14.59C14.1384 14.4664 14.2641 14.2139 14.2677 14.0137C14.2725 13.7546 14.1276 13.5629 13.8379 13.1795C12.4918 11.398 10.3774 10.25 7.99989 10.25Z"
										stroke="#2342BF" stroke-width="2" stroke-linecap="round"
										stroke-linejoin="round" />
									<path
										d="M7.99989 8C9.86385 8 11.3749 6.48896 11.3749 4.625C11.3749 2.76104 9.86385 1.25 7.99989 1.25C6.13593 1.25 4.62489 2.76104 4.62489 4.625C4.62489 6.48896 6.13593 8 7.99989 8Z"
										stroke="#2342BF" stroke-width="2" stroke-linecap="round"
										stroke-linejoin="round" />
								</svg>
							</div>
							<p className="font-18 hd-title">{communicationMemberList?.member?.length}<span className="d-block font-14 hd-subtitle"> Members</span></p>
						</div>
					</div>
					<div className="program_name">
						<p className="font-14 hd-subtitle">Group Home Name</p>
						<h6 className="font-14 hd-title">{communicationMemberList?.home_name}</h6>
					</div>
					<div className="program_name">
						<p className="font-14 hd-subtitle">Phone No.</p>
						<h6 className="font-14 hd-title">{communicationMemberList?.phone_no}</h6>
					</div>
					<div className="program_name">
						<p className="font-14 hd-subtitle">Site Id</p>
						<h6 className="font-14 hd-title">{communicationMemberList?.cost_center_number}</h6>
					</div>
					{/* <div className="program_name">
						<p className="font-14 hd-subtitle">Program Type</p>
						<h6 className="font-14 hd-title">24-Hour Residential (Children)</h6>
					</div>
					<div className="program_name">
						<p className="font-14 hd-subtitle">Program ID</p>
						<h6 className="font-14 hd-title">{communicationMemberList?.home_id}</h6>
					</div> */}
				</div>
                </nav>
            </div>
            </>
    );
};

export default SideBar;
