import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { customAlert } from "../../utils/alert";
import { HTTPURL } from "../../constant/Matcher";
export const ForgotPassword = createAsyncThunk(
    "auth/forgot_password",
    async (formData, thunkAPI) => {
        try {
            let config = {
                method: 'Post',
                url: `${HTTPURL}api/forgot-password`,
                headers: {
                    "Content-Type": "application/json",
                },
                data:formData
            };
            const res = await axios.request(config);
            if (res?.data?.status===true) {
                customAlert(res?.data?.message,'success');
            } else {
                // customAlert(res?.data?.message);
            }
            return res.data;
        } catch (error) {
            customAlert(error?.response?.data? error?.response?.data?.message : error?.message);
        }
    }
);


export const Reset_Password = createAsyncThunk(
    "auth/reset_password",
    async (formData, thunkAPI) => {
        
        try {
            let config = {
                method: 'Post',
                url: `${HTTPURL}api/reset-password`,
                headers: {
                    "Content-Type": "application/json",
                },
                data:formData
            };
            const res = await axios.request(config);
            if (res?.data?.status===true) {
                customAlert(res?.data?.message,'success');
            } else {
                customAlert(res?.data?.message);
            }
            return res.data;
        } catch (error) {
            customAlert(error?.response?.data? error?.response?.data?.message : error?.message);
        }
    }
);
