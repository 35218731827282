import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getModulePermission,
  Create_role_permission,
} from "../../../../redux/services/AuthSettings";
import { useForm } from "react-hook-form";
import Modal from "react-modal";
import { getAuthSettingDetail } from "../../../../redux/services/AuthSettings";

const getCustomModalStyles = (isSmallScreen) => ({
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    zIndex: 1000,
  },
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    border: "none",
    background: "#fff",
    borderRadius: "8px",
    padding: isSmallScreen ? "10px" : "20px",
    zIndex: 99,
    transition: "all 1s ease-in-out",
    maxWidth: "750px",
    width: isSmallScreen ? "95%" : "90%",
    height: "auto",
    maxHeight: "90vh",
  },
});

const AddRoles = ({ modalIsOpen, setModalIsOpen }) => {
  const [toggle, setToggle] = useState({});
  const [checkedPermissions, setCheckedPermissions] = useState({});
  const [moduleSelectAllState, setModuleSelectAllState] = useState({});
  const [errorMessage, setErrorMessage] = useState("");
  const [showMsg, setShowMsg] = useState(false);
  const [selectAll, setSelectAll] = useState(false);
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 768);
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
  } = useForm();
  const ModulePermissions = useSelector(
    (state) => state?.AuthSettings.ModulePermissions
  );
  const dispatch = useDispatch();

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 768);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    dispatch(getModulePermission());
  }, [dispatch]);

  useEffect(() => {
    if (ModulePermissions && ModulePermissions.length > 0) {
      const initialPermissions = {};
      const initialModuleSelectAll = {};
      
      // Initialize permissions and select all states for each module
      ModulePermissions.forEach((module) => {
        if (module && module.id) {
          initialPermissions[module.id] = {};
          initialModuleSelectAll[module.id] = false;
          
          if (module.permission && Array.isArray(module.permission)) {
            module.permission.forEach((permission) => {
              if (permission && permission.id) {
                initialPermissions[module.id][permission.id] = false;
              }
            });
          }
        }
      });
      
      setCheckedPermissions(initialPermissions);
      setModuleSelectAllState(initialModuleSelectAll);
    }
  }, [ModulePermissions]);

  const toggleTab = (tabId) => {
    setToggle((prevState) => ({
      ...prevState,
      [tabId]: !prevState[tabId],
    }));
  };

  // Helper function to check if all permissions in a module are selected
  const areAllModulePermissionsSelected = (moduleId, permissionsState = checkedPermissions) => {
    if (!moduleId || !permissionsState[moduleId]) return false;
    
    const module = ModulePermissions.find(m => m.id === moduleId);
    if (!module || !module.permission || !Array.isArray(module.permission) || module.permission.length === 0) {
      return false;
    }
    
    return module.permission.every(
      (permission) => permission && permission.id && permissionsState[moduleId][permission.id]
    );
  };

  // Helper function to check if all permissions across all modules are selected
  const areAllPermissionsSelected = (permissionsState = checkedPermissions) => {
    if (!ModulePermissions || !Array.isArray(ModulePermissions) || ModulePermissions.length === 0) {
      return false;
    }
    
    return ModulePermissions.every((module) => 
      module && module.id && areAllModulePermissionsSelected(module.id, permissionsState)
    );
  };

  const handleCheckboxChange = (moduleId, permissionId, isChecked) => {
    if (!moduleId || !permissionId) return;
    
    setShowMsg(false);

    // Create a new permissions state
    const updatedPermissions = {
      ...checkedPermissions,
      [moduleId]: {
        ...(checkedPermissions[moduleId] || {}),
        [permissionId]: isChecked,
      },
    };
    
    // Update the permissions state
    setCheckedPermissions(updatedPermissions);
    
    // Update the module's select all state based on the new permissions
    const isAllModuleSelected = areAllModulePermissionsSelected(moduleId, updatedPermissions);
    setModuleSelectAllState(prevState => ({
      ...prevState,
      [moduleId]: isAllModuleSelected,
    }));
    
    // Update the main select all checkbox based on the new permissions
    const isAllSelected = areAllPermissionsSelected(updatedPermissions);
    setSelectAll(isAllSelected);
  };

  const handleSelectAllChange = (moduleId, e) => {
    if (!moduleId) return;
    
    const isChecked = e.target.checked;
    
    // Update the module's select all state
    setModuleSelectAllState((prevState) => ({
      ...prevState,
      [moduleId]: isChecked,
    }));

    // Find the module and its permissions
    const module = ModulePermissions.find(m => m.id === moduleId);
    if (!module || !module.permission || !Array.isArray(module.permission)) {
      return;
    }

    // Create a new permissions state
    const updatedPermissions = { ...checkedPermissions };
    
    // Initialize module object if it doesn't exist
    if (!updatedPermissions[moduleId]) {
      updatedPermissions[moduleId] = {};
    }
    
    // Update all permissions for this module
    module.permission.forEach((permission) => {
      if (permission && permission.id) {
        updatedPermissions[moduleId][permission.id] = isChecked;
      }
    });
    
    // Update the permissions state
    setCheckedPermissions(updatedPermissions);
    
    // Update the main select all checkbox based on the new permissions
    const isAllSelected = areAllPermissionsSelected(updatedPermissions);
    setSelectAll(isAllSelected);
  };

  const handleSelectAllMainChange = (e) => {
    const isChecked = e.target.checked;
    setShowMsg(false);
    setSelectAll(isChecked);

    if (!ModulePermissions || !Array.isArray(ModulePermissions)) {
      return;
    }

    // Update all module select all states
    const newModuleSelectAllState = {};
    ModulePermissions.forEach((module) => {
      if (module && module.id) {
        newModuleSelectAllState[module.id] = isChecked;
      }
    });
    setModuleSelectAllState(newModuleSelectAllState);

    // Update all permissions
    const newCheckedPermissions = {};
    ModulePermissions.forEach((module) => {
      if (module && module.id) {
        newCheckedPermissions[module.id] = {};
        if (module.permission && Array.isArray(module.permission)) {
          module.permission.forEach((permission) => {
            if (permission && permission.id) {
              newCheckedPermissions[module.id][permission.id] = isChecked;
            }
          });
        }
      }
    });

    setCheckedPermissions(newCheckedPermissions);
  };

  const onSubmit = async (data) => {
    const permissions = [];
    try {
      let isAnyPermissionSelected = false;
      
      if (ModulePermissions && Array.isArray(ModulePermissions)) {
        ModulePermissions.forEach((module) => {
          if (module && module.id && module.permission && Array.isArray(module.permission)) {
            module.permission.forEach((permission) => {
              if (permission && permission.id) {
                const isChecked =
                  checkedPermissions[module.id]?.[permission.id] || false;
                if (isChecked) {
                  isAnyPermissionSelected = true;
                }
                permissions.push({
                  permission_id: permission.id,
                  permission_granted: isChecked,
                });
              }
            });
          }
        });
      }

      if (!isAnyPermissionSelected) {
        setErrorMessage("Please select at least one permission.");
        setShowMsg(true);
        setTimeout(() => {
          setShowMsg(false);
        }, 3000);
        return;
      }
      
      const formData = {
        role_name: data.role_name.trim(),
        role_desciption: data.role_desciption.trim(),
        permission: permissions,
      };
      
      const response = await dispatch(Create_role_permission(formData));
      if (response?.payload?.status) {
        dispatch(getAuthSettingDetail());
        setSelectAll(false);
        setCheckedPermissions({});
        setModuleSelectAllState({});
        setValue("role_name", "");
        setValue("role_desciption", "");
        setModalIsOpen(false);
      }
    } catch (error) {
      console.error("Error during role creation:", error);
    }
  };

  const onClose = () => {
    setModalIsOpen(false);
    setSelectAll(false);
    setCheckedPermissions({});
    setModuleSelectAllState({});
    setErrorMessage("");
    setValue("role_name", "");
    setValue("role_desciption", "");
  };

  const customModalStyles = getCustomModalStyles(isSmallScreen);

  return (
    <Modal
      isOpen={modalIsOpen}
      onRequestClose={() => setModalIsOpen(false)}
      style={customModalStyles}
      contentLabel="Create Role Modal"
      shouldCloseOnOverlayClick={false}
    >
      <div className="modal-dialog modal-lg permision-popup">
        <svg
          className="modal-ripple"
          width="216"
          height="216"
          viewBox="0 0 216 216"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <mask
            id="mask0_9034_17571"
            style={{ maskType: "alpha" }}
            maskUnits="userSpaceOnUse"
            x="-120"
            y="-120"
            width="336"
            height="336"
          >
            <rect
              width="336"
              height="336"
              transform="translate(-120 -120)"
              fill="url(#paint0_radial_9034_17571)"
            />
          </mask>
          <g mask="url(#mask0_9034_17571)">
            <circle cx="48" cy="48" r="47.5" stroke="#EAECF0" />
            <circle cx="48" cy="48" r="47.5" stroke="#EAECF0" />
            <circle cx="48" cy="48" r="71.5" stroke="#EAECF0" />
            <circle cx="48" cy="48" r="95.5" stroke="#EAECF0" />
            <circle cx="48" cy="48" r="119.5" stroke="#EAECF0" />
            <circle cx="48" cy="48" r="143.5" stroke="#EAECF0" />
            <circle cx="48" cy="48" r="167.5" stroke="#EAECF0" />
          </g>
          <defs>
            <radialGradient
              id="paint0_radial_9034_17571"
              cx="0"
              cy="0"
              r="1"
              gradientUnits="userSpaceOnUse"
              gradientTransform="translate(168 168) rotate(90) scale(168 168)"
            >
              <stop />
              <stop offset="1" stopOpacity="0" />
            </radialGradient>
          </defs>
        </svg>
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">
              <svg
                width="52"
                height="52"
                viewBox="0 0 52 52"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g filter="url(#filter0_d_9022_25481)">
                  <path
                    d="M2.5 11C2.5 5.75329 6.75329 1.5 12 1.5H40C45.2467 1.5 49.5 5.75329 49.5 11V39C49.5 44.2467 45.2467 48.5 40 48.5H12C6.75329 48.5 2.5 44.2467 2.5 39V11Z"
                    stroke="#EAECF0"
                    shapeRendering="crispEdges"
                  />
                  <path
                    d="M17 21L29 21M29 21C29 22.6569 30.3431 24 32 24C33.6569 24 35 22.6569 35 21C35 19.3431 33.6569 18 32 18C30.3431 18 29 19.3431 29 21ZM23 29L35 29M23 29C23 30.6569 21.6569 32 20 32C18.3431 32 17 30.6569 17 29C17 27.3431 18.3431 26 20 26C21.6569 26 23 27.3431 23 29Z"
                    stroke="#344054"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </g>
                <defs>
                  <filter
                    id="filter0_d_9022_25481"
                    x="0"
                    y="0"
                    width="52"
                    height="52"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                  >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                      in="SourceAlpha"
                      type="matrix"
                      values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                      result="hardAlpha"
                    />
                    <feOffset dy="1" />
                    <feGaussianBlur stdDeviation="1" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix
                      type="matrix"
                      values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.05 0"
                    />
                    <feBlend
                      mode="normal"
                      in2="BackgroundImageFix"
                      result="effect1_dropShadow_9022_25481"
                    />
                    <feBlend
                      mode="normal"
                      in="SourceGraphic"
                      in2="effect1_dropShadow_9022_25481"
                      result="shape"
                    />
                  </filter>
                </defs>
              </svg>
            </h5>
            <button
              type="button"
              className="btn-close"
              onClick={() => onClose()}
            ></button>
          </div>
          <div className="modal-body role-popup">
            <div className="font-24 hd-title py-3">Create role</div>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="col-md-12 col-12 mb-3">
                <label htmlFor="role_name" className="form-label">
                  Role*
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="role_name"
                  {...register("role_name", { required: true })}
                  maxLength={150}
                />
                {errors.role_name && (
                  <span className="error">Role name is required!</span>
                )}
              </div>
              <div className="col-md-12 col-12 mb-3">
                <label htmlFor="role_description" className="form-label">
                  Description*
                </label>
                <textarea
                  className="form-control"
                  id="role_description"
                  rows="3"
                  {...register("role_desciption", { required: true })}
                  maxLength={250}
                ></textarea>
                {errors.role_desciption && (
                  <span className="error">Role description is required!</span>
                )}
              </div>
              <div className="col-md-12 col-12 mt-3">
                <div className="alert sap-alert-heading permision-head">
                  <div className="font-24 hd-title">Permissions</div>
                </div>
                <div className="form-check selectall mb-4">
                  <div className="form-check form-switch">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="flexSwitchCheckDefault"
                      checked={selectAll}
                      onChange={(e) => handleSelectAllMainChange(e)}
                    />
                  </div>
                  <label className="form-check-label" htmlFor="flexCheckDefault">
                    Select All
                  </label>
                </div>
              </div>
              <div className="accordion accordion-flush sap-accor-form popup">
                {ModulePermissions && Array.isArray(ModulePermissions) &&
                  ModulePermissions.map((module, index) => {
                    if (!module || !module.id || !module.permission) {
                      return null;
                    }
                    
                    const ascendingData = Array.isArray(module.permission) 
                      ? [...module.permission].sort((a, b) => {
                          if (!a?.permission || !b?.permission) return 0;
                          return a.permission.localeCompare(b.permission);
                        })
                      : [];
                      
                    return (
                      <div className="accordion-item" key={module.id || index}>
                        <h2 className="accordion-header">
                          <button
                            className={`accordion-button ${
                              toggle[`tab${index + 1}`] ? "" : "collapsed"
                            }`}
                            onClick={() => toggleTab(`tab${index + 1}`)}
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target={`#flush-collapse-${index + 1}`}
                            aria-expanded={toggle[`tab${index + 1}`]}
                            aria-controls={`flush-collapse-${index + 1}`}
                          >
                            <div className="saf-title-head">
                              <div className="saf-left">
                                <div className="font-20 hd-title">
                                  {module.module_name}
                                </div>
                              </div>
                            </div>
                          </button>
                        </h2>
                        <div
                          id={`flush-collapse-${index + 1}`}
                          className={`accordion-collapse collapse ${
                            toggle[`tab${index + 1}`] ? "show" : ""
                          }`}
                          aria-labelledby={`flush-heading-${index + 1}`}
                          data-bs-parent="#accordionFlushExample"
                        >
                          <div className="accordion-body">
                            <div className="sub-accordian-select mb-4 width-full-accordian">
                              <label
                                className="form-check-label font-weight"
                                htmlFor={`flexSwitchCheckDefault_${module.id}`}
                              >
                                Select All
                              </label>
                              <div className="form-check form-switch">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  id={`flexSwitchCheckDefault_${module.id}`}
                                  checked={moduleSelectAllState[module.id] || false}
                                  onChange={(e) =>
                                    handleSelectAllChange(module.id, e)
                                  }
                                />
                              </div>
                            </div>
                            {ascendingData &&
                              ascendingData.map((permission) => {
                                if (!permission || !permission.id) {
                                  return null;
                                }
                                return (
                                  <div
                                    className="switch-tab-details"
                                    key={permission.id}
                                  >
                                    <div className="switch-tabs">
                                      <div className="font-20 hd-title">
                                        {permission.permission}
                                      </div>
                                      <div className="font-16 hd-subtitle">
                                        {permission.permission_desc}
                                      </div>
                                    </div>
                                    <div className="form-check form-switch">
                                      <input
                                        className="form-check-input"
                                        type="checkbox"
                                        id={`permission_${permission.id}`}
                                        checked={
                                          checkedPermissions[module.id]?.[
                                            permission.id
                                          ] || false
                                        }
                                        onChange={(e) =>
                                          handleCheckboxChange(
                                            module.id,
                                            permission.id,
                                            e.target.checked
                                          )
                                        }
                                      />
                                    </div>
                                  </div>
                                );
                              })}
                          </div>
                        </div>
                      </div>
                    );
                  })}
              </div>
              {showMsg && <div className="error">{errorMessage}</div>}
              <div className="col-md-12 login-btn">
                <button
                  type="button"
                  className="btn-outline-primary btn"
                  onClick={() => onClose()}
                >
                  Cancel
                </button>
                <button type="submit" className="btn btn-primary">
                  Create Role
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default AddRoles;