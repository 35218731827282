import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { customAlert } from "../../utils/alert";
import { HTTPURL } from "../../constant/Matcher";


// create-task
export const Create_task = createAsyncThunk(
    "/api/create-task",
    async (formData, thunkAPI) => {
        const state = thunkAPI.getState();
        const token = state?.auth?.token;
        const refreshToken = localStorage.getItem('refreshToken');
       
        try {
            let config = {
                method: 'Post',
                url: `${HTTPURL}api/create-task `,
                headers: {
                    // "Content-Type": "application/json",
                    'Authorization': `Bearer ${token}`,
                    'refreshToken': `${refreshToken}`,
                },
                data: formData
            };
            const res = await axios.request(config);
            if (res?.data?.status === true) {
                customAlert(res?.data?.message, 'success');
            } else {
                customAlert(res?.data?.message);
            }
            return res.data;
        } catch (error) {
            customAlert(error?.response?.data ? error?.response?.data?.message : error?.message);
        }

    }

);

//get task-board-view
export const get_task_board_view = createAsyncThunk(
    "auth/task-board-view",
    async (SearchData, thunkAPI) => {
        const state = thunkAPI.getState();
        const token = state?.auth?.token;
        const refreshToken = localStorage.getItem('refreshToken');
        try {
            let config = {
                method: 'get',
                url: `${HTTPURL}api/task-board-view?role=${SearchData?.role}&task_status=${SearchData?.task_status}&assigned_to=${SearchData?.assigned_to}&due_date=${SearchData?.due_date}&searchQuery=${SearchData?.searchBar}`,
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'refreshToken': `${refreshToken}`,
                },
            };
            const response = await axios.request(config)
            return response.data;
        } catch (error) {
            if(error?.response?.data?.message !== 'jwt expired'){
                customAlert(error?.response?.data ? error?.response?.data?.message : error?.message);
            }else{
                localStorage.clear()
            }
        }
    }
)

//edit-task
export const edit_task = createAsyncThunk(
    "auth/edit-task",
    async (id, thunkAPI) => {
        const state = thunkAPI.getState();
        const token = state?.auth?.token;
        const refreshToken = localStorage.getItem('refreshToken');
        try {
            let config = {
                method: 'get',
                url: `${HTTPURL}api/edit-task?task_id=${id}`,
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'refreshToken': `${refreshToken}`,
                },
            };
            const response = await axios.request(config)
            return response.data;
        } catch (error) {
            customAlert(error?.response?.data ? error?.response?.data?.message : error?.message);
        }
    }
)
//destroy-task
export const destroy_task = createAsyncThunk(
    "auth/destroy-task",
    async (id, thunkAPI) => {
        const state = thunkAPI.getState();
        const token = state?.auth?.token;
        const refreshToken = localStorage.getItem('refreshToken');
        try {
            let config = {
                method: 'get',
                url: `${HTTPURL}api/destroy-task?task_id=${id}`,
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'refreshToken': `${refreshToken}`,
                },
            };
            const response = await axios.request(config)
            if (response?.data?.status === true) {
                customAlert(response?.data?.message, 'success');
            } else {
                customAlert(response?.data?.message);
            }
            return response.data;
        } catch (error) {
            customAlert(error?.response?.data ? error?.response?.data?.message : error?.message);
        }
    }
);
// task-status-change
export const Task_status_change = createAsyncThunk(
    "/api/task-status-change",
    async (formData, thunkAPI) => {
        const state = thunkAPI.getState();
        const token = state?.auth?.token;
        const refreshToken = localStorage.getItem('refreshToken');
       
        try {
            let config = {
                method: 'Post',
                url: `${HTTPURL}api/task-status-change `,
                headers: {
                    // "Content-Type": "application/json",
                    'Authorization': `Bearer ${token}`,
                    'refreshToken': `${refreshToken}`,
                },
                data: formData
            };
            const res = await axios.request(config);
            if (res?.data?.status === true) {
                customAlert(res?.data?.message, 'success');
            } else {
                customAlert(res?.data?.message);
            }
            return res.data;
        } catch (error) {
            customAlert(error?.response?.data ? error?.response?.data?.message : error?.message);
        }

    }

);
//get  task-table-view
export const get_task_table_view = createAsyncThunk(
    "auth/task-table-view",
    async (SearchData, thunkAPI) => {
        const state = thunkAPI.getState();
        const token = state?.auth?.token;
        const refreshToken = localStorage.getItem('refreshToken');
        try {
            let config = {
                method: 'get',
                url: `${HTTPURL}api/task-table-view?role=${SearchData?.role}&page=${SearchData?.page}&listData=${SearchData?.listData}&task_status=${SearchData?.task_status}&assigned_to=${SearchData?.assigned_to}&due_date=${SearchData?.due_date}&searchQuery=${SearchData?.searchBar}`,
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'refreshToken': `${refreshToken}`,
                },
            };
            const response = await axios.request(config)
            return response.data;
        } catch (error) {
            customAlert(error?.response?.data ? error?.response?.data?.message : error?.message);
        }
    }
);
//get all-admin-home-users
export const get_all_admin_home_users = createAsyncThunk(
    "auth/all-admin-home-users",
    async (role, thunkAPI) => {
        const state = thunkAPI.getState();
        const token = state?.auth?.token;
        const refreshToken = localStorage.getItem('refreshToken');
        try {
            let config = {
                method: 'get',
                url: `${HTTPURL}api/all-admin-home-users`,
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'refreshToken': `${refreshToken}`,
                },
            };
            const response = await axios.request(config)
            return response.data;
        } catch (error) {
            customAlert(error?.response?.data ? error?.response?.data?.message : error?.message);
        }
    }
)