import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { mapTasksToColumns } from "./Common";
import { DownArrowSvg } from "../../../Images/ComonSvgs";
import { monthDayYearFormat } from "../../../components/Common/Common";
import moment from "moment";

const BoardView = ({ onTaskClick, handleMarkAsComplate }) => {
  const BoardViewTasks = useSelector(
    (state) => state?.AuthTasks?.BoardViewTasks
  );
  const general = useSelector(
    (state) => state?.AuthSettings?.settingInfo?.general
  );

  const [columns, setColumns] = useState([]);
  const [visibleTasks, setVisibleTasks] = useState({}); // Track visible tasks per column

  useEffect(() => {
    // Assuming BoardViewTasks is the data you provided
    const mappedColumns = mapTasksToColumns(BoardViewTasks);
    setColumns(mappedColumns);

    // Initialize visible tasks state for each column, starting with 4 tasks visible
    const initialVisibleTasks = {};
    mappedColumns.forEach((column) => {
      initialVisibleTasks[column.id] = 4; // Show first 4 tasks initially
    });
    setVisibleTasks(initialVisibleTasks);
  }, [BoardViewTasks]);

  const handleDragStart = (e, taskId, sourceColumnId) => {
    e.dataTransfer.setData("taskId", taskId);
    e.dataTransfer.setData("sourceColumnId", sourceColumnId);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    const target = e.target;
    if (target.className.includes("droppable")) {
      target.style.backgroundColor = "rgba(0, 0, 0, 0.05)";
    }
  };

  const handleDragLeave = (e) => {
    const target = e.target;
    if (target.className.includes("droppable")) {
      target.style.backgroundColor = "";
    }
  };

  const handleDrop = (e, targetColumnId) => {
    e.preventDefault();
    const target = e.target;
    if (target.className.includes("droppable")) {
      target.style.backgroundColor = "";
    }

    const taskId = e.dataTransfer.getData("taskId");
    const sourceColumnId = e.dataTransfer.getData("sourceColumnId");
    let status = "";

    // Find the task being dragged
    const sourceColumn = columns.find((col) => col.id === sourceColumnId);
    const taskToMove = sourceColumn?.tasks.find((task) => task.id === taskId);

    // If task is "pending", ensure it can only be dropped in the "pending approval" column
    if (taskToMove && taskToMove?.task_status === "pending") {
      // Check if the target column is the "pending approval" column
      const targetColumn = columns?.find((col) => col?.id === targetColumnId);
      if (targetColumn && targetColumn?.title !== "Pending approval") {
        return; // Prevent drop if it's not the "pending approval" column
      }
      status = targetColumn?.title.toLowerCase();
    }

    if (general?.id === taskToMove?.created_by || general?.type === "Owner") {
 
      if (taskToMove && taskToMove?.task_status === "past due") {
        const targetColumn = columns.find((col) => col?.id === targetColumnId);
        if (!targetColumn || targetColumn.title !== "Pending approval") {
          return;
        }
        status = targetColumn.title.toLowerCase();
      }
      // If task is "pending approval", ensure it can only be dropped in the "completed" and "pending" column
      if (taskToMove && taskToMove?.task_status === "pending approval") {
        // Check if the target column is the "completed" and "pending" column
        const targetColumn = columns.find((col) => col?.id === targetColumnId);
        if (targetColumn) {
          const validStatuses = ["Pending", "Completed"];
          if (!validStatuses.includes(targetColumn.title)) {
            return; // Prevent drop if it's not a valid column
          }
          status = targetColumn.title.toLowerCase();
        }
      }
      // If task is "completed"
      if (taskToMove && taskToMove?.task_status === "completed") {
        const targetColumn = columns.find((col) => col.id === targetColumnId);

        if (targetColumn) {
          const validStatuses = ["Past due", "Pending approval"];
          if (validStatuses?.includes(targetColumn.title)) {
            return; // Prevent drop if it's not a valid column
          }
          status = targetColumn.title.toLowerCase();
        }
      }
    }
    if (general?.id !== taskToMove?.created_by) {
      // If task is "pending approval", ensure it can only be dropped in the "completed" and "pending" column
      if (taskToMove && taskToMove.task_status === "pending approval") {
        // Check if the target column is the "completed" and "pending" column
        const targetColumn = columns.find((col) => col?.id === targetColumnId);
        if (targetColumn) {
          const validStatuses = ["Pending"];
          if (!validStatuses.includes(targetColumn?.title)) {
            return; // Prevent drop if it's not a valid column
          }
          status = targetColumn.title.toLowerCase();
        }
      }
      // If task is "completed"
      if (taskToMove && taskToMove?.task_status === "completed") {
        const targetColumn = columns.find((col) => col?.id === targetColumnId);

        if (targetColumn) {
          const validStatuses = ["Pending", "Past due", "Pending approval"];
          if (validStatuses.includes(targetColumn?.title)) {
            return; // Prevent drop if it's not a valid column
          }
          status = targetColumn?.title.toLowerCase();
        }
      }
    }

    // If source and target column are the same, no action needed
    if (sourceColumnId === targetColumnId) return;

    setColumns((prevColumns) => {
      const newColumns = [...prevColumns];
      const sourceColumn = newColumns.find((col) => col?.id === sourceColumnId);
      const targetColumn = newColumns.find((col) => col?.id === targetColumnId);

      if (sourceColumn && targetColumn && taskToMove) {
        // Remove task from source column and add it to the target column
        sourceColumn.tasks = sourceColumn?.tasks?.filter(
          (task) => task?.id !== taskId
        );
        targetColumn.tasks = [...targetColumn.tasks, taskToMove];
      }
      handleMarkAsComplate(taskToMove, status);
      return newColumns;
    });
  };

  // Handle the "See More" button click
  const handleSeeMore = (columnId) => {
    setVisibleTasks((prevState) => {
      const newVisibleCount = prevState[columnId] + 4; // Increase by 4 each time
      return { ...prevState, [columnId]: newVisibleCount };
    });
  };

  return (
    <>
      <section className="tasks-section pt-0">
        <div className="row">
          {columns &&
            columns?.map((column) => (
              <div
                key={column?.id}
                className={`col-md-3 col-sm-6 mt-4 droppable rounded-lg p-4 task_responsive_space`}
                onDragOver={handleDragOver}
                onDragLeave={handleDragLeave}
                onDrop={(e) => handleDrop(e, column?.id)}
              >
                <div className={`task-pending-sec ${column?.color}`}>
                  <div className="task-information">
                    <div className="task-heading">
                      <div className="taskpaddung_flex">
                        <p>{column?.title}</p>{" "}
                        <span> {column?.tasks?.length}</span>
                      </div>
                    </div>
                    {column?.tasks
                      ?.slice(0, visibleTasks[column.id])
                      .map((task) => {
                        return (
                          <div
                            key={task?.id}
                            className="task-content"
                            draggable
                            onDragStart={(e) =>
                              handleDragStart(e, task?.id, column?.id)
                            }
                            onClick={() => onTaskClick(task)}
                          >
                            <h4>{task?.title}</h4>
                            <p className="task_para_space">
                              {task?.description?.length > 50
                                ? task?.description?.substring(0, 50) + "..."
                                : task?.description}
                            </p>
                            <div className="task-footer">
                              {/* <p>Due {monthDayYearFormat(moment(task.due_date).format('YYYY-MM-DD'), true)}</p> */}
                              <p>
                                Due {monthDayYearFormat(task?.due_date, true)}
                              </p>
                              {task?.assignee?.avatar ? (
                                <img src={task?.assignee?.avatar} alt="" />
                              ) : (
                                <span className="name">
                                  {task?.assignee?.name}
                                </span>
                              )}
                            </div>
                          </div>
                        );
                      })}
                    {column?.tasks?.length > visibleTasks[column.id] && (
                      <button
                        className="btn-see-more"
                        onClick={() => handleSeeMore(column.id)}
                      >
                        See more <DownArrowSvg />
                      </button>
                    )}
                  </div>
                </div>
              </div>
            ))}
        </div>
      </section>
    </>
  );
};
export default BoardView;
