import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { HomeIconSvg } from '../../../Images/MarSvg'
import UrlBar from './UrlBar'
import OpenMarTable from './OpenMarTable'
import Calander from './Calender'
import { dateFormateOpenMAR, dayDateFormateOpenMAR } from '../../../components/Common/Common'
import { get_open_mar_details } from '../../../redux/services/AuthMar';

const OpenMar = () => {
    const dispatch = useDispatch();
    const { id } = useParams();
    const OpenMarDetails = useSelector(state => state?.AuthMar?.OpenMarDetails || []);
    const [firstData, setFirstData] = useState({})

    useEffect(() => {
        if (id) {
            dispatch(get_open_mar_details(id));
        }
    }, [dispatch, id]);

    useEffect(() => {
        if (OpenMarDetails.length > 0) {
            setFirstData(OpenMarDetails[0])
        }
    }, [OpenMarDetails])

    // Safely get the member initials with null checks
    const getMemberInitials = () => {
        const firstName = firstData?.member?.first_name || '';
        const lastName = firstData?.member?.last_name || '';
        return firstName.charAt(0) + lastName.charAt(0);
    };

    const memberName = getMemberInitials();

    return (
        <>
            <div id="layoutSidenav_content">
                <main>
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-12 col-lg-12 col-12">
                                <div className="right-main-sec open_mar_sec">
                                    <UrlBar />
                                    <div className="mjohan_cont">
                                        <div className="mavtar_img">
                                            {firstData?.member?.profile_pic ?
                                                <img src={firstData?.member?.profile_pic} alt="Profile" />
                                                :
                                                <div className="name green">
                                                    {memberName}
                                                </div>
                                            }
                                        </div>
                                        <h4>{firstData?.member?.first_name || ''} {firstData?.member?.last_name || ''}</h4>
                                    </div>
                                    <p className='edit_mar_decs'>Primary Diagnosis: {firstData?.member?.diagnosis?.name || 'N/A'} </p>
                                    <p className='edit_mar_decs'>Secondary Diagnosis: {firstData?.member?.secondaryDiagnosis || 'N/A'} </p>
                                    <p className='edit_mar_decs'>Allergies: {firstData?.member?.membersMedicalHistoryAllergie?.[0]?.alergy_history_text || 'N/A'} </p>
                                    <div className="open_record_data">
                                        <div className="home_icon">
                                            <HomeIconSvg />
                                        </div>
                                        <div className="open_record_head">
                                            <h3>Medication Administration Record Data - {firstData?.adminis_start_date ? dateFormateOpenMAR(firstData.adminis_start_date) : 'N/A'}</h3>
                                            <h4>{firstData?.adminis_start_date ? dayDateFormateOpenMAR(firstData.adminis_start_date) : ''}</h4>
                                        </div>
                                    </div>
                                    {OpenMarDetails && OpenMarDetails.length > 0 ? OpenMarDetails.map((item, index) => (
                                        <React.Fragment key={index}>
                                            <div className="main-content-sec vit_info_data medicat_tb_cont">
                                                <OpenMarTable tableData={item} currentMedi={index + 1} TotalMed={OpenMarDetails.length} />
                                            </div>
                                            <Calander calanderData={item} />
                                            {index < OpenMarDetails.length - 1 && <hr/>}
                                        </React.Fragment>
                                    )) : (
                                        <div className="no-data-message">No medication records available</div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </div>
        </>
    )
}

export default OpenMar