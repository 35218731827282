import React, { useState } from "react";

const Header = ({ handleSearchBar, search, setSearch }) => {
  const [showFilter, setShowFilter] = useState(false);

  const handleFilter = () => {
    setShowFilter(!showFilter);
  };
  const handleStatusChange = (e) => {
    const { name, value } = e.target;
    setSearch({
      ...search,
      [name]: value,
    });
  };

  return (
    <>
      <div className="medicat_ser">
        <button
          type="button"
          className="btn btn-light"
          onClick={() => handleFilter()}
        >
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M5 10H15M2.5 5H17.5M7.5 15H12.5"
              stroke="#344054"
              stroke-width="1.66667"
              stroke-linecap="round"
              stroke-linejoin="round"
            ></path>
          </svg>
          More Filters
        </button>
        {!showFilter && (
          <div className="left-side-content searchbar">
            <div className="form-group">
              <input
                type="text"
                name=""
                id=""
                className="form-control"
                placeholder="Search..."
                onChange={(e) => handleSearchBar(e)}
              />
              <svg
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M16.5 16.5L13.5834 13.5833M15.6667 8.58333C15.6667 12.4954 12.4954 15.6667 8.58333 15.6667C4.67132 15.6667 1.5 12.4954 1.5 8.58333C1.5 4.67132 4.67132 1.5 8.58333 1.5C12.4954 1.5 15.6667 4.67132 15.6667 8.58333Z"
                  stroke="#667085"
                  stroke-width="1.66667"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>
              </svg>
            </div>
          </div>
        )}
         {showFilter && (
        <div className="mar_show_filter">
          <div className="flex-input filter_container w-full">
            <div className="w-full  search_ind">
              <div className="left-side-content ">
                <div className="form-group">
                  <label for="" className="form-label">
                    Search
                  </label>
                  <input
                    type="text"
                    className="form-control "
                    placeholder="Search..."
                    onChange={(e) => handleSearchBar(e)}
                  />
                  <svg
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M16.5 16.5L13.5834 13.5833M15.6667 8.58333C15.6667 12.4954 12.4954 15.6667 8.58333 15.6667C4.67132 15.6667 1.5 12.4954 1.5 8.58333C1.5 4.67132 4.67132 1.5 8.58333 1.5C12.4954 1.5 15.6667 4.67132 15.6667 8.58333Z"
                      stroke="#667085"
                      stroke-width="1.66667"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    ></path>
                  </svg>
                </div>
              </div>
            </div>
            <div className="w-full mb-3 search_ind">
              <label for="" className="form-label">
                Approval Status
              </label>
              <select
                className="form-select"
                aria-label="Default select example"
                name="approval_status"
                onChange={(e) => handleStatusChange(e)}
              >
                <option value="">Select</option>
                <option value="Pending">Pending</option>
                <option value="approved">Approve</option>
              </select>
            </div>
          </div>
        </div>
      )}
      </div>
     
    </>
  );
};
export default Header;
