import React from "react";
import { Pagination } from "../../Pagination";
import { monthDayYearFormat } from "../../../components/Common/Common";

const fileFormate = {
  "vnd.openxmlformats-officedocument.wordprocessingml.document": "docx",
  "vnd.openxmlformats-officedocument.spreadsheetml.sheet": "xlsx",
  msword: "doc",
  jpeg: "jpeg",
  jpg: "jpg",
  png: "png",
  pdf: "pdf",
};
const TableView = ({
  documentsList,
  currentPage,
  totalPages,
  handlePageChange,
  onRowClick,
}) => {
  return (
    <div className="main-content-sec doc_info_data medicat_tb_cont">
      <div className="row">
        <div className="col-md-12 col-12">
          <div className="table-sec">
            <div className="table-responsive">
              <table className="table table-hover">
                <thead>
                  <tr>
                    <th scope="col">
                      <div className="table-profile">Name</div>
                    </th>
                    <th scope="col">Type</th>
                    <th scope="col">Category</th>
                    <th scope="col">Uploaded by</th>
                    <th scope="col">Uploaded date</th>
                    <th scope="col">Attached to</th>
                  </tr>
                </thead>
                <tbody>
                  {documentsList &&
                    documentsList.map((item, index) => {
                      let name = item.file_name.split(".");
                      let type = item.file_type.split("/");

                      return (
                        <tr className="hover-row">
                          <td>
                            <div className="table-profile">
                              <div
                                className="appointment-member"
                                onClick={() => onRowClick(item.id)}
                              >
                                {name[0]}
                              </div>
                            </div>
                          </td>
                          <td className="hd-subtitle text-dark text-uppercase">
                            {/* {fileFormate[type[1]]} */}
                            {name[1]}
                          </td>
                          <td className="hd-subtitle text-dark">
                            {item?.module?.module_name}
                          </td>

                          <td className="hd-subtitle text-dark">
                            {item?.createdPlans?.first_name}
                          </td>
                          <td className="approval-status-tbl">
                            {monthDayYearFormat(
                              item.created_date,
                              true
                            ).replaceAll("-", "/")}
                          </td>

                          <td>
                            <div className="table-profile">
                              <div className="profile-sec appointment-member">
                                {item?.documentAttachedTo.map(
                                  (member, index) => {
                                    if (index < 5) {
                                      return (
                                        <div className="tooltipp">
                                          <span
                                            className="tooltipptext"
                                            style={{
                                              color: "white",
                                              background: "black",
                                            }}
                                          >
                                            {member.member.first_name +
                                              " " +
                                              member.member.last_name}
                                          </span>
                                          <div
                                            className="profile-sec curve appointment-member "
                                            key={index}
                                          >
                                            <span className="name">
                                              {member.member?.profile_pic ? (
                                                <div className="Profile_curve2 child-div">
                                                  <img
                                                    src={
                                                      member.member?.profile_pic
                                                    }
                                                    alt={`${member.member.first_name}'s profile`}
                                                  />
                                                </div>
                                              ) : (
                                                <div className="Profile_curve2 child-div">
                                                  <img
                                                    src="/pro.png"
                                                    alt={`${member.member.first_name}'s profile`}
                                                  />
                                                </div>
                                              )}
                                            </span>
                                          </div>
                                        </div>
                                      );
                                    }

                                    return null;
                                  }
                                )}
                              </div>

                              {item?.documentAttachedTo.length > 5 && (
                                <div
                                  className="tooltipp bg-white"
                                  style={{
                                    cursor: "pointer",
                                    position: "relative",
                                    right: "6px",
                                  }}
                                >
                                  <span
                                    className="tooltipptext"
                                    style={{
                                      color: "white",
                                      background: "black",
                                      position: "absolute",
                                      left: "30px",
                                    }}
                                  >
                                    {item?.documentAttachedTo.length > 5 && (
                                      <div className="">
                                        {item?.documentAttachedTo
                                          .slice(
                                            5,
                                            item?.documentAttachedTo.length
                                          )
                                          .map((e) => (
                                            <div
                                              style={{
                                                background: "black",
                                                color: "white",
                                                display: "flex",
                                                flexDirection: "column",
                                              }}
                                            >
                                              {e.member.first_name +
                                                " " +
                                                e.member.last_name}
                                            </div>
                                          ))}
                                      </div>
                                    )}
                                  </span>
                                  <div className="border-white child-div">
                                    {item?.documentAttachedTo.length > 5 && (
                                      <div className="appointment_plus others_docs">
                                        +{item.documentAttachedTo.length - 5}
                                      </div>
                                    )}
                                  </div>
                                </div>
                              )}
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                  <tr>
                    <td colSpan="7">
                      <Pagination
                        currentPage={currentPage}
                        totalPages={totalPages}
                        onPageChange={handlePageChange}
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TableView;
