import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { get_document_by_id } from '../../../redux/services/AuthDocuments'
import { DownloadIconSvg, DeleteIconSvg } from '../../../Images/CommunicationSvg';
import DocViewer, { DocViewerRenderers } from "react-doc-viewer";
import { Document, Page, pdfjs } from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;

const fileFormate = {
    'msword': 'doc',
    'vnd.openxmlformats-officedocument.wordprocessingml.document': 'docx',
    'vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'xlsx',
    'jpeg': 'jpeg',
    'jpg': 'jpg',
    'png': 'png',
    'pdf': 'pdf'
}

const ViewDocuments = ({ id, onModalToggle, handleDelete }) => {
    const dispatch = useDispatch();

    const documentsById = useSelector((state) => state?.AuthDocuments?.DocumentsById);
    const userPermissions = useSelector((state) => state?.common?.userPermissions);

    const [file, setFile] = useState({ fileName: '', url: '', type: '' })
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);


    const onDocumentLoadSuccess = ({ numPages }) => {
        setNumPages(numPages);
    };

    useEffect(() => {
        if (id) {
            dispatch(get_document_by_id(id))
        }
    }, [id])

    useEffect(() => {
        if (documentsById && Object.keys(documentsById).length > 0) {
            let fileType = documentsById.file_type.split('/')
            setFile({ fileName: documentsById?.file_name, url: documentsById?.file_url, type: fileFormate[fileType[1]] })
        }
    }, [documentsById])

    const handleDownload = async () => {
        if (file.url) {
            try {
                const response = await fetch(file.url, { mode: 'cors' });
                if (!response.ok) throw new Error('Network response was not ok');
    
                const blob = await response.blob();
                const link = document.createElement('a');
    
                const contentDisposition = response.headers.get('content-disposition');
                const fileName = contentDisposition
                    ? contentDisposition.split('filename=')[1].replace(/['"]/g, '')
                    : file.fileName || `download.${file.url.split('.').pop()}`;
    
                link.href = URL.createObjectURL(blob);
                link.download = decodeURIComponent(fileName); // Ensure filename decoding
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                URL.revokeObjectURL(link.href);
            } catch (error) {
                console.error('Download failed:', error);
            }
        }
    };


    const docs = [
        { uri: file?.url, fileType: file?.type }
    ];

    let isEdit = (userPermissions && userPermissions.includes('Delete')) || (userPermissions && userPermissions.includes('Download'))
    return (
        <div className="modal fade new_medi_modal show">
            <div className="modal-dialog modal-lg permision-popup">
                <div className="modal-content">
                    <div className='modal-header margin_header'>
                        <h2>Upload Document</h2>
                        <button type="button" className="btn-close" onClick={() => onModalToggle('')}></button>
                    </div>
                    <div className="modal-body medication_modall pdf_div">
                        {documentsById && Object.keys(documentsById).length > 0 &&
                            <>
                                {file.type === 'pdf' &&
                                    <div className="row">
                                        <div className="col-md-1 d-flex align-items-center text-center">
                                            {numPages > 1 &&
                                                <button
                                                    disabled={pageNumber <= 1}
                                                    onClick={() => setPageNumber(pageNumber - 1)}
                                                >
                                                    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M20.3332 11.0001H1.6665M1.6665 11.0001L10.9998 20.3334M1.6665 11.0001L10.9998 1.66675" stroke="#344054" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                    </svg>

                                                </button>
                                            }
                                        </div>
                                        <div className="col-md-10">
                                            <Document
                                                file={file?.url}
                                                onLoadSuccess={onDocumentLoadSuccess}
                                            >
                                                <Page pageNumber={pageNumber} />
                                            </Document>
                                            {numPages > 1 &&
                                                <p className="pages_align">
                                                    Page {pageNumber} of {numPages}
                                                </p>
                                            }
                                        </div>
                                        <div className="col-md-1 d-flex align-items-center text-center">
                                            {numPages > 1 &&
                                                <button
                                                    disabled={pageNumber >= numPages}
                                                    onClick={() => setPageNumber(pageNumber + 1)}
                                                >
                                                    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M1.6665 11.0001H20.3332M20.3332 11.0001L10.9998 1.66675M20.3332 11.0001L10.9998 20.3334" stroke="#344054" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                    </svg>

                                                </button>
                                            }
                                        </div>
                                    </div>
                                }
                                {(file.type === 'jpeg' || file.type === 'jpg' || file.type === 'png') &&
                                    <div className="image_div">
                                        <img src={file?.url} />
                                    </div>
                                }
                                {(file.type === 'docx' || file.type === 'xlsx' || file.type === 'doc') &&
                                    <>
                                        <DocViewer pluginRenderers={DocViewerRenderers} documents={docs} style={{ height: '600px', width: '100%' }} />
                                    </>
                                }
                            </>
                        }
                    </div>
                    {isEdit &&
                        <div className="document_btn d-flex">
                            {userPermissions && userPermissions.includes('Delete') &&
                                <button type="button" className="w-50" onClick={() => handleDelete()}><DeleteIconSvg />Delete</button>
                            }
                            {userPermissions && userPermissions.includes('Download') &&
                                <button type="button" className="w-50" onClick={handleDownload}><DownloadIconSvg />Download</button>
                            }
                        </div>
                    }
                </div>
            </div>
        </div>
    )

}
export default ViewDocuments