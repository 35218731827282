import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { staff_update_checkbox } from "../../../redux/services/AuthMar";
import { PlusSvg } from "../../../Images/MarSvg";

const AddComment = ({ handleCommentToggle, checkBoxId, parentId }) => {
  const dispatch = useDispatch();
  const AllListingData = useSelector(
    (state) => state?.AuthMar?.AllListingData?.marListData
  );
  const [approvalComment, setApprovalComment] = useState("");
  const [error, setError] = useState("");

  const handleChange = (e) => {
    setApprovalComment(e.target.value);
    // Clear error when user starts typing
    if (error) setError("");
  };

  const handleSubmit = async () => {
    const trimmedComment = approvalComment.trim();
    if (!trimmedComment) {
      setError("Comment is required");
      return;
    }

    try {
      let data = {
        mar_detail_td_id: checkBoxId,
        mar_detail_id: parentId,
        administer_status: "administered",
        administer_comment: approvalComment,
      };
      const response = await dispatch(staff_update_checkbox(data));
      if (response?.payload?.status) {
        handleCommentToggle(false);
      }
    } catch (error) {
      console.error("Error during add Comment:", error);
    }
  };

  return (
    <>
      <div className="modal fade new_medi_modal show">
        <div className="modal-dialog modal-lg permision-popup">
          <div className="modal-content ">
            <div className="modal-header" style={{padding:"10px"}}>
              <h5 className="modal-title" id="exampleModalLabel">
                <PlusSvg />
              </h5>
              <button
                type="button"
                className="btn-close"
                onClick={() => handleCommentToggle(false)}
              ></button>
            </div>
            <div className="modal-body medication_modal">
              <form action="" className="row">
                <div className="col-12">
                  <div
                    className="member_vital_basic_title"
                  >
                    <h4>Add Comment</h4>
                  </div>
                  <div
                    className="vital_stepone_para"
                    style={{ marginBottom: "12px" }}
                  >
                    <p>
                      Add a comment for the next shift staff before the
                      medication administration is marked as complete.{" "}
                    </p>
                  </div>
                </div>
                <hr />

                <div className="col-12 mb-3">
                  <label htmlFor="comment" className="form-label">
                    Comment<span>*</span>
                  </label>
                  <input
                    type="text"
                    className={`form-control ${error ? "is-invalid" : ""}`}
                    id="comment"
                    placeholder="Message goes here"
                    value={approvalComment}
                    onChange={handleChange}
                    required
                  />
                  {error && <div className="invalid-feedback">{error}</div>}
                </div>
                <div className="up_vitalm_btn d-flex">
                  <button
                    type="button"
                    className="w-50"
                    onClick={() => handleCommentToggle()}
                  >
                    Cancel
                  </button>
                  <button type="button" className="w-50" onClick={handleSubmit}>
                    Done
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default AddComment;
