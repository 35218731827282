import React, { useEffect, useState } from 'react'
import { get_home_assign_members } from '../../../redux/services/AuthHomes';
import { useDispatch, useSelector } from 'react-redux';
import { Pagination } from '../../Pagination';
import Delete_home_user from './Delete_Home_user';
import Add_new_home_user from './Add_new_home_user';
import DeleteMember from './Delete_multipal_member';
import {NewFormatDate} from '../../DateFormat'
const HomeMembers = ({ id }) => {
    const dispatch = useDispatch();
    const [currentPage, setCurrentPage] = useState(1);
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [checked, setChecked] = useState(false);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [multiDelteModal, setMultiDeleteModal] = useState(false)
    const [delteModal, setDeleteModal] = useState({
        modal: false,
        user_id: '',
    });
    const [search, setSearch] = useState({
        role: '',
        status: '',
        page: 1,
        listData: 10,
        home: '',
        searchBar: '',
        id: id?.id
    });
    useEffect(() => {
        dispatch(get_home_assign_members(search));
    }, [search]);
    const homeAssignMembers = useSelector((state) => state?.AuthHomes?.GetHomeAssignMembers?.homeMembers);

    const assingMemberTotalPage = useSelector(state => state?.AuthHomes?.GetHomeAssignMembers);
    const EditHomeData = useSelector((state) => state?.AuthHomes?.EditHomeData);
    //pagination
    const totalPages = assingMemberTotalPage?.totalNoPages;
    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
        setSearch({
            ...search,
            page: pageNumber
        });
    };
    const checkAll = (e) => {       
        const allUserIds = homeAssignMembers?.map(user => user.member_id);        
        setSelectedUsers(checked ? [] : allUserIds);
        setChecked(!checked);
    };

    const handleSingleCheck = (userId) => {
        const currentIndex = selectedUsers.indexOf(userId);
        const newSelectedUsers = [...selectedUsers];
        if (currentIndex === -1) {
            newSelectedUsers.push(userId);
        } else {
            newSelectedUsers.splice(currentIndex, 1);
        }        
        setSelectedUsers(newSelectedUsers);
    };
    const handleSearch = (e) => {
        setCurrentPage(1)
        setSearch({
            ...search,
            page: 1,
            searchBar: e.target.value,
        });
    }
            
    return (
        <>
            <div className="right-main-header search-sec">
                <div className="left-side-content">
                </div>
                <div className="left-side-content">
                    {
                        selectedUsers.length > 0 ?
                            <button type="button" className="btn btn-danger mx-2" onClick={() => setMultiDeleteModal(true)} >
                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M13.3333 5.00033V4.33366C13.3333 3.40024 13.3333 2.93353 13.1517 2.57701C12.9919 2.2634 12.7369 2.00844 12.4233 1.84865C12.0668 1.66699 11.6001 1.66699 10.6667 1.66699H9.33333C8.39991 1.66699 7.9332 1.66699 7.57668 1.84865C7.26308 2.00844 7.00811 2.2634 6.84832 2.57701C6.66667 2.93353 6.66667 3.40024 6.66667 4.33366V5.00033M8.33333 9.58366V13.7503M11.6667 9.58366V13.7503M2.5 5.00033H17.5M15.8333 5.00033V14.3337C15.8333 15.7338 15.8333 16.4339 15.5608 16.9686C15.3212 17.439 14.9387 17.8215 14.4683 18.0612C13.9335 18.3337 13.2335 18.3337 11.8333 18.3337H8.16667C6.76654 18.3337 6.06647 18.3337 5.53169 18.0612C5.06129 17.8215 4.67883 17.439 4.43915 16.9686C4.16667 16.4339 4.16667 15.7338 4.16667 14.3337V5.00033"
                                        stroke="white" stroke-width="1.66667" stroke-linecap="round"
                                        stroke-linejoin="round" />
                                </svg>
                                Delete Selected
                            </button> : ''
                    }
                    <button type="button" className="btn btn-primary link" onClick={() => setModalIsOpen(true)} disabled={EditHomeData?.is_active !== true ? true : false}>
                        <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M10.5 4.16699V15.8337M4.66663 10.0003H16.3333" stroke="white" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"></path>
                        </svg>
                        Assign New Member
                    </button>
                </div>
            </div>
            <div className="table-btn-sec">
                <div className="form-group searchbar">
                    <input type="text" name="" id="" className="form-control" placeholder="Search name..." onChange={(e) => handleSearch(e)} />
                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M16.5 16.5L13.5834 13.5833M15.6667 8.58333C15.6667 12.4954 12.4954 15.6667 8.58333 15.6667C4.67132 15.6667 1.5 12.4954 1.5 8.58333C1.5 4.67132 4.67132 1.5 8.58333 1.5C12.4954 1.5 15.6667 4.67132 15.6667 8.58333Z" stroke="#667085" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"></path>
                    </svg>
                </div>
                {/* <button type="button" className="btn btn-light">
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M5 10H15M2.5 5H17.5M7.5 15H12.5" stroke="#344054" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"></path>
                    </svg>
                    More Filters
                </button> */}
            </div>
            <div className="row">
                <div className="col-md-12 col-12">
                    <div className="table-sec">
                        <div className="table-responsive">
                            <table className="table table-hover">
                                <thead>
                                    <tr>
                                        <th>
                                            <div className="form-check">
                                                <input className="form-check-input" type="checkbox" checked={checked} id="flexCheckDefault" onChange={(e) => checkAll(e)} />
                                                <label className="form-check-label" for="flexCheckDefault">Name</label>
                                            </div>
                                        </th>
                                        {/* <th scope="col">Name</th> */}
                                        <th scope="col">PCP</th>
                                        <th scope="col">AHSS ID</th>
                                        <th scope="col">Next meeting date</th>
                                        <th scope="col">Action</th>
                                    </tr>
                                </thead>
                                {totalPages == 0 ?
                                        <tr>
                                            <td colspan="7">
                                                <div className='text-center text-capitalize'>No member found</div>
                                            </td>
                                        </tr>
                                        :
                                <tbody>
                                    {homeAssignMembers &&
                                        homeAssignMembers?.map((elem) => {
                                            let imageName = elem?.first_name?.charAt(0)?.toUpperCase() + elem?.last_name?.charAt(0)?.toUpperCase() || '';
                                            let fullName = elem?.first_name?.toUpperCase() + ' ' + elem?.last_name?.toUpperCase();
                                            let pcpName = elem?.p_first_name !== '' ? elem?.p_first_name?.toUpperCase() : '' + ' ' + elem?.p_last_name !== '' ? elem?.p_last_name?.toUpperCase() : '';
                                            
                                            return (
                                                <tr key={elem.id}>
                                                    <td>
                                                        {
                                                            <div className='td-form-check'>
                                                                <div className="form-check">
                                                                    <input
                                                                        className="form-check-input"
                                                                        type="checkbox"
                                                                        checked={selectedUsers.includes(elem.member_id)}
                                                                        onChange={() => handleSingleCheck(elem.member_id)}
                                                                    />
                                                                </div>
                                                                <div className="profile-sec">
                                                                    <span className="name">
                                                                        {elem?.profile_img_url ?
                                                                        <img src={elem?.profile_img_url} /> :
                                                                     
                                                                        imageName
                                                                    }</span>
                                                                    <div
                                                                        className="font-14 hd-title"
                                                                    >
                                                                        {fullName}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        }
                                                    </td>
                                                    <td>{pcpName ? pcpName : 'N/A'}</td>
                                                    <td>{elem?.ahss_id || 'N/A'}</td>
                                                    <td><NewFormatDate inputDate={elem?.date_next_meeting}/></td>
                                                    <td className="pe-auto" >
                                                        {EditHomeData?.is_active &&
                                                        <button className='btn btn-ouline-primary' onClick={() => setDeleteModal({ ...delteModal, modal: true, member_id: elem?.member_id })}>
                                                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M13.3333 5.00033V4.33366C13.3333 3.40024 13.3333 2.93353 13.1517 2.57701C12.9919 2.2634 12.7369 2.00844 12.4233 1.84865C12.0668 1.66699 11.6001 1.66699 10.6667 1.66699H9.33333C8.39991 1.66699 7.9332 1.66699 7.57668 1.84865C7.26308 2.00844 7.00811 2.2634 6.84832 2.57701C6.66667 2.93353 6.66667 3.40024 6.66667 4.33366V5.00033M8.33333 9.58366V13.7503M11.6667 9.58366V13.7503M2.5 5.00033H17.5M15.8333 5.00033V14.3337C15.8333 15.7338 15.8333 16.4339 15.5608 16.9686C15.3212 17.439 14.9387 17.8215 14.4683 18.0612C13.9335 18.3337 13.2335 18.3337 11.8333 18.3337H8.16667C6.76654 18.3337 6.06647 18.3337 5.53169 18.0612C5.06129 17.8215 4.67883 17.439 4.43915 16.9686C4.16667 16.4339 4.16667 15.7338 4.16667 14.3337V5.00033" stroke="black" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round" />
                                                            </svg>
                                                        </button>
                                                        }
                                                        </td>
                                                </tr>
                                            )
                                        })
                                    }
                                    <tr>
                                        <td colspan="7">
                                            <Pagination
                                                currentPage={currentPage}
                                                totalPages={totalPages}
                                                onPageChange={handlePageChange}
                                            />
                                        </td>
                                    </tr>
                                </tbody>
                                }
                            </table>
                        </div>
                    </div>
                </div>
                <Add_new_home_user modalIsOpen={modalIsOpen} setModalIsOpen={setModalIsOpen} id={id?.id} search={search} type='add_new_member' />
                <Delete_home_user delteModal={delteModal} setDeleteModal={setDeleteModal} search={search} id={id?.id} type='delete_member' />
                {/* Delete Member */}
                <DeleteMember setDeleteModal={setMultiDeleteModal} deleteModal={multiDelteModal} selectedUsers={selectedUsers} search={search} setSelectedUsers={setSelectedUsers} setChecked={setChecked}/>
            </div>
        </>
    )
}
export default HomeMembers