import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  Planning_meeting_member,
  Responsible_person_contact_info,
  Meeting_notes,
  Get_ddd_pcsp_data,
  Profile_member,
  Summry_conversation,
  Medical_support_info,
  Behavioral_provider_info,
  Additional_provider,
  Medications,
  Vision_hearing_speech,
  Vision_hearing_speech_equipements,
  Medical_supplies,
  Preventative_screening_services,
  Home_life,
  Daily_life,
  Individualized_goals_comes,
  Activities_daily_living,
  Services_authorized,
  Services_authorized_non_paid,
  Services_authorized_non_altcs,
  Health_medical_risks,
  Risks_assessment,
  Modifications_plan,
  Action_plan_followup,
  Next_meeting_info,
  Informed_concent,
} from "../../../../redux/services/AuthMembers";
import { reSetAllDddPcsp } from "../../../../redux/features/AuthMembersSlice";
import { addSteps } from "../../../../redux/features/AuthMembersSlice";

import PcspHeader from "./PcspHeader";
import PcspSideBar from "./PcspSideBar";
import { AfterAddMember } from "../AfterAddMember";

// Import forms
import ActionPlan from "./Forms/ActionPlan";
import ActivitiesOfDailyLiving from "./Forms/ActivitiesOfDailyLiving";
import MeetingInformation from "./Forms/MeetingInformation";
import MemberProfile from "./Forms/MemberProfile";
import IdentificationOfRisks from "./Forms/IdentificationOfRisks";
import IndividualizedGoals from "./Forms/IndividualizedGoals";
import IndividualSetting from "./Forms/IndividualSetting";
import InformedConsent from "./Forms/InformedConsent";
import Modifications from "./Forms/Modifications";
import NextMeetingInformation from "./Forms/NextMeetingInformation";
import PreferencesAndStrengths from "./Forms/PreferencesAndStrengths";
import RisksAssessment from "./Forms/RisksAssessment";
import ServicesAuthorized from "./Forms/ServicesAuthorized";

const DDDPCSPFORMS = [
  "MeetingInformation",
  "MemberProfile",
  "PreferencesAndStrengths",
  "IndividualSetting",
  "IndividualizedGoals",
  "ActivitiesOfDailyLiving",
  "ServicesAuthorized",
  "IdentificationOfRisks",
  "RisksAssessment",
  "Modifications",
  "ActionPlan",
  "InformedConsent",
  "NextMeetingInformation",
];

const formSubSteps = {
  MeetingInformation: 3,
  MemberProfile: 1,
  PreferencesAndStrengths: 9,
  IndividualSetting: 2,
  IndividualizedGoals: 1,
  ActivitiesOfDailyLiving: 1,
  ServicesAuthorized: 3,
  IdentificationOfRisks: 4,
  RisksAssessment: 1,
  Modifications: 1,
  ActionPlan: 1,
  InformedConsent: 1,
  NextMeetingInformation: 1,
};

const formComponents = {
  MeetingInformation,
  MemberProfile,
  PreferencesAndStrengths,
  IndividualSetting,
  IndividualizedGoals,
  ActivitiesOfDailyLiving,
  ServicesAuthorized,
  IdentificationOfRisks,
  RisksAssessment,
  Modifications,
  ActionPlan,
  InformedConsent,
  NextMeetingInformation,
};

const DddPcsp = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [step, setStep] = useState(0);
  const [subStep, setSubStep] = useState(0);
  const [meetingId, setMeetingId] = useState("");
  const [prefStrengthsId, setPrefStrengthsId] = useState("");
  const [medicalSuppInfoId, setMedicalSuppInfoId] = useState("");
  const [preventiveScreeningInfoId, setPreventiveScreeningInfoId] =
    useState("");
  const [riskPlanModificationId, setRiskPlanModificationId] = useState("");
  const [isGoBack, setIsGoBack] = useState(false);
  const [showPopUp, setShowPopUp] = useState({
    modal: false,
    message: "",
    id: "",
  });
  const totalSteps = DDDPCSPFORMS.length;

  const getSubSteps = (formName) => formSubSteps[formName] || 1;
  const allDddPcsp = useSelector((state) => state?.AuthMember?.allDddPcsp);
  const stepForm = useSelector(
    (state) => state?.AuthMember?.allDddPcsp?.stepForm
  );
  const getDDD_PCSP = () => {
    dispatch(Get_ddd_pcsp_data(id));
  };
  useEffect(() => {
    getDDD_PCSP();
  }, []);

  useEffect(() => {
    const data = {
      step: step,
      subStep: subStep,
      totalSteps: totalSteps,
      totalSubSteps: getSubSteps(DDDPCSPFORMS[step]),
    };
    dispatch(addSteps(data));
  }, [totalSteps, step, subStep]);

  useEffect(() => {
    if (allDddPcsp) {
      setPreventiveScreeningInfoId((prevId) =>
        prevId !== allDddPcsp.preventiveScreeningInfo?.id
          ? allDddPcsp.preventiveScreeningInfo?.id
          : prevId
      );
      setMeetingId((prevId) =>
        prevId !== allDddPcsp?.meetingInfo?.id
          ? allDddPcsp?.meetingInfo?.id
          : prevId
      );
      setPrefStrengthsId((prevId) =>
        prevId !== allDddPcsp?.preferenceStrenth?.id
          ? allDddPcsp?.preferenceStrenth?.id
          : prevId
      );
      setMedicalSuppInfoId((prevId) =>
        prevId !== allDddPcsp?.medicalsuppliesInfo?.id
          ? allDddPcsp?.medicalsuppliesInfo?.id
          : prevId
      );
      setRiskPlanModificationId((prevId) =>
        prevId !== allDddPcsp?.risksPlanModification?.id
          ? allDddPcsp?.risksPlanModification?.id
          : prevId
      );
    }

    if (stepForm && !isGoBack) {
      setStep(() => {
        const storedStep = Number(stepForm?.step_no);
        return storedStep > 0 ? storedStep - 1 : 0;
      });

      setSubStep((prevSubStep) => {
        const storedSubStep = Number(stepForm?.sub_step_no);
        return storedSubStep > 0 ? storedSubStep - 1 : 0;
      });
    }
    if (!stepForm?.step_no && !stepForm?.sub_step_no) {
      setStep(0);
      setSubStep(0);
    }
  }, [allDddPcsp, stepForm]);

  

  const handleNextStep = () => {
    const currentSubSteps = getSubSteps(DDDPCSPFORMS[step]);
    if (subStep < currentSubSteps - 1) {
      setSubStep(subStep + 1);
    } else if (step < totalSteps - 1) {
      setStep(step + 1);
      setSubStep(0);
    }
  };
  const handleBackStep = () => {
    if (subStep > 0) {
      setSubStep(subStep - 1);
    } else if (step > 0) {
      setStep(step - 1);
      setSubStep(getSubSteps(DDDPCSPFORMS[step - 1]) - 1);
    }
    setIsGoBack(true);
    getDDD_PCSP();

  };


  const handleCrossClick = () => {
    navigate(`/auth/edit-member/${id}`);
  };

  const onSubmit = async (data) => {
    let payload = {
      profile_status: 1,
      step_no: step + 1,
      sub_step_no: subStep + 1,
      member_id: id,
      meeting_detail_id: meetingId,
      ...data,
    };

    if (DDDPCSPFORMS[step] === "PreferencesAndStrengths" && subStep === 5) {
      delete payload.vision;
      delete payload.hearing;
      let visionOption = [];
      let hearingOption = [];
      if (data && data.vision) {
        visionOption = data.vision.map((item) => ({
          option_id: item,
        }));
      }
      if (data && data.hearing) {
        hearingOption = data.hearing.map((item) => ({
          option_id: item,
        }));
      }
      payload = { ...payload, visionOption, hearingOption };
    }
    if (DDDPCSPFORMS[step] === "PreferencesAndStrengths" && subStep === 8) {
      let screeningServices = [];
      if (data && data.screeningServices) {
        screeningServices = data.screeningServices.map((item) => ({
          preventive_option_id: item,
        }));
      }
      payload = { ...payload, screeningServices };
    }
    try {
      let response;
      switch (DDDPCSPFORMS[step]) {
        case "MeetingInformation":
          if (subStep === 0) {
            response = await dispatch(Planning_meeting_member(payload));
            if (response?.payload?.status) {
              console.log(
                response?.payload,
                "response?.payloadresponse?.payload"
              );

              setMeetingId(response?.payload?.data?.meeting_detail_id);
              // dispatch(reSetAllDddPcsp())
            }
          } else if (subStep === 1) {
            console.log(meetingId, "meetingIdmeetingIdmeetingId");

            payload.meeting_detail_id = meetingId;
            response = await dispatch(Responsible_person_contact_info(payload));
          } else if (subStep === 2) {
            response = await dispatch(Meeting_notes(payload));
          }
          break;
        case "MemberProfile":
          response = await dispatch(Profile_member(payload));
          if (response?.payload?.status) {
            setMeetingId(response?.payload?.data?.meeting_detail_id);
          }
          break;
        case "PreferencesAndStrengths":
          delete payload.meeting_detail_id;
          payload.pref_strengths_id = prefStrengthsId || "";
          if (subStep === 0) {
            response = await dispatch(Summry_conversation(payload));
            if (response?.payload?.status) {
              setPrefStrengthsId(response?.payload?.data?.pref_strengths_id);
            }
          } else if (subStep === 1) {
            response = await dispatch(Medical_support_info(payload));
            // if (response?.payload?.status) {
            //     dispatch(reSetAllDddPcsp())
            //  }
          } else if (subStep === 2) {
            response = await dispatch(Behavioral_provider_info(payload));
            // if (response?.payload?.status) {
            //    dispatch(reSetAllDddPcsp())
            // }
          } else if (subStep === 3) {
            response = await dispatch(Additional_provider(payload));
            // if (response?.payload?.status) {
            //     dispatch(reSetAllDddPcsp())
            //  }
          } else if (subStep === 4) {
            payload.medication_id = "";
            response = await dispatch(Medications(payload));
            // if (response?.payload?.status) {
            //     dispatch(reSetAllDddPcsp())
            //  }
          } else if (subStep === 5) {
            response = await dispatch(Vision_hearing_speech(payload));
            if (response?.payload?.status) {
            }
          } else if (subStep === 6) {
            payload.medical_supp_info_id = medicalSuppInfoId;
            response = await dispatch(
              Vision_hearing_speech_equipements(payload)
            );
            if (
              response?.payload?.status &&
              response?.payload?.data?.medical_supp_info_id
            ) {
              setMedicalSuppInfoId(
                response?.payload?.data?.medical_supp_info_id
              );
            }
          } else if (subStep === 7) {
            payload.medical_supp_info_id = medicalSuppInfoId;
            payload.height = payload.height.replace(/\//g, '"');
            response = await dispatch(Medical_supplies(payload));
            if (
              response?.payload?.status &&
              response?.payload?.data?.medical_supp_info_id
            ) {
              setMedicalSuppInfoId(
                response?.payload?.data?.medical_supp_info_id
              );
            }
          } else if (subStep === 8) {
            payload.preventive_screening_info_id = preventiveScreeningInfoId;
            response = await dispatch(Preventative_screening_services(payload));
          }

          break;
        case "IndividualSetting":
          delete payload.meeting_detail_id;
          delete payload.pref_strengths_id;
          if (subStep === 0) {
            response = await dispatch(Home_life(payload));
          } else if (subStep === 1) {
            response = await dispatch(Daily_life(payload));
          }
          break;
        case "IndividualizedGoals":
          delete payload.meeting_detail_id;
          delete payload.pref_strengths_id;
          response = await dispatch(Individualized_goals_comes(payload));
          // if (response?.payload?.status) {
          //     dispatch(reSetAllDddPcsp())
          //  }
          break;
        case "ActivitiesOfDailyLiving":
          delete payload.meeting_detail_id;
          delete payload.pref_strengths_id;
          payload.daily_living_id = "";
          response = await dispatch(Activities_daily_living(payload));
          break;
        case "ServicesAuthorized":
          delete payload.meeting_detail_id;
          delete payload.pref_strengths_id;
          if (subStep === 0) {
            response = await dispatch(Services_authorized(payload));
          } else if (subStep === 1) {
            response = await dispatch(Services_authorized_non_paid(payload));
            // if (response?.payload?.status) {
            //     dispatch(reSetAllDddPcsp())
            //  }
          } else if (subStep === 2) {
            response = await dispatch(Services_authorized_non_altcs(payload));
            // if (response?.payload?.status) {
            //     dispatch(reSetAllDddPcsp())
            //  }
          }
          break;
        case "IdentificationOfRisks":
          delete payload.meeting_detail_id;
          delete payload.pref_strengths_id;
          if (subStep === 0) {
            response = await dispatch(Health_medical_risks(payload));
            // if (response?.payload?.status) {
            //     dispatch(reSetAllDddPcsp())
            //  }
          } else if (subStep === 1) {
            response = await dispatch(Health_medical_risks(payload));
            // if (response?.payload?.status) {
            //     dispatch(reSetAllDddPcsp())
            //  }
          } else if (subStep === 2) {
            response = await dispatch(Health_medical_risks(payload));
            // if (response?.payload?.status) {
            //     dispatch(reSetAllDddPcsp())
            //  }
          } else if (subStep === 3) {
            response = await dispatch(Health_medical_risks(payload));
            // if (response?.payload?.status) {
            //     dispatch(reSetAllDddPcsp())
            //  }
          }
          break;
        case "RisksAssessment":
          delete payload.meeting_detail_id;
          delete payload.pref_strengths_id;
          response = await dispatch(Risks_assessment(payload));
          // if (response?.payload?.status) {
          //     dispatch(reSetAllDddPcsp())
          //  }
          break;
        case "Modifications":
          delete payload.meeting_detail_id;
          delete payload.pref_strengths_id;
          payload.risk_plan_modification_id = riskPlanModificationId || "";
          response = await dispatch(Modifications_plan(payload));
          if (response?.payload?.status) {
            setRiskPlanModificationId(
              response?.payload?.data?.risk_plan_modification_id
            );
          }
          break;
        case "ActionPlan":
          delete payload.meeting_detail_id;
          delete payload.pref_strengths_id;
          delete payload.risk_plan_modification_id;
          response = await dispatch(Action_plan_followup(payload));
          // if (response?.payload?.status) {
          //     dispatch(reSetAllDddPcsp())
          //  }
          break;
        case "InformedConsent":
          delete payload.meeting_detail_id;
          delete payload.pref_strengths_id;
          delete payload.risk_plan_modification_id;
          let informedConcepts = payload?.informedConcepts;
          // Create a new FormData object
          let formData = new FormData();
          formData.append("profile_status", payload?.profile_status);
          formData.append("step_no", payload?.step_no);
          formData.append("sub_step_no", payload?.sub_step_no);
          formData.append("member_id", payload?.member_id);

          // Iterate through the `informedConcepts` array
          informedConcepts.forEach((item, index) => {
            // Append each data field with an indexed key
            formData.append(
              `informedConcepts[${index}][agency_relationship_name]`,
              item.agency_relationship_name
            );
            formData.append(`informedConcepts[${index}][date]`, item.date);
            formData.append(
              `informedConcepts[${index}][person_name]`,
              item.person_name
            );
            formData.append(
              `informedConcepts[${index}][signature_image]`,
              item.signature_image
            );
            formData.append(
              `informedConcepts[${index}][signature_image_body]`,
              item.signature_image_body
            );
          });
          response = await dispatch(Informed_concent(formData));
          // if (response?.payload?.status) {
          //     dispatch(reSetAllDddPcsp())
          //  }
          break;
        case "NextMeetingInformation":
          delete payload.meeting_detail_id;
          delete payload.pref_strengths_id;
          delete payload.risk_plan_modification_id;
          payload.profile_status = 0;
          response = await dispatch(Next_meeting_info(payload));
          if (response?.payload?.status) {
            setShowPopUp({
              modal: true,
              message: response?.payload?.message,
              id: id,
            });
          }
          break;
        // Add additional cases for other forms as needed
        default:
          break;
      }
      if (response?.payload?.status) {
        handleNextStep();
      }
    } catch (error) {
      console.log("Error:", error);
    }
  };

  const renderForm = () => {
    const FormComponent = formComponents[DDDPCSPFORMS[step]];
    return FormComponent ? (
      <FormComponent
        step={step}
        subStep={subStep}
        totalSubSteps={getSubSteps(DDDPCSPFORMS[step])}
        onHandleBackStep={handleBackStep}
        onSubmit={onSubmit}
        isGoBack={isGoBack}
      />
    ) : null;
  };

  return (
    <section className="step-home-sec container member_vitalinfo_stepone">
      <PcspHeader
        currentStep={step + 1}
        totalSteps={totalSteps}
        onClickCross={handleCrossClick}
      />
      <div className="pills-steps">
        <PcspSideBar currentStep={step} DDDPCSPFORMS={DDDPCSPFORMS} />
        <div className="tab-content" id="v-pills-tabContent">
          {renderForm()}
        </div>
      </div>
      <AfterAddMember
        setShowPopUp={setShowPopUp}
        showPopUp={showPopUp}
        type="DddPcsp"
      />
    </section>
  );
};

export default DddPcsp;