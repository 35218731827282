import React from "react";
import { useSelector } from "react-redux";

const PcspFooter = ({ onHandleBackStep }) => {
  const { step, subStep, totalSteps, totalSubSteps } =
    useSelector((state) => state?.AuthMember?.steps) || {};

  const isFirstStep = step === 0 && subStep === 0;
  const isLastStep = step === totalSteps - 1 && subStep === totalSubSteps - 1;

  return (
    <div className="col-md-12 login-btn">
      <div className="three-btn-sec">
        <div className="two-btn-sec"></div>
        <div className="two-btn-sec">
          {!isFirstStep && (
            <button
              type="button"
              className="btn btn-light me-md-2"
              onClick={onHandleBackStep}
            >
              <svg
                width="16"
                height="14"
                viewBox="0 0 10 10"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7.917 5H2.083M2.083 5L5 7.917M2.083 5L5 2.083"
                  stroke="black"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              Go Back
            </button>
          )}
          <button type="submit" className="btn btn-primary">
            {isLastStep ? "Submit" : "Next"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default PcspFooter;
