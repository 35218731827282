import React, { useEffect, useState } from "react";
import { useForm, useFieldArray } from "react-hook-form";
import { useSelector } from "react-redux";
import PcspFooter from "../../PcspFooter";

const Medications = ({ onHandleBackStep, onSubmit, isGoBack }) => {
  const [showAllergiesTextarea, setShowAllergiesTextarea] = useState(false);
  const allDddPcsp = useSelector(
    (state) => state?.AuthMember?.allDddPcsp?.medications
  );
  const UploadedPdfData = useSelector(
    (state) => state?.AuthMember?.UploadedPdfData
  );

  const {
    control,
    handleSubmit,
    register,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      medicationList: [
        {
          med_name: "",
          physician_name: "",
          reason_for_taking: "",
          dosage_frequency: "",
          why_non_effective: "",
          why_no_sideeffects: "",
        },
      ],
      hasAllergies: "",
      any_allergies: "",
    },
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "medicationList",
  });

  const hasAllergiesValue = watch("hasAllergies");

  useEffect(() => {
    setShowAllergiesTextarea(hasAllergiesValue === "yes");
    if (hasAllergiesValue === "no") {
      setValue("any_allergies", "");
    }
  }, [hasAllergiesValue, setValue]);
  useEffect(() => {
    if (UploadedPdfData?.length > 0 && !isGoBack) {
      UploadedPdfData.forEach((item) => {
        switch (item.key) {
          case "Are you taking your medications as prescribed? If not, why? What support/assistance would help you to do so?":
            setValue("support_you_need_for_medication", item?.value);
            break;
          case "Where are prescriptions filled?":
            setValue("where_prescription_filled", item.value);
            break;
        }
      });
    } else {
      setValue("hasAllergies", allDddPcsp?.any_allergies ? "yes" : "no");
      setValue("any_allergies", allDddPcsp?.any_allergies || "");
      setValue("has_medication_changed", allDddPcsp?.has_medication_changed);
      setValue(
        "support_you_need_for_medication",
        allDddPcsp?.support_you_need_for_medication
      );
      setValue(
        "where_prescription_filled",
        allDddPcsp?.where_prescription_filled
      );

      const lengthDifference =
        allDddPcsp?.memberdddpcspprefstrengthsmedicationprescribedcurrent
          ?.length - fields.length;
      if (lengthDifference > 0) {
        for (let i = 0; i < lengthDifference; i++) {
          append({});
        }
      }
      allDddPcsp?.memberdddpcspprefstrengthsmedicationprescribedcurrent.forEach(
        (item, index) => {
          setValue(
            `medicationList.${index}.dosage_frequency`,
            item.dosage_frequency || ""
          );
          setValue(`medicationList.${index}.med_name`, item.med_name || "");
          setValue(
            `medicationList.${index}.physician_name`,
            item.physician_name || ""
          );
          setValue(
            `medicationList.${index}.reason_for_taking`,
            item.reason_for_taking || ""
          );
          setValue(
            `medicationList.${index}.why_no_sideeffects`,
            item.why_no_sideeffects || ""
          );
          setValue(
            `medicationList.${index}.why_non_effective`,
            item.why_non_effective || ""
          );
        }
      );
    }
  }, [allDddPcsp, UploadedPdfData]);

  console.log(allDddPcsp);

  return (
    <>
      <div className="member_vitalstep_one_form">
        <form onSubmit={handleSubmit(onSubmit)} className="row">
          <div className="col-12">
            <div className="alert alert-light sap-alert-heading">
              <div className="hd-title font-24">Medications</div>
              <div className="hd-subtitle font-16">
                Review medications for changes
              </div>
            </div>
            <div className="member_vital_basic_title">
              <h4>Review medical supports and information for changes </h4>
            </div>
          </div>
          <div className="col-12 mb-3">
            <label for="" className="form-label">
              Has your medication information changed since the last meeting?*
            </label>
            <select
              className="form-select"
              aria-label="Default select example"
              {...register(`has_medication_changed`, {
                required: "This is required!",
              })}
            >
              <option value="">Select</option>
              <option value="0">No</option>
              <option value="1">Yes</option>
              <option value="2">N/A</option>
            </select>
            {errors.has_medication_changed && (
              <span className="error">
                {errors.has_medication_changed.message}
              </span>
            )}
          </div>

          <div className="col-12 mb-3">
            <label className="form-label">
              Do you have any allergies (medication, food, seasonal)?*
            </label>
            <div className="d-flex gap-4 mb-2">
              <div className="form-check">
                <input
                  type="radio"
                  className="form-check-input"
                  id="allergiesYes"
                  value="yes"
                  {...register("hasAllergies", {
                    required: "Please select yes or no",
                  })}
                />
                <label className="form-check-label" htmlFor="allergiesYes">
                  Yes
                </label>
              </div>
              <div className="form-check">
                <input
                  type="radio"
                  className="form-check-input"
                  id="allergiesNo"
                  value="no"
                  {...register("hasAllergies", {
                    required: "Please select yes or no",
                  })}
                />
                <label className="form-check-label" htmlFor="allergiesNo">
                  No
                </label>
              </div>
            </div>
            {errors.hasAllergies && (
              <span className="error">{errors.hasAllergies.message}</span>
            )}

            {showAllergiesTextarea && (
              <div className="mt-2">
                <textarea
                  className="form-control"
                  placeholder="Describe your allergies"
                  {...register("any_allergies", {
                    required: showAllergiesTextarea
                      ? "Describe your allergies"
                      : false,
                  })}
                  maxLength={150}
                />
                {errors.any_allergies && (
                  <span className="error">{errors.any_allergies.message}</span>
                )}
              </div>
            )}
          </div>

          <div className="member_vital_basic_title">
            <h4>
              List all current prescribed medications (physical/behavioral
              health/over the counter/ vitamins/supplements)
            </h4>
          </div>
          {fields.map((member, index) => (
            <div key={member.id} className="row">
              {index > 0 && <hr />}
              <div className="col-md-6 mb-3">
                <label for="" className="form-label">
                  Name of medication*
                </label>
                <input
                  type="input"
                  className="form-control"
                  placeholder="Enter Name of medication"
                  {...register(`medicationList.${index}.med_name`, {
                    required: "Name of medication is required!",
                  })}
                  maxLength={20}
                />
                {errors.medicationList &&
                  errors.medicationList[index]?.med_name && (
                    <span className="error">
                      {errors.medicationList[index].med_name.message}
                    </span>
                  )}
              </div>
              <div className="col-md-6 mb-3">
                <label for="" className="form-label">
                  Prescribing physician*
                </label>
                <input
                  type="input"
                  className="form-control"
                  placeholder="Enter Prescribing physician"
                  {...register(`medicationList.${index}.physician_name`, {
                    required: "Prescribing physician is required!",
                  })}
                  maxLength={40}
                />
                {errors.medicationList &&
                  errors.medicationList[index]?.physician_name && (
                    <span className="error">
                      {errors.medicationList[index].physician_name.message}
                    </span>
                  )}
              </div>
              <div className="col-12 mb-3">
                <label for="" className="form-label">
                  Why are you taking this medication? (For BH medication include
                  psychoactive drug use type)*
                </label>
                <input
                  type="input"
                  className="form-control"
                  placeholder="Enter medication"
                  {...register(`medicationList.${index}.reason_for_taking`, {
                    required: "This is required!",
                  })}
                  maxLength={20}
                />
                {errors.medicationList &&
                  errors.medicationList[index]?.reason_for_taking && (
                    <span className="error">
                      {errors.medicationList[index].reason_for_taking.message}
                    </span>
                  )}
              </div>
              <div className="col-12 mb-3">
                <label for="" className="form-label">
                  Dosage frequency*
                </label>
                <input
                  type="input"
                  className="form-control"
                  placeholder="Enter Dosage frequency"
                  {...register(`medicationList.${index}.dosage_frequency`, {
                    required: "Dosage frequency is required!",
                  })}
                  maxLength={20}
                />
                {errors.medicationList &&
                  errors.medicationList[index]?.dosage_frequency && (
                    <span className="error">
                      {errors.medicationList[index].dosage_frequency.message}
                    </span>
                  )}
              </div>
              <div className="col-12 mb-3">
                <label for="" className="form-label">
                  Is the medication effective (Y/N) if no, explain*
                </label>
                <textarea
                  className="form-control"
                  placeholder="Enter Is the medication effective"
                  {...register(`medicationList.${index}.why_non_effective`, {
                    required: "true",
                  })}
                  maxLength={150}
                />
                {errors.medicationList &&
                  errors.medicationList[index]?.why_non_effective && (
                    <span className="error">This is required!</span>
                  )}
              </div>
              <div className="col-12 mb-3">
                <label for="" className="form-label">
                  Side effects (Y/N) if yes, explain*
                </label>
                <textarea
                  className="form-control"
                  placeholder="Enter Side effects"
                  {...register(`medicationList.${index}.why_no_sideeffects`, {
                    required: "true",
                  })}
                  maxLength={150}
                />
                {errors.medicationList &&
                  errors.medicationList[index]?.why_no_sideeffects && (
                    <span className="error">This is required!</span>
                  )}
              </div>
              {index > 0 && (
                <div className="col-12 mb-3">
                  <a
                    href="#"
                    className="stepone_remove"
                    onClick={(e) => {
                      e.preventDefault();
                      remove(index);
                    }}
                  >
                    Remove
                  </a>
                </div>
              )}
            </div>
          ))}
          <div className="stepone_health_planbtn">
            <button
              type="button"
              className="font-14"
              onClick={() => append({ id: Date.now() })}
            >
              <svg
                width="12"
                height="12"
                viewBox="0 0 12 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M6.00016 1.33325V10.6666M1.3335 5.99992H10.6668"
                  stroke="#667085"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>
              </svg>
              Add another medication
            </button>
          </div>
          <div className="col-12 mb-3">
            <label for="" className="form-label">
              Where are prescriptions filled?*
            </label>
            <textarea
              className="form-control"
              placeholder="Prescriptions filled"
              {...register("where_prescription_filled", { required: "true" })}
              maxLength={150}
            />
            {errors.where_prescription_filled &&
              errors.where_prescription_filled.type === "required" && (
                <span className="error">prescriptions notes is required!</span>
              )}
          </div>
          <div className="col-12 mb-3">
            <label for="" className="form-label">
              Are you taking your medications as prescribed? If not, why? What
              support/assistance would help you to do so?*
            </label>
            <textarea
              className="form-control"
              placeholder="Enter medications as prescribed"
              {...register("support_you_need_for_medication", {
                required: "true",
              })}
              maxLength={150}
            />
            {errors.support_you_need_for_medication &&
              errors.support_you_need_for_medication.type === "required" && (
                <span className="error">Notes is required!</span>
              )}
          </div>
          <PcspFooter onHandleBackStep={onHandleBackStep} />
        </form>
      </div>
    </>
  );
};
export default Medications;
