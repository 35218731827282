import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Header from './Header'
import ResultNotFound from './ResultNotFound'
import TableView from './TableView'
import { get_all_communication_list, get_loggedIn_home_member_list } from '../../../redux/services/AuthCommunication'
import { setUserPermissions } from '../../../redux/features/commonSlice'
import AddCommunication from './AddCommunication'

const initionalSearch = {
  page: 1,
  listData: 10,
  searchBar: ''
}

const Communication = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate();
  const UserTotalPages = useSelector(state => state?.AuthCommunication?.AllcommunicationList
  );
  const totalPages = UserTotalPages?.totalNoPages;

  const AllcommunicationList = useSelector(state => state?.AuthCommunication?.AllcommunicationList?.communicationList);
  const moduleData = useSelector((state) => state?.common?.moduleData);
  const userPermissions = useSelector((state) => state?.common?.userPermissions);
  const general = useSelector(state => state?.AuthSettings?.settingInfo?.general);

  const [addNewModal, setAddNewModal] = useState(false);
  const [search, setSearch] = useState(initionalSearch);
  const [currentPage, setCurrentPage] = useState(1);


  useEffect(() => {
    if (moduleData.length > 0) {
      let module = moduleData.find((item) => item.module_name === 'Communication')      
      if (module && Object.keys(module).length > 0) {
        const permissionsArray = module.permission.map(item => item.permission);
        dispatch(setUserPermissions(permissionsArray))
      }
    }
  }, [moduleData])

  useEffect(() => {
    if (!addNewModal) {
      dispatch(get_all_communication_list(search))
      dispatch(get_loggedIn_home_member_list())
    }
  }, [search, addNewModal])

  const handleSearchBar = (e) => {
    setSearch({
      ...search,
      page: 1,
      searchBar: e.target.value,
    });
  };

  const handleRowClick = (data) => {
    if (userPermissions && userPermissions.includes('View')) {
      navigate(`/auth/communication-details/${data.home_id}`)
      localStorage.setItem('member_id', data.member_id)
    }
  }

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber)
    setSearch({
      ...search,
      page: pageNumber
    });
  };


  return (
    <div id="layoutSidenav_content">
      <main>
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12 col-lg-12 col-12">
              <Header handleSearchBar={handleSearchBar} search={search} setSearch={setSearch} onModalToggle={setAddNewModal} />
              {AllcommunicationList && AllcommunicationList.length === 0 ?
                <ResultNotFound />
                :
                <TableView communicationList={AllcommunicationList} currentPage={currentPage} totalPages={totalPages} handlePageChange={handlePageChange} search={search} onRowClick={handleRowClick} />
              }
              {addNewModal && <AddCommunication onModalToggle={setAddNewModal} />}
            </div>
          </div>
        </div>
      </main>
    </div>
  )

}
export default Communication