import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import Header from './Header';
import Listview from "./Listview";
import Calenderview from './Calenderview';
import AddNewAppointment from './AddNewAppointment';
import ViewModal from './ViewModal';
import AppointmentStatus from './AppointmentStatus';
import DeleteModal from './DeleteModal';
import ResultNotFound from './ResultNotFound';
import { get_all_appointment_list, appointment_destroy, get_appointment_search_location } from '../../../redux/services/AuthAppointment';
import { reSetEditAppointmentList} from '../../../redux/features/AuthAppointmentSlice'
import { dayMonthYearFormat } from '../../../components/Common/Common';
import { setUserPermissions } from '../../../redux/features/commonSlice';
import moment from 'moment';

const initialSearch = {
  page: 1,
  listData: 10,
  approved_status: '',
  appointment_status: '',
  searchBar: ''
};

const Appointment = ({ FromMember = '' }) => {
  const dispatch = useDispatch();
  const AllAppointmentList = useSelector(state => state?.AuthAppointment?.AllAppointmentList?.appoinments);
  const moduleData = useSelector(state => state.common.moduleData);
  const userPermissions = useSelector(state => state.common.userPermissions);

  const [modalStates, setModalStates] = useState({
    addNewModal: false,
    editModalId: '',
    deleteModalId: '',
    appointmentStatus: {},
    statusType: '',
  });
  
  const [calanderView, setCalanderView] = useState('list');
  const [calanderTabView, setCalanderTabView] = useState(null);
  const [search, setSearch] = useState(initialSearch);
  const [selectionRange, setSelectionRange] = useState({
    startDate: '',
    endDate: '',
    key: "selection",
  });

  useEffect(() => {
    if (moduleData.length > 0) {
      const module = moduleData.find(item => item.module_name === 'Appointments');
      if (module) {
        const permissionsArray = module.permission.map(item => item.permission);
        dispatch(setUserPermissions(permissionsArray));
      }
    }
  }, [moduleData, dispatch]);

  useEffect(() => {
    if (modalStates) {
      const payload = {
        ...search,
        type: calanderTabView !== null ? calanderTabView : null,
        date_range: calanderTabView === null && selectionRange.startDate !== '' ? `${dayMonthYearFormat(selectionRange.startDate, true)}/${dayMonthYearFormat(selectionRange.endDate, true)}` : null,
        member_id: FromMember === '' ? '' : FromMember
      };
      dispatch(get_all_appointment_list(payload));
    }
  }, [search, calanderTabView, selectionRange, modalStates, dispatch]);

  const handlePageChange = pageNumber => {
    setSearch(prev => ({ ...prev, page: pageNumber }));
  };

  const handleSearchBar = e => {
    setSearch(prev => ({ ...prev, page: 1, searchBar: e.target.value }));
  };

  const handleRowClick = id => {
    if (userPermissions?.includes('View')) {
      setModalStates(prev => ({ ...prev, editModalId: id }));
    }
  };

  const handleDeleteToggle = id => {
    setModalStates(prev => ({ ...prev, deleteModalId: id, editModalId: '' }));
  };

  const handleDeleteSubmit = async () => {
    const response = await dispatch(appointment_destroy(modalStates.deleteModalId));
    if (response?.payload?.status) {
      setModalStates(prev => ({ ...prev, deleteModalId: '', editModalId: '' }));
      dispatch(reSetEditAppointmentList())
       const isLastPageEmpty = (search.page !== 1) && (AllAppointmentList.length === 1);
            
       if (isLastPageEmpty) {
           setSearch({
               ...search,
               page: search.page - 1
           });
       }
    }
  };
  
  return (
    <>
      {FromMember === '' && (
        <div id="layoutSidenav">
          <div id="layoutSidenav_content">
            <main>
              <div className="container-fluid">
                <div className="row">
                  <div className="col-12">
                    <div className="right-main-sec appointment_dash_sec">
                      <Header
                        handleSearchBar={handleSearchBar}
                        onCalanderView={setCalanderView}
                        calanderView={calanderView}
                        calanderTabView={calanderTabView}
                        setCalanderTabView={setCalanderTabView}
                        setAddNewModal={addNewModal => setModalStates(prev => ({ ...prev, addNewModal }))}
                        selectionRange={selectionRange}
                        setSelectionRange={setSelectionRange}
                        search={search}
                        setSearch={setSearch}
                      />
                      {console.log(AllAppointmentList)}
                      {AllAppointmentList && AllAppointmentList?.length === 0 ? <ResultNotFound /> : (
                        calanderView === 'list' ? (
                          <Listview handlePageChange={handlePageChange} currentPage={search.page} onRowClick={handleRowClick} />
                        ) : (
                          <Calenderview onRowClick={handleRowClick} selectionRange={selectionRange}/>
                        )
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </main>
          </div>
        </div>
      )}

      {FromMember !== '' &&
      <div className="">
      <Header
        handleSearchBar={handleSearchBar}
        onCalanderView={setCalanderView}
        calanderView={calanderView}
        calanderTabView={calanderTabView}
        setCalanderTabView={setCalanderTabView}
        setAddNewModal={addNewModal => setModalStates(prev => ({ ...prev, addNewModal }))}
        selectionRange={selectionRange}
        setSelectionRange={setSelectionRange}
        search={search}
        setSearch={setSearch}
        FromMember={FromMember}
      />
      {AllAppointmentList && AllAppointmentList?.length === 0 ? <ResultNotFound /> : (
        calanderView === 'list' ? (
          <Listview FromMember={FromMember} handlePageChange={handlePageChange} currentPage={search.page} onRowClick={handleRowClick} />
        ) : (
          <Calenderview FromMember={FromMember} onRowClick={handleRowClick} />
        )
      )}
    </div>
    }
      {modalStates.addNewModal && <AddNewAppointment onModalToggle={addNewModal => setModalStates(prev => ({ ...prev, addNewModal }))} />}
      {modalStates.editModalId && <ViewModal editModalId={modalStates.editModalId} setEditModalId={id => setModalStates(prev => ({ ...prev, editModalId: id }))} onModalToggle={addNewModal => setModalStates(prev => ({ ...prev, addNewModal }))} onDeleteToggal={handleDeleteToggle} onTaggalStatus={(data, type) => setModalStates(prev => ({ ...prev, appointmentStatus: data, statusType: type }))} />}
      {Object.keys(modalStates.appointmentStatus).length > 0 && <AppointmentStatus appointmentStatus={modalStates.appointmentStatus} onTaggalStatus={(data, type, id) => setModalStates(prev => ({ ...prev, appointmentStatus: data, statusType: type , editModalId: id }))} statusType={modalStates.statusType} />}
      {modalStates.deleteModalId && <DeleteModal onDeleteToggal={handleDeleteToggle} onDeleteSubmit={handleDeleteSubmit} />}
    </>
  );
};

export default Appointment;
