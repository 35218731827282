import React, { useEffect, useState } from "react";
import { useForm, useFieldArray } from "react-hook-form";
import { useSelector } from "react-redux";
import PcspFooter from "../../PcspFooter";

const VisionHearingSpeechForm = ({ onHandleBackStep, onSubmit }) => {
  const medicalsuppliesInfo = useSelector(
    (state) => state?.AuthMember?.allDddPcsp?.medicalsuppliesInfo
  );
  
  const visionhearingSpeechEquipments = useSelector(
    (state) => state?.AuthMember?.allDddPcsp?.visionhearingSpeechEquipments
  );
  const {
    control,
    handleSubmit,
    register,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      medicalEquepment: [
        {
          medical_or_adptv_equp: "",
          equp_use: "",
          equp_use_frequency: "",
          equp_provider: "",
        },
      ],
    },
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "medicalEquepment",
  });

  useEffect(() => {
    setValue(
      "change_in_med_supplies",
      medicalsuppliesInfo?.change_in_med_supplies
    );          
    setValue("notes", medicalsuppliesInfo?.notes);
    const lengthDifference =
      visionhearingSpeechEquipments?.length - fields.length;
    if (lengthDifference > 0) {
      for (let i = 0; i < lengthDifference; i++) {
        append({});
      }
    }
    visionhearingSpeechEquipments &&
      visionhearingSpeechEquipments.forEach((item, index) => {
        console.log(item)
        setValue(
          `medicalEquepment.${index}.equp_provider`,
          item.equp_provider || ""
        );
        setValue(`medicalEquepment.${index}.equp_use`, item.equp_use || "");
        setValue(
          `medicalEquepment.${index}.equp_use_frequency`,
          item.equp_use_frequency || ""
        );
        setValue(
          `medicalEquepment.${index}.medical_or_adptv_equp`,
          item.medical_or_adptv_equp || ""
        );
      });
  }, [medicalsuppliesInfo, visionhearingSpeechEquipments]);

  return (
    <>
      <div className="member_vitalstep_one_form">
        <form onSubmit={handleSubmit(onSubmit)} className="row">
          <div className="alert alert-light sap-alert-heading">
            <div className="hd-title font-24">Vision/Hearing/Speech</div>
          </div>
          <div className="member_vital_basic_title">
            <h4>List all medical equipments</h4>
          </div>
          {fields.map((member, index) => (
            <div key={member.id} className="row">
              {index > 0 && <hr />}
              <div className="col-md-6 mb-3">
                <label for="" className="form-label">
                  Medical or adaptive equipment*
                </label>
                <input
                  type="input"
                  className="form-control"
                  placeholder="Enter Medical or adaptive equipment"
                  {...register(
                    `medicalEquepment.${index}.medical_or_adptv_equp`,
                    { required: "Medical or adaptive equipment is required!" }
                  )}
                  maxLength={20}
                />
                {errors.medicalEquepment &&
                  errors.medicalEquepment[index]?.medical_or_adptv_equp && (
                    <span className="error">
                      {
                        errors.medicalEquepment[index].medical_or_adptv_equp
                          .message
                      }
                    </span>
                  )}
              </div>
              <div className="col-md-6 mb-3">
                <label for="" className="form-label">
                  What is the equipment used for*
                </label>
                <input
                  type="input"
                  className="form-control"
                  placeholder="Enter equipment"
                  {...register(`medicalEquepment.${index}.equp_use`, {
                    required: "Equipment is required!",
                  })}
                  maxLength={20}
                />
                {errors.medicalEquepment &&
                  errors.medicalEquepment[index]?.equp_use && (
                    <span className="error">
                      {errors.medicalEquepment[index].equp_use.message}
                    </span>
                  )}
              </div>
              <div className="col-md-6 mb-3">
                <label for="" className="form-label">
                  How often is it used?*
                </label>
                <input
                  type="input"
                  className="form-control"
                  placeholder="Enter How often is it used"
                  {...register(`medicalEquepment.${index}.equp_use_frequency`, {
                    required: "This is required!",
                  })}
                  maxLength={20}
                />
                {errors.medicalEquepment &&
                  errors.medicalEquepment[index]?.equp_use_frequency && (
                    <span className="error">
                      {
                        errors.medicalEquepment[index].equp_use_frequency
                          .message
                      }
                    </span>
                  )}
              </div>
              <div className="col-md-6 mb-3">
                <label for="" className="form-label">
                  Who is providing equipment*
                </label>
                <input
                  type="input"
                  className="form-control"
                  placeholder="Enter Who is providing equipment"
                  {...register(`medicalEquepment.${index}.equp_provider`, {
                    required: "This is required!",
                  })}
                  maxLength={20}
                />
                {errors.medicalEquepment &&
                  errors.medicalEquepment[index]?.equp_provider && (
                    <span className="error">
                      {errors.medicalEquepment[index].equp_provider.message}
                    </span>
                  )}
              </div>
              {index > 0 && (
                <div className="col-12 mb-3">
                  <a
                    href="#"
                    className="stepone_remove"
                    onClick={(e) => {
                      e.preventDefault();
                      remove(index);
                    }}
                  >
                    Remove
                  </a>
                </div>
              )}
            </div>
          ))}

          <div className="stepone_health_planbtn">
            <button
              type="button"
              className="font-14"
              onClick={() => append({ id: Date.now() })}
            >
              <svg
                width="12"
                height="12"
                viewBox="0 0 12 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M6.00016 1.33325V10.6666M1.3335 5.99992H10.6668"
                  stroke="#667085"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>
              </svg>
              Add another health plan
            </button>
          </div>
          <div className="col-12 mb-3">
            <label for="" className="form-label">
              Has there been a change to your medical supplies since the last
              meeting?*
            </label>
            <select
              className="form-select"
              aria-label="Default select example"
              {...register(`change_in_med_supplies`, {
                required: "This is required!",
              })}
            >
              <option value="">Select</option>
              <option value="0">No</option>
              <option value="1">Yes</option>
              <option value="2">N/A</option>
            </select>
            {errors.change_in_med_supplies && (
              <span className="error">
                {errors.change_in_med_supplies.message}
              </span>
            )}
          </div>
          <div className="col-12">
            <label for="" className="form-label">
              Notes*
            </label>
            <div className="ddd_steptwo_para">
              <textarea
                className="form-control"
                placeholder="Enter notes"
                {...register("notes", { required: "true" })}
                maxLength={150}
              />
              {errors.notes && errors.notes.type === "required" && (
                <span className="error">Meeting notes is required!</span>
              )}
            </div>
          </div>
          <PcspFooter onHandleBackStep={onHandleBackStep} />
        </form>
      </div>
    </>
  );
};
export default VisionHearingSpeechForm;
