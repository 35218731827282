import React, { useEffect, useRef, useState } from "react";
import defaultImg from "../../../Images/defaultImg.png";
import { Controller, useForm } from "react-hook-form";
import {
  get_all_roles,
  getCountry,
  getState,
} from "../../../redux/services/common";
import { useDispatch, useSelector } from "react-redux";
import {
  update_user,
  edit_user,
  Activate_user,
} from "../../../redux/services/AuthUser";
import { Link, useNavigate, useParams } from "react-router-dom";
import Select from "react-select";
import DeActivateUser from "./DeActivateUser";
import { Activate_homes } from "../../../redux/services/AuthHomes";
import CountryCode from "../../../components/Common/CountryCode";

const EditUser = () => {
  const {
    register,
    handleSubmit,
    getValues,
    setValue,
    clearErrors,
    watch,
    formState: { errors },
    reset,
    control,
  } = useForm();
  const [showPassword, setShowPassword] = useState(false);
  const [stateError, setStateError] = useState("");
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [showErrorMsg, setShowErrorMsg] = useState({
    phone: "",
    home: false,
  });
  const CountryData = useSelector((state) => state?.common?.CountryData);
  const [countryCode, setCountryCode] = useState("");
  const UserData = useSelector((state) => state?.AuthUsers?.EditUserData);
  const homeslist = useSelector((state) => state?.AuthHomes?.ActivateHomes);
  const ownerInfo = useSelector((state) => state?.owner?.ownerInfo);
  const userPermissions = useSelector((state) => state.common.userPermissions);
  const data = UserData?.user?.homeUser?.map((elem) => elem?.home_table_id);
  const DataCheck = homeslist?.filter((ele) => data?.includes(ele?.id));

  const homeListData = homeslist?.map((item) => ({
    value: item?.id,
    label: item?.home_name,
  }));
  const [selectedSubOptions, setSelectedSubOptions] = useState([]);
  const [newSubOptions, setNewSubOptions] = useState([]);
  const [removedSubOptions, setRemovedSubOptions] = useState([]);

  const handleHomeChange = (selectedSubOptions) => {
    setSelectedSubOptions(selectedSubOptions);
    setNewSubOptions(selectedSubOptions);
  };

  const inputChangeHandler = (e) => {
    const { name, value } = e.target;
    setValue(name, value);

    // Update the countryCode state when the country_code field changes
    if (name === "country_code") {
      setCountryCode(value);
      clearErrors("country_code");
    }
  };

  // Remove a selected option
  const handleRemoveOption = (optionToRemove) => {
    if (optionToRemove && optionToRemove.value) {
      setSelectedSubOptions(
        selectedSubOptions.filter(
          (option) => option.value !== optionToRemove.value
        )
      );
      setNewSubOptions(
        newSubOptions.filter((option) => option.value !== optionToRemove.value)
      );
      const filterd =
        DataCheck && DataCheck.find((item) => item.id === optionToRemove.value);
      if (filterd) {
        setRemovedSubOptions([...removedSubOptions, optionToRemove]);
      }
    }
  };

  const id = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    const data = DataCheck?.map((ele) => ({
      value: ele?.id,
      label: ele?.home_name,
    }));
    setSelectedSubOptions(data);
  }, [UserData]);

  // image
  const [file, setFile] = useState(null);
  const [imageUrl, setImageUrl] = useState("");
  const inputFile = useRef(null);
  const onButtonClick = () => {
    inputFile.current.click();
  };

  //password
  const password = useRef({});
  password.current = watch("password", "");

  useEffect(() => {
    dispatch(edit_user(id?.id));
    dispatch(Activate_homes());
    dispatch(get_all_roles());
    dispatch(getCountry());
  }, []);

  useEffect(() => {
    if (ownerInfo?.organization?.country_id) {
      dispatch(getState(ownerInfo?.organization?.country_id));
    }
  }, [ownerInfo?.organization?.country_id]);

  useEffect(() => {
    setImageUrl(UserData?.user?.profile_img_url);

    // Important: Set the countryCode state from the user data
    setCountryCode(UserData?.user?.country_code || "");

    setValue("first_name", UserData?.user?.first_name);
    setValue("middle_name", UserData?.user?.middle_name);
    setValue("last_name", UserData?.user?.last_name);
    setValue("address1", UserData?.user?.address1);
    setValue("zip_code", UserData?.user?.zip_code);
    setValue("phone", UserData?.user?.phone);
    setValue("email_address", UserData?.user?.email_address);
    setValue("username", UserData?.user?.auth?.username);
    setValue("is_active", UserData?.is_active === true ? "1" : "0");
    setValue("City", UserData?.user?.City);
    setValue("role_id", UserData?.role_id);
    setValue("country_code", UserData?.user?.country_code); // Set the country_code form field

    setTimeout(() => {
      setValue("State_id", UserData?.user?.State_id);
    }, 1000);
  }, [UserData]);

  const handleFileUpload = (e) => {
    const { files } = e.target;
    if (files && files.length) {
      const selectedFile = files[0];
      setFile(selectedFile);
      if (imageUrl) {
        URL.revokeObjectURL(imageUrl);
      }
      const newImageUrl = URL.createObjectURL(selectedFile);
      setImageUrl(newImageUrl);
    }
  };

  const handleDelete = () => {
    setFile(null);
    setImageUrl("");
    if (inputFile.current) {
      inputFile.current.value = null;
    }
  };

  const checkState = (e) => {
    setStateError(e.target.value);
    setValue("City", "");
  };

  const onSubmit = async () => {
    const form = new FormData();
    form.append(
      "profile_img_url",
      file ? file : typeof imageUrl !== "object" ? imageUrl : null
    );
    form.append("address1", getValues("address1"));
    form.append("City", getValues("City"));
    form.append("email_address", getValues("email_address"));
    form.append("first_name", getValues("first_name"));
    form.append("middle_name", getValues("middle_name"));
    form.append("last_name", getValues("last_name"));
    form.append("phone", getValues("phone"));
    form.append("State_id", getValues("State_id"));
    form.append("zip_code", getValues("zip_code"));
    form.append("password", getValues("password"));
    form.append("is_active", getValues("is_active"));
    form.append("role_id", getValues("role_id"));
    form.append("country_code", getValues("country_code")); // Add country_code to form data
    form.append(
      "homes",
      JSON.stringify(selectedSubOptions?.map((option) => option.value))
    );
    form.append(
      "new_homes",
      JSON.stringify(newSubOptions?.map((option) => option.value))
    );
    form.append(
      "remove_homes",
      JSON.stringify(removedSubOptions?.map((option) => option.value))
    );
    form.append("id", id?.id);
    try {
      const response = await dispatch(update_user(form));
      if (response?.payload?.status) {
        navigate("/auth/users");
      } else {
        setShowErrorMsg({ phone: response?.payload?.message?.phone });
      }
    } catch (error) {
      console.error("Error during update home:", error);
    }
  };

  const ActivateUser = async () => {
    const response = await dispatch(Activate_user(id?.id));
    navigate("/auth/users");
  };

  const RoleData = useSelector((state) => state?.common?.RoleData);
  const stateData = useSelector((state) => state?.common?.StateData);

  return (
    <div id="layoutSidenav_content">
      <main>
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12 col-lg-12 col-12">
              <div className="right-main-sec">
                <form
                  onSubmit={handleSubmit(onSubmit)}
                  enctype="multipart/form-data"
                >
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to="/auth/dashboard">
                        <svg
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M6.77168 11.6663C7.14172 13.104 8.4468 14.1663 10 14.1663C11.5532 14.1663 12.8583
                                                     13.104 13.2283 11.6663M9.18141 2.30297L3.52949 6.6989C3.15168 6.99275 2.96278 7.13968 2.82669
                                                      7.32368C2.70614 7.48667 2.61633 7.67029 2.56169 7.86551C2.5 8.0859 2.5 8.32521 2.5 8.80384V14.833C2.5
                                                       15.7664 2.5 16.2331 2.68166 16.5896C2.84144 16.9032 3.09641 17.1582 3.41002 17.318C3.76654 17.4996 4.23325
                                                        17.4996 5.16667 17.4996H14.8333C15.7668 17.4996 16.2335 17.4996 16.59 17.318C16.9036 17.1582 17.1586
                                                         16.9032 17.3183 16.5896C17.5 16.2331 17.5 15.7664 17.5 14.833V8.80384C17.5 8.32521 17.5 8.0859 17.4383
                                                          7.86551C17.3837 7.67029 17.2939 7.48667 17.1733 7.32368C17.0372 7.13968 16.8483 6.99275 16.4705 
                                                          6.69891L10.8186 2.30297C10.5258 2.07526 10.3794 1.9614 10.2178 1.91763C10.0752 1.87902 9.92484 
                                                          1.87902 9.78221 1.91763C9.62057 1.9614 9.47418 2.07526 9.18141 2.30297Z"
                            stroke="#667085"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </Link>
                    </li>
                    <li className="breadcrumb-item">
                      <Link to="/auth/users">Users</Link>
                    </li>
                    <li className="breadcrumb-item active">
                      {UserData?.user?.first_name} {UserData?.user?.last_name}
                    </li>
                  </ol>
                  <div className="right-main-header search-sec">
                    <div className="left-side-content">
                      <div className="font-30 hd-title">
                        {UserData?.user?.first_name} {UserData?.user?.last_name}
                      </div>
                    </div>
                    {UserData?.is_active === false ? (
                      <div className="left-side-content">
                        <button
                          type="button"
                          className="btn btn-outline-success mx-2"
                          onClick={() => ActivateUser()}
                        >
                          Activate user
                        </button>
                      </div>
                    ) : (
                      <>
                        {userPermissions?.includes("Delete") && (
                          <div className="left-side-content">
                            <button
                              type="button"
                              className="btn btn-outline-danger mx-2"
                              onClick={() => setModalIsOpen(true)}
                            >
                              Deactivate user
                            </button>
                          </div>
                        )}
                      </>
                    )}
                  </div>

                  <div className="row">
                    <div className="col-md-12 col-12">
                      <div className="font-18 py-3">Personal information</div>
                      <div className="saf-img-upload">
                        <div className="saf-img-block">
                          <img
                            height="100px"
                            width="100px"
                            src={
                              imageUrl && imageUrl.length > 0
                                ? imageUrl
                                : defaultImg
                            }
                            alt="defaultImg.png"
                          />
                        </div>
                        <input
                          style={{ display: "none" }}
                          ref={inputFile}
                          onChange={handleFileUpload}
                          type="file"
                          accept="image/jpeg, image/png"
                        />
                        <button
                          type="button"
                          className="btn btn-light"
                          onClick={onButtonClick}
                        >
                          Update profile photo
                        </button>
                        {imageUrl ? (
                          <button
                            type="button"
                            className="btn btn-light"
                            onClick={() => handleDelete()}
                          >
                            Delete
                          </button>
                        ) : (
                          ""
                        )}
                      </div>
                      <div className="row">
                        <div className="col-md-4 mb-3">
                          <label htmlFor="" className="form-label">
                            First name*
                          </label>
                          <input
                            type="input"
                            className="form-control"
                            id=""
                            placeholder="First name"
                            {...register("first_name", { required: "true" })}
                          />
                          {errors.first_name &&
                            errors.first_name.type === "required" && (
                              <span className="error">
                                First name is required!
                              </span>
                            )}
                        </div>
                        <div className="col-md-4 mb-3">
                          <label htmlFor="" className="form-label">
                            Middle name
                          </label>
                          <input
                            type="input"
                            className="form-control"
                            id=""
                            placeholder="Middle name"
                            {...register("middle_name")}
                          />
                        </div>
                        <div className="col-md-4 mb-3">
                          <label htmlFor="" className="form-label">
                            Last name*
                          </label>
                          <input
                            type="input"
                            className="form-control"
                            id=""
                            placeholder="Last name"
                            {...register("last_name", { required: "true" })}
                          />
                          {errors.last_name &&
                            errors.last_name.type === "required" && (
                              <span className="error">
                                Last name is required!
                              </span>
                            )}
                        </div>
                        <div className="col-md-6 mb-3">
                          <label htmlFor="" className="form-label">
                            Address
                          </label>
                          <input
                            type="input"
                            className="form-control"
                            id=""
                            placeholder="Address"
                            {...register("address1")}
                          />
                        </div>
                        <div className="col-md-6 mb-3">
                          <label htmlFor="" className="form-label">
                            State
                          </label>
                          <select
                            className="form-select"
                            aria-label="Default select example"
                            name="State_id"
                            {...register("State_id")}
                            onChange={(e) => checkState(e)}
                          >
                            <option value="">Select state</option>
                            {stateData &&
                              stateData?.map((elem) => {
                                return (
                                  <option value={elem?.id} key={elem?.id}>
                                    {elem?.address_state}
                                  </option>
                                );
                              })}
                          </select>
                        </div>
                        <div className="col-md-6 mb-3">
                          <label htmlFor="" className="form-label">
                            City
                          </label>
                          <input
                            type="input"
                            className="form-control"
                            id=""
                            placeholder="City"
                            {...register("City")}
                          />
                        </div>

                        <div className="col-md-6 mb-3">
                          <label htmlFor="" className="form-label">
                            Zip code
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Zip code"
                            onKeyPress={(event) => {
                              // Allow only numbers (0-9) when typing
                              if (!/[0-9]/.test(event.key)) {
                                event.preventDefault();
                              }
                            }}
                            maxLength={5}
                            onPaste={(event) => {
                              // Prevent pasting any non-numeric characters
                              const pastedData =
                                event.clipboardData.getData("text");
                              if (!/^\d{1,5}$/.test(pastedData)) {
                                event.preventDefault();
                              }
                            }}
                            {...register("zip_code", {
                              pattern: {
                                value: /^\d{5}$/,
                                message: "ZIP code must be exactly 5 digits",
                              },
                            })}
                          />
                          {errors.zip_code &&
                            errors.zip_code.type === "pattern" && (
                              <span className="error">
                                {errors?.zip_code?.message}
                              </span>
                            )}
                        </div>
                        <div className="col-md-6 mb-3">
                          <label htmlFor="" className="form-label">
                            Phone number*
                          </label>
                          <div className="flag_main">
                            {/* This is where the country code component is used */}
                            <CountryCode
                              CountryData={CountryData}
                              country={countryCode} // Pass the country code state
                              onChange={inputChangeHandler}
                              name="country_code" // Make sure this matches the field name
                            />
                            <input
                              type="input"
                              className="form-control"
                              id=""
                              placeholder="Phone number"
                              maxLength={15}
                              onKeyPress={(event) => {
                                if (!/[0-9]/.test(event.key)) {
                                  event.preventDefault();
                                }
                              }}
                              {...register("phone", {
                                required: "true",
                                pattern: {
                                  value: /^\d{10,15}$/,
                                  message:
                                    "Phone number must be 10 to 15 digits",
                                },
                              })}
                              onPaste={(event) => {
                                const pastedData =
                                  event.clipboardData.getData("text");
                                if (!/^\d{1,15}$/.test(pastedData)) {
                                  event.preventDefault();
                                }
                              }}
                            />
                          </div>
                          {/* Register the country_code field */}
                          <input type="hidden" {...register("country_code")} />

                          {errors.phone && errors.phone.type === "required" && (
                            <span className="error">
                              Phone number is required
                            </span>
                          )}
                          {errors.phone && errors.phone.type === "pattern" && (
                            <span className="error">
                              {errors?.phone?.message}
                            </span>
                          )}
                          <p className="error">{showErrorMsg.phone}</p>
                        </div>
                        <div className="col-md-6 mb-3">
                          <label htmlFor="" className="form-label">
                            Email Address
                          </label>
                          <input
                            type="email"
                            className="form-control"
                            id=""
                            placeholder="Email Address"
                            {...register("email_address", {
                              pattern: {
                                value:
                                  /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                message: "Invalid email address",
                              },
                            })}
                          />
                          {errors.email_address &&
                            errors.email_address.type === "pattern" && (
                              <span className="error">
                                {errors?.email_address?.message}
                              </span>
                            )}
                        </div>
                        <div className="col-md-12 mb-3">
                          <label htmlFor="" className="form-label">
                            Status*
                          </label>
                          <select
                            className="form-select"
                            aria-label="Default select example"
                            name="is_active"
                            {...register("is_active", { required: "true" })}
                          >
                            <option value="">Select status</option>
                            <option value="1">Active</option>
                            <option value="0">Inactive</option>
                          </select>
                          {errors.is_active &&
                            errors.is_active.type === "required" && (
                              <span className="error">Status is required</span>
                            )}
                        </div>
                      </div>

                      <hr />

                      <div className="font-18 py-3">Security</div>
                      <div className="row">
                        <div className="col-md-12 col-12 mb-3">
                          <label for="" className="form-label">
                            Username*
                          </label>
                          <input
                            type="input"
                            className="form-control"
                            id=""
                            placeholder="Username"
                            {...register("username", { required: "true" })}
                            disabled
                          />
                          {errors.username &&
                            errors.username.type === "required" && (
                              <span className="error">
                                User name is required!
                              </span>
                            )}
                        </div>
                        <div className="col-md-12 col-12 mb-3">
                          <label for="" className="form-label">
                            Temp password*
                          </label>
                          <div className="pass-sec">
                            <input
                              type={showPassword ? "text" : "password"}
                              style={{ minHeight: "44px" }}
                              className="form-control"
                              id=""
                              placeholder="Password"
                              {...register("password")}
                              autoComplete="new-password"
                            />
                            <div onClick={() => setShowPassword(!showPassword)}>
                              {showPassword ? (
                                <svg
                                  width="18"
                                  height="14"
                                  viewBox="0 0 18 14"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M8.99922 1.5C6.96172 1.5 5.28672 2.425 4.00234 3.61562C2.79922 4.73438 1.96797 6.0625 1.54297 7C1.96797 7.9375 2.79922 9.26562 3.99922 10.3844C5.28672 11.575 6.96172 12.5 8.99922 12.5C11.0367 12.5 12.7117 11.575 13.9961 10.3844C15.1992 9.26562 16.0305 7.9375 16.4555 7C16.0305 6.0625 15.1992 4.73438 13.9992 3.61562C12.7117 2.425 11.0367 1.5 8.99922 1.5ZM2.98047 2.51875C4.45234 1.15 6.47422 0 8.99922 0C11.5242 0 13.5461 1.15 15.018 2.51875C16.4805 3.87812 17.4586 5.5 17.9242 6.61562C18.0273 6.8625 18.0273 7.1375 17.9242 7.38437C17.4586 8.5 16.4805 10.125 15.018 11.4812C13.5461 12.85 11.5242 14 8.99922 14C6.47422 14 4.45234 12.85 2.98047 11.4812C1.51797 10.125 0.539844 8.5 0.0773437 7.38437C-0.0257813 7.1375 -0.0257813 6.8625 0.0773437 6.61562C0.539844 5.5 1.51797 3.875 2.98047 2.51875ZM8.99922 9.5C10.3805 9.5 11.4992 8.38125 11.4992 7C11.4992 5.61875 10.3805 4.5 8.99922 4.5C8.97734 4.5 8.95859 4.5 8.93672 4.5C8.97734 4.65938 8.99922 4.82812 8.99922 5C8.99922 6.10313 8.10234 7 6.99922 7C6.82734 7 6.65859 6.97813 6.49922 6.9375C6.49922 6.95937 6.49922 6.97813 6.49922 7C6.49922 8.38125 7.61797 9.5 8.99922 9.5ZM8.99922 3C10.0601 3 11.0775 3.42143 11.8276 4.17157C12.5778 4.92172 12.9992 5.93913 12.9992 7C12.9992 8.06087 12.5778 9.07828 11.8276 9.82843C11.0775 10.5786 10.0601 11 8.99922 11C7.93835 11 6.92094 10.5786 6.17079 9.82843C5.42065 9.07828 4.99922 8.06087 4.99922 7C4.99922 5.93913 5.42065 4.92172 6.17079 4.17157C6.92094 3.42143 7.93835 3 8.99922 3Z"
                                    fill="#888888"
                                  />
                                </svg>
                              ) : (
                                <svg
                                  width="16"
                                  height="16"
                                  viewBox="0 0 16 16"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M8 11C6.35 11 5 9.65 5 8C5 6.35 6.35 5 8 5C9.65 5 11 6.35 11 8C11 9.65 9.65 11 8 11ZM8 6C6.9 6 6 6.9 6 8C6 9.1 6.9 10 8 10C9.1 10 10 9.1 10 8C10 6.9 9.1 6 8 6Z"
                                    fill="black"
                                  />
                                  <path
                                    d="M8 13C4.81 13 2.01 11.06 1.03 8.16C0.989837 8.05711 0.989837 7.94289 1.03 7.84C2.01 4.95 4.82 3 8 3C11.18 3 13.99 4.94 14.97 7.84C15.01 7.94 15.01 8.06 14.97 8.16C13.99 11.05 11.18 13 8 13ZM2.03 8C2.92 10.4 5.3 12 8 12C10.7 12 13.07 10.4 13.97 8C13.08 5.6 10.7 4 8 4C5.3 4 2.93 5.6 2.03 8Z"
                                    fill="black"
                                  />
                                  <path
                                    d="M14 14.5C13.9344 14.5008 13.8694 14.4879 13.8091 14.462C13.7489 14.4362 13.6946 14.398 13.65 14.35L1.65 2.34999C1.45 2.14999 1.45 1.83999 1.65 1.63999C1.85 1.43999 2.16 1.43999 2.36 1.63999L14.35 13.65C14.55 13.85 14.55 14.16 14.35 14.36C14.25 14.46 14.12 14.51 14 14.51V14.5Z"
                                    fill="black"
                                  />
                                </svg>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="col-md-12 col-12 mb-3">
                          <label htmlFor="" className="form-label">
                            Role*
                          </label>
                          <select
                            className="form-select"
                            aria-label="Default select example"
                            name="role_id"
                            {...register("role_id", { required: "true" })}
                          >
                            <option value="">Select role</option>
                            {RoleData &&
                              RoleData?.map((elem) => {
                                return (
                                  <option value={elem?.id}>
                                    {elem?.role_name}
                                  </option>
                                );
                              })}
                          </select>
                          {errors.role_id &&
                            errors.role_id.type === "required" && (
                              <span className="error">Role is required</span>
                            )}
                        </div>
                      </div>
                      <hr />
                      <div className="font-18 py-3">Group home assignment</div>
                      <div className="row">
                        <div className="col-md-12 col-12 mb-3">
                          <label for="" className="form-label">
                            Group Home
                          </label>
                          <Controller
                            name="home"
                            control={control}
                            defaultValue={[]}
                            render={({ field }) => (
                              <Select
                                options={homeListData}
                                className=""
                                placeholder="Select home"
                                isMulti
                                {...field}
                                value={selectedSubOptions}
                                onChange={(otopn) => handleHomeChange(otopn)}
                                closeMenuOnSelect={true}
                                components={{
                                  MultiValue: () => null,
                                  MultiValueLabel: () => null,
                                  MultiValueRemove: () => null,
                                  IndicatorSeparator: () => null,
                                }}
                              />
                            )}
                          />
                          {selectedSubOptions.length > 0 && (
                            <div className="col-md-12 ">
                              <div className="home-user-sec">
                                {selectedSubOptions.map((option) => (
                                  <button
                                    type="button"
                                    className="btn btn-light home-users"
                                    key={option.value}
                                  >
                                    {option.label}
                                    <span
                                      onClick={() => handleRemoveOption(option)}
                                    >
                                      <svg
                                        width="20"
                                        height="20"
                                        viewBox="0 0 20 20"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          d="M15 5L5 15M5 5L15 15"
                                          stroke="#344054"
                                          stroke-width="1.66667"
                                          stroke-linecap="round"
                                          stroke-linejoin="round"
                                        ></path>
                                      </svg>
                                    </span>
                                  </button>
                                ))}
                              </div>
                            </div>
                          )}
                          {showErrorMsg?.home === true && (
                            <span className="error">Home is required</span>
                          )}
                        </div>
                        {/* <div className="filter-option">
                                                    <button type="button" className="btn btn-light">
                                                        Home 001
                                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M15 5L5 15M5 5L15 15" stroke="#344054" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"></path>
                                                        </svg>
                                                    </button>
                                                    <button type="button" className="btn btn-light">
                                                        Home 001
                                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M15 5L5 15M5 5L15 15" stroke="#344054" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"></path>
                                                        </svg>
                                                    </button>
                                                    <button type="button" className="btn btn-light">
                                                        Home 001
                                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M15 5L5 15M5 5L15 15" stroke="#344054" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"></path>
                                                        </svg>
                                                    </button>
                                                    <button type="button" className="btn btn-light">
                                                        Home 001
                                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M15 5L5 15M5 5L15 15" stroke="#344054" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"></path>
                                                        </svg>
                                                    </button>
                                                </div> */}

                        <div className="col-md-12 login-btn">
                          <button type="submit" className="btn btn-primary">
                            Save Changes
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </main>
      {/*DeActivate Modal  */}
      <DeActivateUser
        id={id}
        modalIsOpen={modalIsOpen}
        setModalIsOpen={setModalIsOpen}
      />
    </div>
  );
};

export default EditUser;
