import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import PcspFooter from '../../PcspFooter'

const REVIEWDATE = [
  "Not to exceed 90 days (HCBS)",
  "Not to exceed 180 days",
  "Annual (Acute Care Only)"
]
const NextMeetingInformation = ({onHandleBackStep, onSubmit}) => {
  const allDddPcsp = useSelector(state => state?.AuthMember?.allDddPcsp?.nextMeetingInformation);
  const { register, handleSubmit, setValue, formState: { errors } } = useForm();

  useEffect(() => {
    setValue('date_next_meeting', allDddPcsp?.date_next_meeting);
    setValue('meeting_location_address', allDddPcsp?.meeting_location_address);
    setValue('next_review_date_options', allDddPcsp?.next_review_date_options);
    setValue('time_next_meeting', allDddPcsp?.time_next_meeting);
}, [allDddPcsp])

  return (
    <div className="tab-pane fade  goal_tab show active">
      <form onSubmit={handleSubmit(onSubmit)} className="row">
        <div className="col-12 mb-3">
          <label for="" className="form-label">Next review date*</label>
          <select
              className="form-select"
              aria-label="Default select example"
              {...register(`next_review_date_options`, { required: "This is required!" })}>
              <option value="">Select</option>
              {REVIEWDATE && REVIEWDATE.map((item)=>{
                return (<option value={item}>{item}</option>)
              })}
            </select>
            {errors.next_review_date_options && (
              <span className="error">{errors.next_review_date_options.message}</span>
            )}
        </div>
        <div className="col-12 mb-3">
          <label for="" className="form-label">Date of next meeting*</label>
          <input type="date" className="form-control" id="" placeholder="Exp. Jan 6, 2024" {...register(`date_next_meeting`, { required: "This is required!", })} onKeyDown={(e) => e.preventDefault()} />
            {errors?.date_next_meeting && (
              <span className="error">{errors.date_next_meeting.message}</span>
            )}
        </div>
        <div className="col-12 mb-3">
          <label for="" className="form-label">Time*</label>
          <input type="time" className="form-control" id="" placeholder="Exp. 3:00pm" {...register(`time_next_meeting`, { required: "This is required!", })} onKeyDown={(e) => e.preventDefault()} />
            {errors?.time_next_meeting && (
              <span className="error">{errors.time_next_meeting.message}</span>
            )}
        </div>
        <div className="col-12 mb-3">
          <label for="" className="form-label">Meeting location/address*</label>
          <input
              type="input"
              className="form-control"
              placeholder="Enter Meeting location"
              {...register(`meeting_location_address`, { required: "This is required!" })}
              maxLength={100}
            />
            {errors?.meeting_location_address && (
              <span className="error">{errors.meeting_location_address.message}</span>
            )}
        </div>
        <PcspFooter onHandleBackStep={onHandleBackStep} />
      </form>
    </div>
  )
}

export default NextMeetingInformation