import React, { useEffect, useState, useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { 
    Get_all_behaviour_modifying_medication, 
    Behavior_modifying_medication_consent
} from '../../../../redux/services/AuthMembers';
import { addSteps } from '../../../../redux/features/AuthMembersSlice';

import Header from './Header';
import SideBar from './SideBar';
import { AfterAddMember } from '../AfterAddMember'

// Import forms
import BehaviorModifyingMedicationConsentForm from './Forms/BehaviorModifyingMedicationConsentForm';

const BEHAVIORMODIFYINGFORM = [
    'BehaviorModifyingMedicationConsentForm',
];

const formSubSteps = {
    BehaviorModifyingMedicationConsentForm: 1,
};

const formComponents = {
    BehaviorModifyingMedicationConsentForm,
};

const BehaviorModifyingMedicationConsent = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    
    // Use state with functional updates to prevent unnecessary re-renders
    const [stepState, setStepState] = useState({
        step: 0,
        subStep: 0
    });

    const [showPopUp, setShowPopUp] = useState({
        modal: false,
        message: '',
        id: ''
    });

    const totalSteps = BEHAVIORMODIFYINGFORM.length;

    // Memoized function to get sub-steps
    const getSubSteps = useCallback((formName) => formSubSteps[formName] || 1, []);

    // Fetch initial data
    useEffect(() => {
        dispatch(Get_all_behaviour_modifying_medication(id));
    }, [dispatch, id]);

    // Centralized step update mechanism
    const updateSteps = useCallback((options = {}) => {
        const { 
            step = stepState.step, 
            subStep = stepState.subStep 
        } = options;

        // Only dispatch if there's an actual change
        const stepData = {
            step: step,
            subStep: subStep,
            totalSteps: totalSteps,
            totalSubSteps: getSubSteps(BEHAVIORMODIFYINGFORM[step])
        };

        dispatch(addSteps(stepData));
        
        // Update local state
        setStepState({ step, subStep });
    }, [dispatch, stepState, totalSteps, getSubSteps]);

    // Trigger steps update when component mounts or steps change
    useEffect(() => {
        updateSteps();
    }, [updateSteps]);

    const handleCrossClick = () => {
        navigate(`/auth/edit-member/${id}`);
    };

    const onSubmit = async (data) => {
        const payload = {
            profile_status: 0,
            step_no: stepState.step + 1,
            sub_step_no: stepState.subStep + 1,
            member_id: id,
            ...data,
        };

        try {
            let response;
            switch (BEHAVIORMODIFYINGFORM[stepState.step]) {
                case 'BehaviorModifyingMedicationConsentForm':
                    response = await dispatch(Behavior_modifying_medication_consent(payload));
                    if (response?.payload?.status) {
                        setShowPopUp({ 
                            modal: true, 
                            message: response?.payload?.message, 
                            id: id 
                        });
                        
                        // Optionally update steps after successful submission
                        updateSteps({
                            step: stepState.step + 1,
                            subStep: 0
                        });
                    }
                    break;
                default:
                    break;
            }
        } catch (error) {
            console.error('Submission Error:', error);
        }
    };

    const renderForm = () => {
        const FormComponent = formComponents[BEHAVIORMODIFYINGFORM[stepState.step]];
        return FormComponent ? (
            <FormComponent
                step={stepState.step}
                subStep={stepState.subStep}
                totalSubSteps={getSubSteps(BEHAVIORMODIFYINGFORM[stepState.step])}
                onSubmit={onSubmit}
            />
        ) : null;
    };

    return (
        <section className="step-home-sec container member_vitalinfo_stepone">
            <Header 
                currentStep={stepState.step + 1} 
                totalSteps={totalSteps} 
                onClickCross={handleCrossClick} 
            />
            <div className="pills-steps">
                <SideBar 
                    currentStep={stepState.step} 
                    BEHAVIORMODIFYINGFORM={BEHAVIORMODIFYINGFORM} 
                />
                <div className="tab-content" id="v-pills-tabContent">
                    {renderForm()}
                </div>
            </div>
            <AfterAddMember 
                setShowPopUp={setShowPopUp} 
                showPopUp={showPopUp} 
                type='BehaviorModifyingMedicationConsent'
            />
        </section>
    );
};

export default BehaviorModifyingMedicationConsent;