import { Navigate, Outlet, useLocation } from "react-router-dom";


function checkToken() {
    var now = new Date().getTime();
    var setupTime = parseInt(localStorage.getItem('setupTime'));
    
    if (setupTime !== null) {
        // Checking if the user is logged in or not...
        var eightHoursInMillis = 60 * 60 * 7000; 
        var timeDiff = now - setupTime;

        if (timeDiff > eightHoursInMillis) {
            console.log("Clearing localStorage data...");
            localStorage.clear();
            window.location.href = '/login'; 
        }
    } else {
        console.log("No setupTime found in localStorage.");
        // Handle the case where setupTime is not present in localStorage
    }
}

const AfterLogin = ({ children }) => {
    // const token = useSelector((state) => state?.auth?.token);
    const token = localStorage.getItem('token');
    if (token) {
        return <Navigate to="/" />
    } else {
        return children;
    }
}

const Protected_routes = ({ children }) => {
    checkToken();
    // const token = useSelector((state) => state?.auth?.token);
    const token = localStorage.getItem('token');
    if (!token) {
        return <Navigate to="/login" />
    } else {
        return children;
    }
}

const Required_auth = () => {
    checkToken();
    // const token = useSelector((state) => state?.auth?.token);
    const token = localStorage.getItem('token');
    const location = useLocation();
    if (!token) {
        return <Navigate replace to="/login" state={{ from: location }} />
    }
    if (token) {
        return <Outlet />
    }
    return <p>You are not authorized access this view</p>
}







export { AfterLogin, Protected_routes, Required_auth }