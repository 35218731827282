import React, { useEffect, useState } from "react";
import { useForm, useFieldArray } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import PcspFooter from "../../PcspFooter";
import { get_all_provider_types } from "../../../../../../redux/services/AuthMembers";

const AdditionalProvider = ({ onHandleBackStep, onSubmit }) => {
  const dispatch = useDispatch();
  const allDddPcsp = useSelector(
    (state) => state?.AuthMember?.allDddPcsp?.preferenceStrenth
  );
  const allProviderType = useSelector(
    (state) => state?.AuthMember?.allProviderType
  );

  // Initialize the form with useForm hook
  const {
    control,
    handleSubmit,
    register,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      providerInfo: [
        { p_type: "", p_agency: "", p_name: "", p_contact_info: "" },
      ],
    },
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "providerInfo",
  });

  useEffect(() => {
    dispatch(get_all_provider_types());
  }, []);

  useEffect(() => {
    setValue("is_provider_info_changed", allDddPcsp?.is_provider_info_changed);

    const lengthDifference =
      allDddPcsp?.memberdddpcspprefstrengthsmedicaladditionalprovider?.length -
      fields.length;
    if (lengthDifference > 0) {
      for (let i = 0; i < lengthDifference; i++) {
        append({});
      }
    }
    allDddPcsp?.memberdddpcspprefstrengthsmedicaladditionalprovider.forEach(
      (item, index) => {
        setValue(`providerInfo.${index}.p_agency`, item.p_agency || "");
        setValue(
          `providerInfo.${index}.p_contact_info`,
          item.p_contact_info || ""
        );
        setValue(`providerInfo.${index}.p_name`, item.p_name || "");
        setValue(`providerInfo.${index}.p_type`, item.p_type || "");
      }
    );
  }, [allDddPcsp]);

  return (
    <>
      <div className="member_vitalstep_one_form">
        <form onSubmit={handleSubmit(onSubmit)} className="row">
          <div className="col-12">
            <div className="alert alert-light sap-alert-heading">
              <div className="hd-title font-24">
                Additional provider and support information
              </div>
              <div className="hd-subtitle font-16">
                Review provider and support information for changes
              </div>
            </div>
          </div>
          <div className="col-12 mb-3">
            <label for="" className="form-label">
              Has your provider and support information changed since the last
              meeting?*
            </label>
            <select
              className="form-select"
              aria-label="Default select example"
              {...register(`is_provider_info_changed`, {
                required: "This is required!",
              })}
            >
              <option value="">Select</option>
              <option value="0">No</option>
              <option value="1">Yes</option>
              <option value="2">N/A</option>
            </select>
            {errors.is_provider_info_changed && (
              <span className="error">
                {errors.is_provider_info_changed.message}
              </span>
            )}
          </div>
          <div className="member_vital_basic_title">
            <h4>Prodivder information</h4>
          </div>
          {fields.map((member, index) => (
            <div key={member.id} className="row">
              {index > 0 && <hr />}
              <div className="col-12 mb-3">
                <label for="" className="form-label">
                  Provider type*
                </label>
                <select
                  className="form-select"
                  aria-label="Default select example"
                  {...register(`providerInfo.${index}.p_type`, {
                    required: "This is required!",
                  })}
                >
                  <option value="">Select</option>
                  {allProviderType &&
                    allProviderType.map((item) => {
                      return <option value={item.id}>{item.name}</option>;
                    })}
                </select>
                {errors.providerInfo && errors.providerInfo[index]?.p_type && (
                  <span className="error">
                    {errors.providerInfo[index].p_type.message}
                  </span>
                )}
              </div>
              <div className="col-12 mb-3">
                <label for="" className="form-label">
                  Provider agency*
                </label>
                <input
                  type="input"
                  className="form-control"
                  placeholder="Enter Provider agency"
                  {...register(`providerInfo.${index}.p_agency`, {
                    required: "Provider agency is required!",
                  })}
                  maxLength={20}
                />
                {errors.providerInfo &&
                  errors.providerInfo[index]?.p_agency && (
                    <span className="error">
                      {errors.providerInfo[index].p_agency.message}
                    </span>
                  )}
              </div>
              <div className="col-12 mb-3">
                <label for="" className="form-label">
                  Provider name*
                </label>
                <input
                  type="input"
                  className="form-control"
                  placeholder="Enter Provider name"
                  {...register(`providerInfo.${index}.p_name`, {
                    required: "Provider name is required!",
                  })}
                  maxLength={20}
                />
                {errors.providerInfo && errors.providerInfo[index]?.p_name && (
                  <span className="error">
                    {errors.providerInfo[index].p_name.message}
                  </span>
                )}
              </div>
              <div className="col-12 mb-3">
                <label for="" className="form-label">
                  Contact information*
                </label>
                <input
                  type="input"
                  className="form-control"
                  placeholder="000 0000 000"
                  {...register(`providerInfo.${index}.p_contact_info`, {
                    required: "Contact information is required",
                    pattern: {
                      value: /^\d{10,15}$/,
                      message: "Contact information must be 10 to 15 digits",
                    },
                  })}
                  maxLength={15}
                  onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                />
                {errors.providerInfo &&
                  errors.providerInfo[index]?.p_contact_info && (
                    <span className="error">
                      {errors.providerInfo[index].p_contact_info.message}
                    </span>
                  )}
              </div>
              {index > 0 && (
                <div className="col-12 mb-3">
                  <a
                    href="#"
                    className="stepone_remove"
                    onClick={(e) => {
                      e.preventDefault();
                      remove(index);
                    }}
                  >
                    Remove
                  </a>
                </div>
              )}
            </div>
          ))}
          <div className="stepone_health_planbtn">
            <button
              type="button"
              className="font-14"
              onClick={() => append({ id: Date.now() })}
            >
              <svg
                width="12"
                height="12"
                viewBox="0 0 12 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M6.00016 1.33325V10.6666M1.3335 5.99992H10.6668"
                  stroke="#667085"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>
              </svg>
              Add another provider information
            </button>
          </div>
          <PcspFooter onHandleBackStep={onHandleBackStep} />
        </form>
      </div>
    </>
  );
};
export default AdditionalProvider;
