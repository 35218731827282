import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Deactivate_all_homes, Delete_homes } from '../../../redux/services/AuthHomes';
import { useNavigate } from 'react-router-dom';
import { gat_all_homes } from '../../../redux/services/AuthHomes';
import Modal from 'react-modal';
import { home_users_destroy_list, homes_list_users_not_assign, user_transafer_to_home,homes_list_member_not_assign,home_member_destroy_list,member_transafer_to_home } from '../../../redux/services/AuthHomes';
import Select from "react-select";
import { Controller, useForm } from 'react-hook-form';

const DeActivateHome = ({ id, modalIsOpen, setModalIsOpen, selectedUsers, type, setSelectedUsers, search, setDeleteSingle, deleteSingle }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    Modal.setAppElement('#root');
    const { control } = useForm();
    const [cannotDeactivate, setCannotDeactivate] = useState(false);
    const [showConnections, setShowConnections] = useState(false);
    const [selectedSubOptions, setSelectedSubOptions] = useState([]);
    const [selectedUsersNoHome, setSelectedUsersNoHome] = useState([]);
    const [selectMemberNoHome, setSelectedMemberNoHome] = useState([]);
    const [userNotAssignToHome, setUserNotAssignToHome] = useState([]);
    const [memberNotAssignToHome, setMemberNotAssignToHome] = useState([]);
    const [selectedMember,setSelectedMember]=useState([])
    const [data, setData] = useState([]);
    const [memberData, setMemberData] = useState([]);
    const customModalStyles = {
        overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            zIndex: 1000
        },
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            border: 'none',
            maxWidth: '750px',
            // width: '100%',
            background: '#fff',
            borderRadius: '8px',
            padding: '20px',
            // height: `${showConnections ? '60%' : '34%'}`,
            zIndex: '99',
            transition: 'all 1s ease-in-out'
        }
    };
    useEffect(()=>{
        const data=async()=>{
           const payload={
            home_table_id:deleteSingle?.home_table_id||id?.id,
            count:''
           }
            if(type=='Deactivate_single')
                {
                const response=await dispatch(home_users_destroy_list(payload))
                if (response?.payload?.status == true) {
                    // setModalIsOpen(false);
                    // setSelectedUsers('')
                    dispatch(gat_all_homes(search))
                } else {
                    setData(response?.payload?.data)
                }
                const memberResponse=await dispatch(home_member_destroy_list(payload))
                if (memberResponse?.payload?.status == true) {
                    // setModalIsOpen(false);
                    // setSelectedUsers('')
                    dispatch(gat_all_homes(search))
                } else {
                    setMemberData(memberResponse?.payload?.data)
                }
            }
        }
        data();
    },[type])
    const onSubmit = async () => {
        if (id && type !== 'Deactivate_single') {
            dispatch(Delete_homes(id?.id));
            setModalIsOpen(false);
            dispatch(gat_all_homes(search))
            navigate('/auth/homes');
        } else if (type == 'Deactivate_All') {
            const payload = {
                selectedHomes: selectedUsers
            };
            await dispatch(Deactivate_all_homes(payload));
            setModalIsOpen(false);
            setSelectedUsers('')
            dispatch(gat_all_homes(search))
            //  navigate('/auth/homes');
        }
        else if (type == 'Deactivate_single') {
            const payload={
                home_table_id:deleteSingle?.home_table_id||id?.id,
                count:data?.length===0?0:''
               }
            const response = await dispatch(home_users_destroy_list(payload));
            if (response?.payload?.status == true) {
                setModalIsOpen(false);
                setSelectedUsers('')
                dispatch(gat_all_homes(search))
            } else {
                setData(response?.payload?.data)
            }
            const memberResponse=await dispatch(home_member_destroy_list(payload))
            if (memberResponse?.payload?.status == true) {
                // setModalIsOpen(false);
                // setSelectedUsers('')
                dispatch(gat_all_homes(search))
            } else {
                setMemberData(memberResponse?.payload?.data)
            }
            //  navigate('/auth/homes');
        }
        else {
            setModalIsOpen(false);
        }
    }
    //multi select user
    const userListData = data?.map((item) => ({
        id: item?.id,
        value: item?.user?.id,
        label: (
            <span>{`${item?.user?.first_name} ${item?.user?.last_name}`}</span>
        )
    }));

    
    const memberListData = memberData?.map((item) => ({
        id: item?.member?.id,
        value: item?.member?.id,
        label: (
            <span>{`${item?.member?.first_name} ${item?.member?.last_name}`}</span>
        )
    }));
    const userListWithNoHomeAssign = userNotAssignToHome?.map((item) => ({
        value: item?.id,
        label: (
            <span>{`${item?.home_name}`}</span>
        )
    }));
    const homeListNotassignMember = memberNotAssignToHome?.map((item) => ({
        value: item?.id,
        label: (
            <span>{`${item?.home_name}`}</span>
        )
    }));
    const handleUserChange = async (selectedSubOptions) => {
        setSelectedSubOptions(selectedSubOptions);
        setSelectedUsersNoHome([])
        const payload = {
            user_id: selectedSubOptions?.value,
            id: deleteSingle?.home_table_id
        }
        const response = await dispatch(homes_list_users_not_assign(payload));
        if (response?.payload?.status == true) {
            setUserNotAssignToHome(response?.payload?.data)
        }
    };
    const handleUserWithNoHome = (selectedSubOptions) => {
        setSelectedUsersNoHome(selectedSubOptions);
    };
    const handleMemberWithNoHome = (selectedSubOptions) => {
        setSelectedMemberNoHome(selectedSubOptions);
    };
    const handleMemberChange =async (selectedSubOptions) => {
        setSelectedMember(selectedSubOptions);
        setSelectedMemberNoHome([])
        const payload = {
            member_id: selectedSubOptions?.value,
            id: deleteSingle?.home_table_id
        }
        const response = await dispatch(homes_list_member_not_assign(payload));
        if (response?.payload?.status == true) {
            setMemberNotAssignToHome(response?.payload?.data)
        }
    };
    const OnClose = () => {
        setModalIsOpen(false);
        if (type == 'Deactivate_single') {
            setCannotDeactivate(false)
            setDeleteSingle({ modal: false, staff: '', home_table_id: '' });
            setShowConnections(false);
            setSelectedSubOptions([]);
            setUserNotAssignToHome([]);
            setSelectedUsersNoHome([]);
            setSelectedMemberNoHome([]);
            setMemberNotAssignToHome([]);
            setSelectedMember([])
        }
    }
    const TransferHomeToUser = async () => {
        const payload = {
            home_table_id: selectedUsersNoHome?.value,
            user_id: selectedSubOptions?.value,
            from_home_id:deleteSingle?.home_table_id
        }
        const response =await dispatch(user_transafer_to_home(payload))
        if (response?.payload?.status == true) {
            dispatch(gat_all_homes(search))
            setSelectedSubOptions([]);
            setSelectedUsersNoHome([]);
            setUserNotAssignToHome([]);
            const payload={
                home_table_id:deleteSingle?.home_table_id,
                count:''
               }
         const get_homes=  await dispatch(home_users_destroy_list(payload))
           if (get_homes?.payload?.status == true) {
            setSelectedUsers('');
            setCannotDeactivate(false);
        } else {
            setData(get_homes?.payload?.data || [])
        }
        }
    }
    const MemerTransferToHome = async () => {
        const payload = {
            home_table_id: selectMemberNoHome?.value,
            member_id: selectedMember?.value,
            from_home_id:deleteSingle?.home_table_id
        }
        const response =await dispatch(member_transafer_to_home(payload))
        if (response?.payload?.status == true) {
            dispatch(gat_all_homes(search))
            setSelectedMember([]);
            setSelectedMemberNoHome([]);
            setMemberNotAssignToHome([]);
            const payload={
                home_table_id:deleteSingle?.home_table_id,
                count:''
               }
         const get_homes=  await dispatch(home_member_destroy_list(payload))
           if (get_homes?.payload?.status == true) {
            setSelectedUsers('');
            setCannotDeactivate(false);
        } else {
            setData(get_homes?.payload?.data || [])
        }
        }
    }
    return (
        <Modal
            isOpen={modalIsOpen}
            onRequestClose={() => setModalIsOpen(false)}
            style={customModalStyles}
            shouldCloseOnOverlayClick={false}
            contentLabel="Create Role Modal"
        >
            <div className="modal-dialog modal-lg communication_edit_modal">
                <div className="modal-content">
                    <div className="modal-header close-deactivate">
                        <button type="button" className="btn-close" onClick={() => OnClose()}></button>
                    </div>
                    <div className="modal-body">
                        {
                            cannotDeactivate ?
                                <>
                                    <div className="font-18 hd-title">Cannot delete Home</div>
                                    <div className="hd-subtitle font-14">This home is currently assigned to a user(s) and/or member(s) and cannot be Deleted until all attached members/users are re-assigned to another home ?</div>
                                </>
                                :
                                <>
                                    <div className="font-18 hd-title">{type=="Deactivate_single"?'Delete':'Deactivate'} Home</div>
                                    <div className="hd-subtitle font-14">Are you sure you want to {type=="Deactivate_single"?'delete':'deactivate'} this home ?</div>
                                </>
                        }
                        {
                            showConnections ?
                                <>
                                    <Controller name="assignUser" control={control} defaultValue={[]} render={({ field }) => (
                                        <Select options={userListData} className="mb-3"
                                            placeholder={`Users (${userListData?.length || 0})`}
                                            isMulti={false}
                                            {...field}
                                            value={selectedSubOptions}
                                            onChange={(option) => (handleUserChange(option))}
                                        />
                                    )}
                                    />
                                    {
                                        userNotAssignToHome?.length > 0 ?
                                            <Controller name="notAssignUers" control={control} defaultValue={[]} render={({ field }) => (
                                                <Select options={userListWithNoHomeAssign} className="mb-3"
                                                    placeholder={`Homes (${userListWithNoHomeAssign?.length})`}
                                                    isMulti={false}
                                                    {...field}
                                                    value={selectedUsersNoHome}
                                                    onChange={(option) => handleUserWithNoHome(option)}
                                                />
                                            )}
                                            /> : ''
                                    }
                                    {selectedUsersNoHome?.value?.length !== undefined ?
                                      <button type="button" className="btn btn-primary mb-3 w-100" onClick={() => (selectedUsersNoHome?.value?.length !== undefined ? TransferHomeToUser() : setShowConnections(true))}>
                                        Transfer
                                    </button>:''}
                                    <Controller name="Members" control={control} defaultValue={[]} render={({ field }) => (
                                        <Select options={memberListData} className="mb-3"
                                            placeholder={`Members (${memberListData?.length})`}
                                            isMulti={false}
                                            {...field}
                                            value={selectedMember}
                                            onChange={(option) => handleMemberChange(option)}
                                        />
                                    )}
                                    />
                                     {
                                        memberNotAssignToHome?.length > 0 ?
                                            <Controller name="notAssignUers" control={control} defaultValue={[]} render={({ field }) => (
                                                <Select options={homeListNotassignMember} className="mb-3"
                                                    placeholder={`Homes (${homeListNotassignMember?.length})`}
                                                    isMulti={false}
                                                    {...field}
                                                    value={selectMemberNoHome}
                                                    onChange={(option) => handleMemberWithNoHome(option)}
                                                />
                                            )}
                                            /> : ''
                                    }
                                      {selectMemberNoHome?.value?.length !== undefined ?
                                      <button type="button" className="btn btn-primary mb-3 w-100" onClick={() => (selectMemberNoHome?.value?.length !== undefined ? MemerTransferToHome() : setShowConnections(true))}>
                                        Transfer
                                    </button>:''}
                                </>
                                : ''
                        }
                        <div className="pop-action-btn">
                            <button type="button" className="btn btn-light" onClick={() => OnClose()}>Cancel</button>
                            {
                                cannotDeactivate ?
                                <>
                               { selectedUsersNoHome?.value?.length !== undefined ? '':
                                   data?.length===0?
                                   <button type="submit" className="btn btn-danger mx-2" onClick={() => (onSubmit())}>Delete</button>
                                   :
                                   <button type="button" className="btn btn-primary mx-2" onClick={() => (selectedUsersNoHome?.value?.length !== undefined ? TransferHomeToUser() : setShowConnections(true))}>
                                       View Connections
                                    </button>}
                                </>
                                    : <button type="submit" className="btn btn-danger mx-2" onClick={() => (type == 'Deactivate_single' && deleteSingle?.staff > 0 && data?.length>0 ? (setCannotDeactivate(true), onSubmit()) : onSubmit())}>{type=="Deactivate_single"?'Delete':'Deactivate'}</button>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </Modal>

    )
}

export default DeActivateHome