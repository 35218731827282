import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setTaskNotification } from "../redux/features/commonSlice";
import { monthDayYearFormat } from "../components/Common/Common";

const TaskNotification = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const BoardViewTasks = useSelector(
    (state) => state?.AuthTasks?.BoardViewTasks
  );

  const [tasks, setTasks] = useState([]);

  useEffect(() => {
    if (BoardViewTasks && BoardViewTasks.pending) {
      // Limit to the latest 5 tasks
      const latestTasks = BoardViewTasks.pending.slice(0, 5);
      setTasks(latestTasks);
    }
  }, [BoardViewTasks]);

  const handleSeeAll = () => {
    dispatch(setTaskNotification(false));
    navigate("/auth/tasks");
  };

  const handleTaskClick = (task) => {
    dispatch(setTaskNotification(false));
    navigate(`/auth/tasks/${task.id}`);
  };

  return (
    <div className="modal fade new_medi_modal show">
      <div className="modal-dialog notification_popup_align">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">
              Tasks
            </h5>
            <button
              type="button"
              className="btn-close"
              onClick={() => dispatch(setTaskNotification(false))}
            ></button>
          </div>
          <div className="modal-body">
            <div className="tab-content tabs_mem" id="nav-tabContent">
              <div
                className="tab-pane fade active show"
                id="nav-notification"
                role="tabpanel"
                aria-labelledby="nav-home-tab"
                tabindex="0"
              >
                {tasks && tasks.length === 0 ? (
                  <h6 className="no_task_notifi">No result found</h6>
                ) : (
                  <>
                    {tasks &&
                      tasks.length > 0 &&
                      tasks.map((item) => {
                        let shortName =
                          item?.userAssigned?.first_name
                            ?.charAt(0)
                            ?.toUpperCase() +
                            item?.userAssigned?.last_name
                              ?.charAt(0)
                              ?.toUpperCase() || "";

                        return (
                          <div className="notifivation_member">
                            <li onClick={() => handleTaskClick(item)}>
                              <div className="notifivation_member_profile">
                                <div className="name">
                                  {item?.userAssigned?.profile_pic ? (
                                    <img
                                      className="widthHeight"
                                      src={item?.userAssigned?.profile_pic}
                                      alt="profile"
                                    />
                                  ) : (
                                    <img
                                      className="widthHeight"
                                      src="/pro.png"
                                      alt={`'s profile`}
                                    />
                                  )}
                                </div>
                              </div>
                              <div className="notifivation_member_contant">
                                <p className="font-18 f_weight">{item.title}</p>
                                <div className="font-14 hd-subtitle">
                                  {item.description}{" "}
                                  <div className="gray_color"></div>{" "}
                                  {monthDayYearFormat(item.due_date, true)}
                                </div>
                              </div>
                            </li>
                          </div>
                        );
                      })}
                  </>
                )}
              </div>
            </div>
          </div>
          <div className="modal-all_notification border-0">
            {tasks.length > 0 ? (
              <p className="text=-center">
                {" "}
                <a onClick={() => handleSeeAll()}>
                  See all task{" "}
                  <svg
                    width="19"
                    height="14"
                    viewBox="0 0 19 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1.5 7H17.5M17.5 7L11.5 1M17.5 7L11.5 13"
                      stroke="black"
                      strokeWidth="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </a>
              </p>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TaskNotification;
