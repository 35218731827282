import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { customAlert } from "../../utils/alert";
import { HTTPURL } from "../../constant/Matcher";

export const getSubscriptionPlan = createAsyncThunk(
    "auth/subscription-plan",
    async () => {
        try {
            let config = {
                method: 'get',
                url: `${HTTPURL}api/subscription-plan`,
                headers: {}
            };
            const response = await axios.request(config)
            return response.data;
        } catch (error) {
            customAlert(error?.response?.data? error?.response?.data?.message : error?.message);
        }
    }
);
// api/subscription-form-check-email-phone
export const CheckEmailPhone = createAsyncThunk(
    "auth/check-email-phone",
    async (formData, thunkAPI) => {
        try {
            let config = {
                method: 'Post',
                url: `${HTTPURL}api/subscription-form-check-email-phone`,
                headers: {
                    "Content-Type": "application/json",
                },
                data: JSON.stringify(formData)
            };
            const res = await axios.request(config);
            if (res?.data?.status===true) {
                // customAlert(res?.data?.message,'success');
            } else {
                // customAlert(res?.data?.message);
            }
            return res.data;
        } catch (error) {
            customAlert(error?.response?.data? error?.response?.data?.message : error?.message);
        }
    }
);

export const getSubscriptionPlanDiscount = createAsyncThunk(
    "auth/subscription-discount",
    async () => {
        try {
            let config = {
                method: 'get',
                url: `${HTTPURL}api/subscription-discount`,
                headers: {}
            };
            const response = await axios.request(config)
            return response.data;
        } catch (error) {
            customAlert(error?.response?.data? error?.response?.data?.message : error?.message);
        }
    }
);

export const AddSubscriptionDetail = createAsyncThunk(
    "auth/Subscriptionr",
    async (formData, thunkAPI) => {
        try {
            let config = {
                method: 'Post',
                url: `${HTTPURL}api/subscription-form `,
                headers: {
                    "Content-Type": "application/json",
                },
                data: JSON.stringify(formData)
            };
            const res = await axios.request(config);
            if (res?.data?.status===true) {
                customAlert(res?.data?.message,'success');
            } else {
                customAlert(res?.data?.message);
            }
            return res.data;
        } catch (error) {
            customAlert(error?.response?.data? error?.response?.data?.message : error?.message);
        }
    }
);