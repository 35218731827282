import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import UploadAndManually from "./UploadAndManually";
import { useDispatch, useSelector } from "react-redux";
import { Get_step_form_complete_or_not } from "../../../redux/services/AuthMembers";

const FORMS = [
  { title: "DDD PCSP", type: "ddd_pcsp", url: "/auth/edit-members/ddd/pcsp/" },
  {
    title: "Individualized health care & safety needs",
    type: "Individualized_health",
    url: "/auth/edit-members/individualized/",
  },
  {
    title: "Medical history",
    type: "Medical_history",
    url: "/auth/edit-members/medicalhistory/",
  },
  {
    title: "General consent and authorization",
    type: "General_Consent",
    url: "/auth/edit-members/general-consent/",
  },
  {
    title: "Behavior modifying medication consent",
    type: "Medication_Consent",
    url: "/auth/edit-members/behavior-modifying/",
  },
];

export const Forms = ({ member_id, handleClose = () => {} }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const totalSteps = 13;
  const stepFormComplete = useSelector(
    (state) => state?.AuthMember?.stepFormComplete
  );
  const [uploadAndManuallyModal, setUploadAndManuallyModal] = useState(false);
  const [completedForms, setCompletedForms] = useState({});
  const [inProgressForms, setInProgressForms] = useState({});
  const [isIndividualizedHealthEnabled, setIsIndividualizedHealthEnabled] =
    useState(true);
  const [isOtherFormsEnabled, setIsOtherFormsEnabled] = useState(false);
  const [currentStep, setCurrentStep] = useState(0);
  const [progressPercentage, setProgressPercentage] = useState(0);

  useEffect(() => {
    console.log("member_id:", member_id); 
    if (member_id) {
      dispatch(Get_step_form_complete_or_not(member_id));
    } else {
      console.warn("member_id is undefined or null");
    }
  }, [dispatch, member_id]);

  useEffect(() => {
    if (stepFormComplete) {
      let newCompletedData = {};
      let newInProgressData = {};
      let dddPcspStep = 0; // Initialize with 0
      
      stepFormComplete.forEach((item) => {
        newCompletedData[item.form_type] = !item.profile_status;
        newInProgressData[item.form_type] = item.step_no > 0 && item.profile_status;
  
        // Store the ddd_pcsp step separately instead of setting state in loop
        if (item.form_type === "ddd_pcsp") {
          dddPcspStep = Number(item.step_no);
          
          if (!item.profile_status) {
            setIsIndividualizedHealthEnabled(true);
          }
        }
  
        if (item.form_type === "Individualized_health") {
          if (!item.profile_status && newCompletedData["ddd_pcsp"]) {
            setIsOtherFormsEnabled(true);
          }
        }
      });
      

      setCurrentStep(dddPcspStep);
      setProgressPercentage((dddPcspStep / totalSteps) * 100);
      setCompletedForms(newCompletedData);
      setInProgressForms(newInProgressData);
    }
  }, [stepFormComplete]);

  const handleFormNavigate = (url) => {
    setUploadAndManuallyModal(true);
  };

  const handleEdit = (navigateUrl) => {
    if (location.pathname === navigateUrl) {
      handleClose();
    } else {
      navigate(navigateUrl);
    }
  };

  const getFormStatus = (formType) => {
    if (completedForms[formType]) {
      return "Completed";
    } else if (inProgressForms[formType]) {
      return "In Progress";
    } else {
      return "Incomplete";
    }
  };

  const renderFormSection = (title, formType, navigateUrl) => {
    const status = getFormStatus(formType);


    return (
      <div className="memb_mult_field_main" key={formType}>
        <div className="member_field_left">
          <h4>
            {title}
            <span
              className={`mem_field_bages ${
                status === "Completed"
                  ? "comp_badge"
                  : status === "In Progress"
                  ? "in_progress_badge"
                  : ""
              }`}
            >
              {status}
            </span>
          </h4>
        </div>
        <div className="member_field_right">
          {status === "Completed" ? (
            <button type="button" onClick={() => handleEdit(navigateUrl)}>
              Edit
            </button>
          ) : (
            <button
              type="button"
              onClick={() =>
                formType === "ddd_pcsp"
                  ? handleFormNavigate()
                  : navigate(navigateUrl)
              }
            >
              {status === "In Progress" ? "Continue" : "Complete Now"}
            </button>
          )}
        </div>
      </div>
    );
  };

  return (
    <div
      className="tab-pane active show"
      id="form"
      role="tabpanel"
      aria-labelledby="form-tab"
    >
      <div className="member_vital_form_cont">
        <div className="vital_form_progress">
          <div className="vital_form_progress_inner">
            <h4>Complete upload of DDD/PCSP form</h4>
            <div className="steps-progress">
              <div className="progress">
                <div
                  className="progress-bar"
                  role="progressbar"
                  style={{ width: `${progressPercentage}%` }}
                  aria-valuenow={progressPercentage}
                  aria-valuemin="0"
                  aria-valuemax="100"
                >
                  {Math.round(progressPercentage)}%
                </div>
              </div>
              <h5 className="sap-card-title">
                {" "}
                STEP {currentStep} OF {totalSteps}
              </h5>
            </div>
          </div>
        </div>

        {FORMS.map((item) =>
          renderFormSection(item.title, item.type, `${item.url}${member_id}`)
        )}
      </div>
      <UploadAndManually
        id={member_id}
        isOpen={uploadAndManuallyModal}
        setIsOpen={setUploadAndManuallyModal}
      />
    </div>
  );
};