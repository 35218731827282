import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import SideBar from "./SideBar";
import { useParams } from "react-router-dom";
import UrlBar from "./UrlBar";
import AddCommunication from "./AddCommunication";
import Messages from "./Messages";
import { New_ComSvg, SearchSvg } from "../../../Images/CommunicationSvg";
import {
  get_home_with_member,
  get_communication_member,
} from "../../../redux/services/AuthCommunication";
import { reSetCommunicationById } from "../../../redux/features/AuthCommunicationSlice";

const CommunicationDetails = () => {
  const dispatch = useDispatch();
  const { id } = useParams();

  const communicationMemberList = useSelector(
    (state) => state?.AuthCommunication?.communicationMemberList?.member
  );
  const home_name = useSelector(
    (state) => state?.AuthCommunication?.communicationMemberList?.home_name
  );
  const userPermissions = useSelector(
    (state) => state?.common?.userPermissions
  );

  const [addNewModal, setAddNewModal] = useState(false);
  const [selectedMemberId, setSelectedMemberId] = useState("");
  const [selectedMember, setSelectedMember] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [reloadData, setReloadData] = useState(false);

  useEffect(() => {
    if (id && !addNewModal) {
      let member_id = localStorage.getItem("member_id");
      if (member_id) {
        setSelectedMemberId(member_id);
        let data = {
          page: 1,
          listData: 10,
          searchBar: "",
          member_id: member_id,
        };
        dispatch(get_communication_member(data));
      }
      dispatch(get_home_with_member(id));
    }
  }, [id, addNewModal]);

  useEffect(() => {
    // Update selected member when member list or selected member ID changes
    if (communicationMemberList && selectedMemberId) {
      const filteredMember = communicationMemberList.find(
        (item) => item.id === selectedMemberId
      );

      if (filteredMember) {
        setSelectedMember(filteredMember);
      } else if (communicationMemberList.length > 0) {
        // Fallback: if no member matches the ID, select the first member
        const firstMember = communicationMemberList[0];
        setSelectedMemberId(firstMember.id);
        setSelectedMember(firstMember);
      }
    }
  }, [communicationMemberList, selectedMemberId]);

  useEffect(() => {
    if (reloadData) {
      let data = {
        page: 1,
        listData: 10,
        searchBar: "",
        member_id: selectedMemberId,
      };
      dispatch(get_communication_member(data));
      setReloadData(false);
    }
  }, [reloadData]);

  const handleMemberSelect = (id) => {
    if (userPermissions && userPermissions.includes("View")) {
      setSelectedMemberId(id);
      let data = {
        page: 1,
        listData: 10,
        searchBar: "",
        member_id: id,
      };
      dispatch(get_communication_member(data));
    }
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
    let data = {
      page: pageNumber,
      listData: 10,
      searchBar: "",
      member_id: selectedMemberId,
    };
    dispatch(get_communication_member(data));
  };

  const handleAddNew = () => {
    setAddNewModal(true);
    dispatch(reSetCommunicationById());
  };

  const handleSearchBar = (e) => {
    let data = {
      page: 1,
      listData: 10,
      member_id: selectedMemberId,
      searchBar: e.target.value,
    };
    dispatch(get_communication_member(data));
  };

  return (
    <div id="layoutSidenav">
      <SideBar />
      <div id="layoutSidenav_content">
        <main>
          <UrlBar homeName={home_name} />
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12 col-lg-12 col-12">
                <div className="right-main-sec appointment_dash_sec">
                  <div className="adminstrat_btn_main member_commun">
                    <h2 className="font-16 hd-title member_comm">Members</h2>
                    <div className="adminstrat_btn">
                      {userPermissions && userPermissions.includes("Add") && (
                        <button
                          type="button"
                          className="btn btn-primary"
                          onClick={() => handleAddNew()}
                        >
                          <New_ComSvg />
                          New Com Log
                        </button>
                      )}
                    </div>
                  </div>
                  <div className="member_sectio">
                    <div className="nav nav-pills tabs_sec_comm">
                      {communicationMemberList &&
                        communicationMemberList.map((item) => {
                          if (!item) return null;
                          let name =
                            item?.first_name.charAt(0) +
                            item?.last_name.charAt(0);
                          return (
                            <div
                              className={`table-profile  ${
                                item.id === selectedMemberId ? "active" : ""
                              }`}
                              style={{
                                cursor: "pointer",
                              }}
                              key={item.id}
                            >
                              <div
                                className="profile-sec appointment-member"
                                onClick={() => handleMemberSelect(item.id)}
                                style={{ width: "100%" }}
                              >
                                {item.profile_pic ? (
                                  <div
                                    style={{
                                      position: "relative",
                                      display: "inline-block",
                                    }}
                                  >
                                    <img
                                      src={item.profile_pic}
                                      alt="Profile"
                                      style={{ width: "50px", height: "50px" }}
                                    />
                                    <span
                                      style={{
                                        position: "absolute",
                                        bottom: "-2px",
                                        right: "14px",
                                        width: "12px",
                                        height: "12px",
                                        borderRadius: "50%",
                                        backgroundColor:
                                          item.profile_status === false
                                            ? "#10b981"
                                            : "#9ca3af",
                                        border: "2px solid white",
                                      }}
                                    ></span>
                                  </div>
                                ) : (
                                  <div
                                    style={{
                                      position: "relative",
                                      display: "inline-block",
                                    }}
                                  >
                                    <span
                                      className="communication_hg"
                                      style={{
                                        width: "50px",
                                        height: "50px",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                      }}
                                    >
                                      {name}
                                    </span>
                                    <span
                                      style={{
                                        position: "absolute",
                                        bottom: "-2px",
                                        right: "14px",
                                        width: "12px",
                                        height: "12px",
                                        borderRadius: "50%",
                                        backgroundColor:
                                          item.profile_status === true
                                            ? "#10b981"
                                            : "#9ca3af",
                                        border: "2px solid white",
                                      }}
                                    ></span>
                                  </div>
                                )}
                                <span className="oln"></span>
                                <div className="profile-info">
                                  <p className="font-14 hd-title">
                                    {item.first_name} {item.last_name}
                                    <span className="d-block hd-subtitle text-start">
                                      {item.ahss_id}
                                    </span>
                                  </p>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                    </div>
                    <div
                      className="tab-content communication_logs"
                      id="v-pills-tabContent"
                    >
                      <div className="saf-img-upload">
                        <div className="form-group searchbar">
                          <input
                            type="text"
                            name=""
                            id=""
                            className="form-control"
                            maxLength={20}
                            placeholder="Search"
                            onChange={(e) => handleSearchBar(e)}
                          />
                          <SearchSvg />
                        </div>
                      </div>
                      <Messages
                        handlePageChange={handlePageChange}
                        currentPage={currentPage}
                        setReloadData={setReloadData}
                        setAddNewModal={setAddNewModal}
                        selectedMember={selectedMember}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
      {addNewModal && (
        <AddCommunication
          onModalToggle={setAddNewModal}
          currentHomeName={home_name}
          currentMemberId={selectedMemberId}
          selectedMember={selectedMember}
        />
      )}
    </div>
  );
};

export default CommunicationDetails;
