import React, { useEffect, useState } from "react";
import { useForm, useFieldArray } from "react-hook-form";
import { useSelector } from "react-redux";
import PcspFooter from "../../PcspFooter";

const MedicalDentalBehavioral = ({ onHandleBackStep, onSubmit }) => {
  const [lastVisitDate, setLastVisitDate] = useState("");
  const allDddPcsp = useSelector(
    (state) => state?.AuthMember?.allDddPcsp?.preferenceStrenth
  );
  // Initialize the form with useForm hook
  const {
    control,
    handleSubmit,
    register,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      behavioralProInfo: [
        {
          provider_name_address: "",
          p_phonenumber: "",
          p_specialty: "",
          last_visit: "",
          next_visit: "",
          transport_or_companioncare_needed: "",
        },
      ],
    },
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "behavioralProInfo",
  });

  useEffect(() => {
    setValue("notes", allDddPcsp?.notes);
    setValue(
      "alt_traditional_holistic_healing",
      allDddPcsp?.alt_traditional_holistic_healing
    );
    if (allDddPcsp?.memberdddpcspprefstrengthsmedicalinfoprovider) {
      const lengthDifference =
        allDddPcsp?.memberdddpcspprefstrengthsmedicalinfoprovider?.length -
        fields.length;
      if (lengthDifference > 0) {
        for (let i = 0; i < lengthDifference; i++) {
          append({});
        }
      }
      console.log(
        allDddPcsp?.memberdddpcspprefstrengthsmedicalinfoprovider,
        "allDddPcsp?.memberdddpcspprefstrengthsmedicalinfoprovider"
      );
      allDddPcsp?.memberdddpcspprefstrengthsmedicalinfoprovider.forEach(
        (item, index) => {
          setValue(
            `behavioralProInfo.${index}.last_visit`,
            item.last_visit || ""
          );
          setValue(
            `behavioralProInfo.${index}.next_visit`,
            item.next_visit || ""
          );
          setValue(
            `behavioralProInfo.${index}.p_phonenumber`,
            item.p_phonenumber || ""
          );
          setValue(
            `behavioralProInfo.${index}.p_specialty`,
            item.p_specialty || ""
          );
          setValue(
            `behavioralProInfo.${index}.provider_name_address`,
            item.provider_name_address || ""
          );
          setValue(
            `behavioralProInfo.${index}.transport_or_companioncare_needed`,
            item.transport_or_companioncare_needed || ""
          );
        }
      );
    }
  }, [allDddPcsp]);
  const handleLastVisitChange = (e, index) => {
    const date = new Date(e.target.value);
    date.setDate(date.getDate() + 1); // Add 1 day
    const nextDay = date.toISOString().split('T')[0];
  
    setValue(`behavioralProInfo.${index}.last_visit`, nextDay, {
      shouldValidate: true, // Trigger validation after setting the value
    });
  };

  return (
    <>
      <div className="member_vitalstep_one_form">
        <form onSubmit={handleSubmit(onSubmit)} className="row">
          <div className="member_vital_basic_title">
            <h4>Medical/Dental/Behavioral provider information</h4>
          </div>
          {fields.map((member, index) => (
            <div key={member.id} className="row">
              <div className="col-md-6 mb-3">
                <label for="" className="form-label">
                  Provider Name/Address*
                </label>
                <input
                  type="input"
                  className="form-control"
                  placeholder="Enter Provider Name/Address"
                  {...register(
                    `behavioralProInfo.${index}.provider_name_address`,
                    { required: "Provider is required!" }
                  )}
                  maxLength={20}
                />
                {errors.behavioralProInfo &&
                  errors.behavioralProInfo[index]?.provider_name_address && (
                    <span className="error">
                      {
                        errors.behavioralProInfo[index].provider_name_address
                          .message
                      }
                    </span>
                  )}
              </div>
              <div className="col-md-6 mb-3">
                <label className="form-label">Phone number*</label>
                <input
                  type="input"
                  className="form-control"
                  placeholder="000 0000 000"
                  {...register(`behavioralProInfo.${index}.p_phonenumber`, {
                    required: "Phone number is required",
                    pattern: {
                      value: /^\d{10,15}$/,
                      message: "Phone number must be 10 to 15 digits",
                    },
                  })}
                  maxLength={15}
                  onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  onPaste={(event) => {
                    // Prevent pasting any non-numeric characters
                    const pastedData = event.clipboardData.getData("text");
                    if (!/^\d{1,5}$/.test(pastedData)) {
                      event.preventDefault();
                    }
                  }}
                />
                {errors.behavioralProInfo &&
                  errors.behavioralProInfo[index]?.p_phonenumber && (
                    <span className="error">
                      {errors.behavioralProInfo[index].p_phonenumber.message}
                    </span>
                  )}
              </div>
              <div className="col-md-6 col-lg-4 mb-3">
                <label for="" className="form-label">
                  Provider specialty*
                </label>
                <input
                  type="input"
                  className="form-control"
                  placeholder="Enter Provider specialty"
                  {...register(`behavioralProInfo.${index}.p_specialty`, {
                    required: "Provider specialty is required!",
                  })}
                  maxLength={20}
                />
                {errors.behavioralProInfo &&
                  errors.behavioralProInfo[index]?.p_specialty && (
                    <span className="error">
                      {errors.behavioralProInfo[index].p_specialty.message}
                    </span>
                  )}
              </div>
              <div className="col-md-6 col-lg-4 mb-3">
                <label className="form-label">Last visit*</label>
                <input
                  type="date"
                  className="form-control"
                  placeholder="Enter Last visit"
                  {...register(`behavioralProInfo.${index}.last_visit`, {
                    required: "Last visit is required",
                  })}
                  onKeyDown={(e) => e.preventDefault()}
                  onChange={(e) => handleLastVisitChange(e, index)}
                />
                {errors.behavioralProInfo &&
                  errors.behavioralProInfo[index]?.last_visit && (
                    <span className="error">
                      {errors.behavioralProInfo[index].last_visit.message}
                    </span>
                  )}
              </div>

              <div className="col-md-6 col-lg-4 mb-3">
                <label for="" className="form-label">
                  Next visit*
                </label>
                <input
                  type="date"
                  className="form-control"
                  id=""
                  placeholder="Enter Next visit"
                  min={lastVisitDate}
                  {...register(`behavioralProInfo.${index}.next_visit`, {
                    required: "Next visit is required",
                  })}
                  onKeyDown={(e) => e.preventDefault()}
                />
                {errors.behavioralProInfo &&
                  errors.behavioralProInfo[index]?.next_visit && (
                    <span className="error">
                      {errors.behavioralProInfo[index].next_visit.message}
                    </span>
                  )}
              </div>

              <div className="col-md-6 mb-3">
                <label for="" className="form-label">
                  Transportation or companion care needed*
                </label>
                <input
                  type="input"
                  className="form-control"
                  placeholder="Enter Transportation or companion"
                  {...register(
                    `behavioralProInfo.${index}.transport_or_companioncare_needed`,
                    { required: "Transportation or companion is required!" }
                  )}
                  maxLength={20}
                />
                {errors.behavioralProInfo &&
                  errors.behavioralProInfo[index]
                    ?.transport_or_companioncare_needed && (
                    <span className="error">
                      {
                        errors.behavioralProInfo[index]
                          .transport_or_companioncare_needed.message
                      }
                    </span>
                  )}
              </div>
              {index > 0 && (
                <div className="col-12 mb-3">
                  <a
                    href="#"
                    className="stepone_remove"
                    onClick={(e) => {
                      e.preventDefault();
                      remove(index);
                    }}
                  >
                    Remove
                  </a>
                </div>
              )}
              <hr />
            </div>
          ))}

          <div className="stepone_health_planbtn">
            <button
              type="button"
              className="font-14"
              onClick={() => append({ id: Date.now() })}
            >
              <svg
                width="12"
                height="12"
                viewBox="0 0 12 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M6.00016 1.33325V10.6666M1.3335 5.99992H10.6668"
                  stroke="#667085"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>
              </svg>
              Add another Provider
            </button>
          </div>
          <div className="col-12 mb-3">
            <label for="" className="form-label">
              Do you use alternative, traditional, or holistic healing?*
            </label>
            <select
              className="form-select"
              aria-label="Default select example"
              {...register(`alt_traditional_holistic_healing`, {
                required: "This is required!",
              })}
            >
              <option value="">Select</option>
              <option value="0">No</option>
              <option value="1">Yes</option>
              <option value="2">N/A</option>
            </select>
            {errors.alt_traditional_holistic_healing && (
              <span className="error">
                {errors.alt_traditional_holistic_healing.message}
              </span>
            )}
          </div>
          <div className="col-12">
            <label for="" className="form-label">
              Notes*
            </label>
            <div className="mb-3">
              <textarea
                className="form-control"
                placeholder="Enter notes"
                {...register("notes", { required: "true" })}
                maxLength={150}
              />
              {errors.notes && errors.notes.type === "required" && (
                <span className="error">Notes is required!</span>
              )}
            </div>
          </div>
          <PcspFooter onHandleBackStep={onHandleBackStep} />
        </form>
      </div>
    </>
  );
};
export default MedicalDentalBehavioral;
