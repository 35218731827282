import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Pagination } from "../../Pagination";
import {
  formatAMPM,
  monthDayYearFormat,
} from "../../../components/Common/Common";
import { appointmentStatusOptions } from "./CommonData";
import ResultNotFound from "../Members/ResultNotFound";

const Listview = ({
  FromMember = "",
  onRowClick,
  handlePageChange,
  currentPage,
}) => {
  const AllAppointmentList = useSelector(
    (state) => state?.AuthAppointment?.AllAppointmentList?.appointments
  );
  const totalNoPages = useSelector(
    (state) => state?.AuthAppointment?.AllAppointmentList?.totalNoPages
  );
  const ExistingLocationList = useSelector(
    (state) => state?.AuthAppointment?.ExistingLocationList
  );

  const [appointmentData, setAppointmentData] = useState([]);

  useEffect(() => {
    if (FromMember) {
      const filteredData =
        AllAppointmentList &&
        AllAppointmentList?.filter((item) =>
          item?.appointmentMemberList?.some(
            (member) => member?.member?.id === FromMember
          )
        );
      setAppointmentData(filteredData);
    } else {
      setAppointmentData(AllAppointmentList);
    }
  }, [FromMember, AllAppointmentList]);

  return (
    <div className="row">
      <div className="col-md-12 col-12">
        <div className="table-sec">
          <div className="table-responsive">
            {appointmentData && appointmentData.length > 0 ? (
              <table className="table table-hover appointment_table">
                <thead>
                  <tr>
                    {FromMember === "" && <th scope="col">Members</th>}
                    <th scope="col">Appointment Date</th>
                    <th scope="col">With</th>
                    <th scope="col">Location</th>
                    <th scope="col">Approval Status</th>
                    <th scope="col">Appointment Status</th>
                  </tr>
                </thead>
                <tbody>
                  {appointmentData &&
                    appointmentData.map((item) => {
                      let time = item?.appointment_time?.split("-");
                      let appointmentLabel = "";
                      let appointment = appointmentStatusOptions.find(
                        (elm) => elm.value === item?.appointment_status
                      );
                      if (appointment) {
                        appointmentLabel = appointment?.lable;
                      }
                      return (
                        <tr className="hover-row">
                          {FromMember === "" && (
                            <td>
                              <div className="table-profile ">
                                <div className="form-check appointment_check ">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    value=""
                                    id="flexCheckIndeterminate"
                                  />
                                </div>
                                <div
                                  className="profile-sec"
                                  onClick={() => onRowClick(item?.id)}
                                >
                                  {item?.appointmentMemberList?.map(
                                    (member, index) => {
                                      if (index < 5) {
                                        return (
                                          <div
                                            className="appointment-member child-div"
                                            key={index}
                                          >
                                            <span className="name child-div">
                                              {member?.member?.profile_pic ? (
                                                <div className="tooltipp">
                                                  <span
                                                    className="tooltipptext"
                                                    style={{
                                                      color: "white",
                                                      background: "black",
                                                    }}
                                                  >
                                                    {member?.member
                                                      ?.first_name +
                                                      " " +
                                                      member?.member?.last_name}
                                                  </span>
                                                  <div className="Profile_curve2 child-div">
                                                    <img
                                                      src={
                                                        member?.member
                                                          ?.profile_pic
                                                      }
                                                      alt={`${member?.member?.first_name}'s profile`}
                                                    />
                                                  </div>
                                                </div>
                                              ) : (
                                                <div className="tooltipp">
                                                  <span
                                                    className="tooltipptext"
                                                    style={{
                                                      color: "white",
                                                      background: "black",
                                                    }}
                                                  >
                                                    {member?.member
                                                      ?.first_name +
                                                      " " +
                                                      member?.member?.last_name}
                                                  </span>
                                                  <div className="Profile_curve2 child-div">
                                                    <img
                                                      src="/pro.png"
                                                      alt={`'s profile`}
                                                    />
                                                  </div>
                                                </div>
                                              )}
                                            </span>
                                          </div>
                                        );
                                      }
                                      return null;
                                    }
                                  )}
                                  {item?.appointmentMemberList?.length > 5 && (
                                    <div
                                      className="tooltipp bg-white"
                                      style={{
                                        cursor: "pointer",
                                        position: "relative",
                                        right: "6px",
                                      }}
                                    >
                                      <span
                                        className="tooltipptext"
                                        style={{
                                          color: "white",
                                          background: "black",
                                          position: "absolute",
                                          left: "30px",
                                        }}
                                      >
                                        {item?.appointmentMemberList?.length >
                                          5 && (
                                          <div className="">
                                            {item?.appointmentMemberList
                                              .slice(
                                                5,
                                                item?.appointmentMemberList
                                                  ?.length
                                              )
                                              .map((e) => (
                                                <div
                                                  style={{
                                                    background: "black",
                                                    color: "white",
                                                    display: "flex",
                                                    flexDirection: "column",
                                                  }}
                                                >
                                                  {e.member.first_name +
                                                    " " +
                                                    e.member.last_name}
                                                </div>
                                              ))}
                                          </div>
                                        )}
                                      </span>
                                      <div className="border-white child-div">
                                        {item?.appointmentMemberList?.length >
                                          5 && (
                                          <div className="appointment_plus others_docs">
                                            +
                                            {item?.appointmentMemberList
                                              ?.length - 5}
                                          </div>
                                        )}
                                      </div>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </td>
                          )}
                          <td
                            className="hd-subtitle text-dark"
                            onClick={() => onRowClick(item?.id)}
                          >
                            {monthDayYearFormat(
                              item?.appointment_date,
                              true
                            ).replaceAll("-", "/")}
                            <span className="d-block hd-subtitle">
                              {formatAMPM(time[0])} - {formatAMPM(time[1])}
                            </span>
                          </td>
                          <td className="hd-subtitle text-dark">
                            Dr. {item?.memberHealthPrimaryPhysician?.first_name}{" "}
                            {item?.memberHealthPrimaryPhysician?.last_name}{" "}
                            <span className="d-block hd-subtitle">
                              {item?.doctor_specialty}
                            </span>
                          </td>
                          <td className="hd-subtitle text-dark">
                            {item?.masterLocation?.name || ""}
                          </td>
                          <td className="approval-status-tbl">
                            <div className="badges">
                              <span className={item?.approval_status}></span>
                              {item?.approval_status}
                            </div>
                          </td>
                          <td className="approval-status-tbl">
                            <div className="badges">
                              <span className={item?.appointment_status}>
                                {" "}
                              </span>{" "}
                              {appointmentLabel}
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                  <tr>
                    <td colSpan="7">
                      <Pagination
                        currentPage={currentPage}
                        totalPages={totalNoPages}
                        onPageChange={handlePageChange}
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            ) : (
              <ResultNotFound />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Listview;
