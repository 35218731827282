import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import {
  UploadIconSvg,
  PdfIconSvg,
  DeleteIconSvg,
} from "../../../Images/CommunicationSvg";
import {
  CrossSvg,
  SearchSvg,
  AfterSearchSvg,
} from "../../../Images/DocumentsSvg";
import {
  Add_documents,
  get_loggedIn_user_member_list,
} from "../../../redux/services/AuthDocuments";

import Doc from "../../../Images/fileTypes/doc.png";
import Docx from "../../../Images/fileTypes/docx-file.png";
import Jpeg from "../../../Images/fileTypes/jpeg.png";
import Jpg from "../../../Images/fileTypes/jpg.png";
import Pdf from "../../../Images/fileTypes/pdf.png";
import Png from "../../../Images/fileTypes/png.png";
import Xlsx from "../../../Images/fileTypes/xlsx.png";


const fileFormate = [
  "application/msword",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  "application/pdf",
  "image/png",
  "image/jpeg",
  "image/jpg",
];

const fileFormateSvg = {
  "application/msword": Doc,
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
    Docx,
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": Xlsx,
  "application/pdf": Pdf,
  "image/png": Png,
  "image/jpeg": Jpeg,
  "image/jpg": Jpg,
};

const getFileIcon = (file) => {

  if (fileFormateSvg[file.type]) {
    return fileFormateSvg[file.type];
  }

  const extension = file.name.split(".").pop().toLowerCase();
  switch (extension) {
    case "doc":
      return Doc;
    case "docx":
      return Docx;
    case "xlsx":
      return Xlsx;
    case "pdf":
      return Pdf;
    case "png":
      return Png;
    case "jpeg":
      return Jpeg;
    case "jpg":
      return Jpg;
    default:
      return Pdf;
  }
};

const AddDocuments = ({ onModalToggle }) => {
  const dispatch = useDispatch();
  const {
    handleSubmit,
    register,
    clearErrors,
    setError,
    formState: { errors },
  } = useForm();
  const moduleData = useSelector((state) => state?.common?.moduleData);
  const memberList = useSelector((state) => state?.AuthDocuments?.memberList);

  const [files, setFiles] = useState([]);
  const [progress, setProgress] = useState({});
  const [selectedMember, setSelectedMember] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [fileError, setFileError] = useState("");
  const [isChecked, setIsChecked] = useState(false);

  useEffect(() => {
    dispatch(get_loggedIn_user_member_list());
  }, []);

  const onSubmit = async (payload) => {
    if (selectedMember.length === 0) {
      setError("member_ids", { type: "required" });
    } else {
      const formData = new FormData();
      formData.append("category_id", payload?.category_id);

      files.forEach((file) => formData.append("file_name", file));
      selectedMember.forEach((member) =>
        formData.append("member_ids", member?.id)
      );
      const response = await dispatch(Add_documents(formData));
      if (response?.payload?.status) {
        onModalToggle(false);
        setSelectedMember([]);
        setFilteredData([]);
        setSearchInput("");
      }
    }
  };

  const handleDeleteFile = (index) => {
    setFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
  };

  const handleFileChange = (event) => {
    const selectedFiles = Array.from(event.target.files);

    if (selectedFiles.length === 0) return;

    const file = selectedFiles[0];
    const fileType = file.type;
    const fileExtension = file.name.split(".").pop().toLowerCase();

    console.log("File type:", fileType);
    console.log("File extension:", fileExtension);

    // Check if the file type is directly in our allowed list
    let isAllowedType = fileFormate.includes(fileType);

    // For Office documents, sometimes browsers report generic types
    if (!isAllowedType) {
      // Check extension as fallback
      if (fileExtension === "doc" || fileExtension === "docx") {
        isAllowedType = true;
      } else if (fileExtension === "xlsx" || fileExtension === "xls") {
        isAllowedType = true;
      } else if (fileExtension === "pdf") {
        isAllowedType = true;
      } else if (["png", "jpg", "jpeg"].includes(fileExtension)) {
        isAllowedType = true;
      }
    }

    if (isAllowedType) {
      if (file.size <= 5000000) {
        // 5MB limit
        setFileError("");
        setFiles((prevFiles) => [...prevFiles, ...selectedFiles]);
        selectedFiles.forEach(simulateProgress);
      } else {
        setFileError("File size should be less than or equal to 5MB");
      }
    } else {
      setFileError(
        "Invalid file type. Allowed types: DOC, DOCX, XLSX, PDF, PNG, JPG, JPEG"
      );
    }
  };

  const simulateProgress = (file) => {
    const totalDuration = 2000;
    const interval = 100;
    const totalSteps = totalDuration / interval;

    let currentStep = 0;

    const progressInterval = setInterval(() => {
      currentStep++;
      const percentComplete = (currentStep / totalSteps) * 100;
      setProgress((prev) => ({ ...prev, [file.name]: percentComplete }));

      if (currentStep >= totalSteps) {
        clearInterval(progressInterval);
      }
    }, interval);
  };

  const handleSelectAllMembers = (e) => {
    setIsChecked(e.target.checked);
    clearErrors("member_ids");
    if (e.target.checked) {
      setSelectedMember(memberList);
    } else {
      setSelectedMember([]);
    }
  };

  const handleMemberSearch = (e) => {
    const input = e.target.value.toLowerCase();
    if (input !== "") {
      setSearchInput(input);
      const filtered =
        memberList &&
        memberList.filter((user) =>
          user.first_name.toLowerCase().includes(input)
        );
      setFilteredData(filtered);
    } else {
      setFilteredData([]);
      setSearchInput("");
    }
  };

  const handleMemberClick = (data) => {
    clearErrors("member_ids");
    let newArray = [...selectedMember, data];
    let unique = [...new Set(newArray)];
    setSelectedMember(unique);
    const newSelectedUsers =
      filteredData && filteredData.filter((item) => item.id !== data.id);
    let filterdUnique = [...new Set(newSelectedUsers)];
    setFilteredData(filterdUnique);
  };

  const handleMemberDelete = (data) => {
    const deletedMember = selectedMember.filter((elm) => elm.id === data.id);
    const otherMember = selectedMember.filter((elm) => elm.id !== data.id);
    setFilteredData((prevFiles) => [...prevFiles, ...deletedMember]);
    setSelectedMember(otherMember);
  };

  return (
    <div className="modal fade new_medi_modal show">
      <div className="modal-dialog modal-lg permision-popup">
        <div className="modal-content">
          <div className="modal-header margin_header">
            <h2>Upload Document</h2>
            <button
              type="button"
              className="btn-close"
              onClick={() => onModalToggle(false)}
            ></button>
          </div>
          <div className="modal-body medication_modal">
            <form className="row" onSubmit={handleSubmit(onSubmit)}>
              {files.length === 0 && (
                <>
                  <div className="col-md-12 my-4">
                    <h2 className="font-14 hd-title">Document Attachment</h2>
                    <p className="font-14 hd-subtitle">
                      You can share photos and files
                    </p>
                  </div>
                  <div className="upload-btn-wrapper">
                    <button className="vitalu_cont">
                      <div className="upload_icon">
                        <UploadIconSvg />
                      </div>
                      <div className="vital_text_png">
                        <span>Click to upload</span> or drag and drop <br />
                        PDF, PNG, JPEG, JPG, docx, xlsx (max. 5mb)
                      </div>
                    </button>
                    <input
                      type="file"
                      name="myfile"
                      onChange={handleFileChange}
                      multiple={false}
                    />
                  </div>
                </>
              )}
              {fileError && <span className="error">{fileError}</span>}

              {files.map((file, index) => (
                <div className="" key={index}>
                  <div key={file.name} className="progress_bar">
                    <div className="progress_pdf">
                      <img src={getFileIcon(file)} alt={file.name} />
                    </div>
                    <div className="progres_file">
                      <div className="progres_file_cont">
                        <h4>
                          {file.name}
                          <span>{(file.size / 1024).toFixed(2)} KB</span>
                        </h4>
                        <div
                          className="upload_pdf_del"
                          onClick={() => handleDeleteFile(index)}
                        >
                          <DeleteIconSvg />
                        </div>
                      </div>
                      <div className="progress_line">
                        <div
                          className="progres_line_blue"
                          style={{ width: `${progress[file.name] || 0}%` }}
                        ></div>
                        <h4>
                          {progress[file.name]
                            ? `${progress[file.name].toFixed(0)}%`
                            : "0%"}
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>
              ))}

              <div className="col-md-12 mb-12">
                <label className="form-label">Category*</label>
                <select
                  className="form-select"
                  aria-label="Default select example"
                  {...register("category_id", { required: "true" })}
                >
                  <option value="">-Please Select -</option>
                  {moduleData.map((item) => (
                    <option
                      key={item.id || item.module_id}
                      value={item.id || item.module_id}
                    >
                      {item.module_name}
                    </option>
                  ))}
                </select>
                {errors.category_id && (
                  <span className="error">Category is required!</span>
                )}
              </div>
              <div className="col-md-12 col-12 my-4">
                <input
                  className="form-check-input"
                  type="checkbox"
                  value=""
                  id="flexCheckDefault"
                  onChange={handleSelectAllMembers}
                />
                <label className="form-check-label" htmlFor="flexCheckDefault">
                  &nbsp; Attach document to all members
                </label>
              </div>
              {!isChecked && (
                <div className="col-12 ">
                  <div className="appointment_selects search_document">
                    <div className="row">
                      {!isChecked && (
                        <>
                          <label
                            className="form-check-label"
                            htmlFor="flexSearch"
                          >
                            Members
                          </label>
                          <div className="col-md-12 mb-12 search-sect">
                            <SearchSvg />
                            <input
                              type="search"
                              className="form-control"
                              placeholder="Search a member to attach the document to their profile"
                              value={searchInput}
                              onChange={(e) => handleMemberSearch(e)}
                            />
                            <div className="search_icon_s">
                              {memberList.length === 0 ? (
                                ""
                              ) : (
                                <>{/* <AfterSearchSvg /> */}</>
                              )}
                            </div>
                          </div>
                        </>
                      )}
                      {searchInput !== "" && (
                        <div className="member_container">
                          <ul>
                            {filteredData && filteredData.length > 0 ? (
                              <>
                                {filteredData.map((user) => {
                                  let shortName =
                                    user?.first_name?.charAt(0)?.toUpperCase() +
                                      user?.last_name
                                        ?.charAt(0)
                                        ?.toUpperCase() || "";
                                  return (
                                    <li
                                      key={user.id}
                                      className="member_li"
                                      onClick={() => handleMemberClick(user)}
                                    >
                                      <span className="name ">
                                        {user?.profile_pic ? (
                                          <img src={user?.profile_pic} />
                                        ) : (
                                          shortName
                                        )}
                                      </span>
                                      {user.first_name} {user.last_name}
                                    </li>
                                  );
                                })}
                              </>
                            ) : (
                              <li>No Member Found</li>
                            )}
                          </ul>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              )}
              {errors.member_ids && errors.member_ids.type === "required" && (
                <span className="error">
                  Please {memberList.length === 0 ? "create" : "select"} member
                </span>
              )}
              {!isChecked && (
                <div className="row">
                  {selectedMember &&
                    selectedMember.map((item) => {
                      let shortName =
                        item?.first_name?.charAt(0)?.toUpperCase() +
                          item?.last_name?.charAt(0)?.toUpperCase() || "";
                      return (
                        <div className="col-md-4" key={item.id}>
                          <div className="document_option_doc">
                            <div className="profile-sec appointment-member">
                              <span className="name ">
                                {item?.profile_pic ? (
                                  <img src={item?.profile_pic} />
                                ) : (
                                  shortName
                                )}
                              </span>
                            </div>
                            <div className="profile-info">
                              <p className="font-14 hd-title">
                                {item?.first_name}
                              </p>
                            </div>
                            <div className="crose_div">
                              <span onClick={() => handleMemberDelete(item)}>
                                <CrossSvg />
                              </span>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                </div>
              )}
              <div className="up_vitalm_btn d-flex">
                <button
                  type="button"
                  className="w-50"
                  onClick={() => onModalToggle(false)}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="btn w-50"
                  disabled={files.length === 0}
                >
                  Save
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddDocuments;
