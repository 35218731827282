import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  Get_all_general_consent_authorization,
  General_consent_authorization,
} from "../../../../redux/services/AuthMembers";

import Header from "./Header";
import SideBar from "./SideBar";
import { AfterAddMember } from "../AfterAddMember";

// Import forms
import GeneralConsentAndAuthorizationForm from "./Forms/GeneralConsentAndAuthorizationForm";

const GENERALCONSENTFORM = ["GeneralConsentAndAuthorizationForm"];

const formSubSteps = {
  GeneralConsentAndAuthorizationForm: 1,
};

const formComponents = {
  GeneralConsentAndAuthorizationForm,
};

const GeneralConsentAndAuthorization = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  
  // Localized state for steps
  const [localStep, setLocalStep] = useState(0);
  const [localSubStep, setLocalSubStep] = useState(0);
  
  const [showPopUp, setShowPopUp] = useState({
    modal: false,
    message: "",
    id: "",
  });
  const totalSteps = GENERALCONSENTFORM.length;

  const getSubSteps = (formName) => formSubSteps[formName] || 1;
  
  // Remove the dependency on global stepForm state
  const get_all_general_consent_authorization = () => {
    dispatch(Get_all_general_consent_authorization(id));
  };
  
  useEffect(() => {
    get_all_general_consent_authorization();
  }, []);

  const handleCrossClick = () => {
    navigate(`/auth/edit-member/${id}`);
  };

  const onSubmit = async (data) => {
    let payload = {
      profile_status: 0,
      step_no: localStep + 1,
      sub_step_no: localSubStep + 1,
      member_id: id,
      ...data,
    };

    try {
      let response;
      switch (GENERALCONSENTFORM[localStep]) {
        case "GeneralConsentAndAuthorizationForm":
          let formData = new FormData();

          formData.append("profile_status", payload?.profile_status);
          formData.append("step_no", payload?.step_no);
          formData.append("sub_step_no", payload?.sub_step_no);
          formData.append("member_id", payload?.member_id);
          formData.append("agency_name", payload?.agency_name);
          formData.append(
            "medical_evaluation_assessments",
            payload?.medical_evaluation_assessments
          );
          formData.append(
            "necessary_emergency_medical",
            payload?.necessary_emergency_medical
          );
          formData.append("routine_dental_care", payload?.routine_dental_care);
          formData.append(
            "sedation_mechanical_restraint",
            payload?.sedation_mechanical_restraint
          );
          formData.append("medical_condition", payload?.medical_condition);
          formData.append("signature", payload?.signature);
          formData.append(
            "protectiveDevice",
            JSON.stringify(payload?.protectiveDevice)
          );
          response = await dispatch(General_consent_authorization(formData));
          if (response?.payload?.status) {
            setShowPopUp({
              modal: true,
              message: response?.payload?.message,
              id: id,
            });
          }
          break;
        default:
          break;
      }
    } catch (error) {
      console.log("Error:", error);
    }
  };

  // Remove the dispatch of steps to global state
  const renderForm = () => {
    const FormComponent = formComponents[GENERALCONSENTFORM[localStep]];
    return FormComponent ? (
      <FormComponent
        step={localStep}
        subStep={localSubStep}
        totalSubSteps={getSubSteps(GENERALCONSENTFORM[localStep])}
        onSubmit={onSubmit}
   
        setStep={setLocalStep}
        setSubStep={setLocalSubStep}
      />
    ) : null;
  };

  return (
    <section className="step-home-sec container member_vitalinfo_stepone">
      <Header
        currentStep={localStep + 1}
        totalSteps={totalSteps}
        onClickCross={handleCrossClick}
      />
      <div className="pills-steps">
        <SideBar currentStep={localStep} GENERALCONSENTFORM={GENERALCONSENTFORM} />
        <div className="tab-content" id="v-pills-tabContent">
          {renderForm()}
        </div>
      </div>
      <AfterAddMember
        setShowPopUp={setShowPopUp}
        showPopUp={showPopUp}
        type="GeneralConsentAndAuthorization"
      />
    </section>
  );
};

export default GeneralConsentAndAuthorization;