import React, { useState, useEffect } from "react"
import { useDispatch, useSelector } from 'react-redux';
import { get_appointment_edit } from '../../../redux/services/AuthAppointment'
import { formatAMPM, dayDateFormateOpenMAR } from '../../../components/Common/Common'
import { reSetEditAppointmentList } from '../../../redux/features/AuthAppointmentSlice'
import {appointmentStatusOptions} from './CommonData'

const ViewModal = ({ onModalToggle, editModalId, setEditModalId, onDeleteToggal, onTaggalStatus }) => {
	const dispatch = useDispatch();
	const EditAppointmentList = useSelector((state) => state?.AuthAppointment?.EditAppointmentList)
	const AppointmentWithList = useSelector((state) => state?.AuthAppointment?.AppointmentWithList);
	const ExistingLocationList = useSelector((state) => state?.AuthAppointment?.ExistingLocationList)
	const userPermissions = useSelector((state) => state?.common?.userPermissions);
	const currentUser = useSelector(state => state?.AuthSettings?.settingInfo?.general);

	const [editData, setEditData] = useState({})
	const [isExpanded, setIsExpanded] = useState(false);
	const [selectedTime, setSelectedTime] = useState({ start: '', end: '' })
	const [selectedMember, setSelectedMember] = useState([]);
	const [showAll, setShowAll] = useState(false);
	const [isFilterd, setIsFilterd] = useState(true);
	const [appointmentWithName, setAppointmentWithName] = useState('')
	const [isShowCurrentUser, setIsShowCurrentUser] = useState(true)

	useEffect(() => {

		if (EditAppointmentList && Object.keys(EditAppointmentList).length > 0) {
			setEditData(EditAppointmentList)
			let time = EditAppointmentList?.appointment_time.split('-')
			setSelectedTime({ start: time[0], end: time[1] })
			setSelectedMember(EditAppointmentList?.appointmentMemberList
			)
				setIsShowCurrentUser(EditAppointmentList.approval_request_to !== currentUser.id ? true : false)
		}
	}, [EditAppointmentList])

	useEffect(() => {
		if (editModalId !== '') {
			dispatch(get_appointment_edit(editModalId))
		}
		
	}, [editModalId])

	useEffect(()=>{
		if (AppointmentWithList && isFilterd) {			
			let data = AppointmentWithList && AppointmentWithList.find((item) => item.id === editData?.appointment_with)			
			if (data) {
				setAppointmentWithName(`${data.first_name} ${data.last_name}`)
				setIsFilterd(false)
			}
		}
	},[AppointmentWithList, isFilterd, editData])

	const toggleExpand = () => {
		setIsExpanded(!isExpanded);
	};
	const handleEdit = () => {
		setEditModalId('')
		onModalToggle(true)
	}
	const handleCancle = () => {
		setEditModalId('')
		dispatch(reSetEditAppointmentList())
	}
	// Function to handle "View All" click
	const handleViewAllClick = () => {
		setShowAll(!showAll);
	};

	const membersToDisplay = showAll ? selectedMember : selectedMember.slice(0, 5);
	let isEdit = (userPermissions && userPermissions.includes('Edit')) || (userPermissions && userPermissions.includes('Delete'))
	let appointmentStatus = appointmentStatusOptions.find((item) => item.value === editData.appointment_status)
	let location = ExistingLocationList && ExistingLocationList.find((item) => item.id === editData.doctor_location_type)
	console.log(location,'locationlocationlocation', ExistingLocationList, editData.doctor_location_type);
	
	return (
		<div className="modal fade  appoint_ment show" >
			<div className="modal-dialog modal-lg ">
				<div className="modal-content ">
					<div className="modal-header">
						{isEdit && isShowCurrentUser &&
							<h5 className="modal-title" id="exampleModalLabel">
								{userPermissions && userPermissions.includes('Edit') &&
									<svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={() => handleEdit()}>
										<path
											d="M17 9.00006L13 5.00006M1.5 20.5001L4.88437 20.124C5.29786 20.0781 5.5046 20.0551 5.69785 19.9925C5.86929 19.937 6.03245 19.8586 6.18289 19.7594C6.35245 19.6476 6.49955 19.5005 6.79373 19.2063L20 6.00006C21.1046 4.89549 21.1046 3.10463 20 2.00006C18.8955 0.895489 17.1046 0.895488 16 2.00006L2.79373 15.2063C2.49955 15.5005 2.35246 15.6476 2.24064 15.8172C2.14143 15.9676 2.06301 16.1308 2.00751 16.3022C1.94496 16.4955 1.92198 16.7022 1.87604 17.1157L1.5 20.5001Z"
											stroke="#98A2B3" strokeWidth="2" stroke-linecap="round" stroke-linejoin="round" />
									</svg>
								}
								{userPermissions && userPermissions.includes('Delete') &&
									<svg width="20" height="22" viewBox="0 0 20 22" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={() => onDeleteToggal(editData?.id)}>
										<path
											d="M14 5V4.2C14 3.0799 14 2.51984 13.782 2.09202C13.5903 1.71569 13.2843 1.40973 12.908 1.21799C12.4802 1 11.9201 1 10.8 1H9.2C8.07989 1 7.51984 1 7.09202 1.21799C6.71569 1.40973 6.40973 1.71569 6.21799 2.09202C6 2.51984 6 3.0799 6 4.2V5M8 10.5V15.5M12 10.5V15.5M1 5H19M17 5V16.2C17 17.8802 17 18.7202 16.673 19.362C16.3854 19.9265 15.9265 20.3854 15.362 20.673C14.7202 21 13.8802 21 12.2 21H7.8C6.11984 21 5.27976 21 4.63803 20.673C4.07354 20.3854 3.6146 19.9265 3.32698 19.362C3 18.7202 3 17.8802 3 16.2V5"
											stroke="#98A2B3" strokeWidth="2" stroke-linecap="round" stroke-linejoin="round" />
									</svg>
								}
							</h5>
						}
						<button type="button" className="btn-close" onClick={() => handleCancle()}></button>
					</div>
					<div className="modal-body appointment_modal">
						<div className="apnorht_main">
							<div className="apnorht_head">
								<div className="apnorht_home">
									<svg width="20" height="21" viewBox="0 0 20 21" fill="none"
										xmlns="http://www.w3.org/2000/svg">
										<path
											d="M6.12602 13C6.57006 14.7252 8.13616 16 10 16C11.8638 16 13.4299 14.7252 13.874 13M9.0177 1.764L2.23539 7.03912C1.78202 7.39175 1.55534 7.56806 1.39203 7.78886C1.24737 7.98444 1.1396 8.20478 1.07403 8.43905C1 8.70352 1 8.9907 1 9.56505V16.8C1 17.9201 1 18.4801 1.21799 18.908C1.40973 19.2843 1.71569 19.5903 2.09202 19.782C2.51984 20 3.07989 20 4.2 20H15.8C16.9201 20 17.4802 20 17.908 19.782C18.2843 19.5903 18.5903 19.2843 18.782 18.908C19 18.4801 19 17.9201 19 16.8V9.56505C19 8.9907 19 8.70352 18.926 8.43905C18.8604 8.20478 18.7526 7.98444 18.608 7.78886C18.4447 7.56806 18.218 7.39175 17.7646 7.03913L10.9823 1.764C10.631 1.49075 10.4553 1.35412 10.2613 1.3016C10.0902 1.25526 9.9098 1.25526 9.73865 1.3016C9.54468 1.35412 9.36902 1.49075 9.0177 1.764Z"
											stroke="#079455" strokeWidth="2" stroke-linecap="round"
											stroke-linejoin="round" />
									</svg>
								</div>
								<div className="apnorht_head_inner">
									<h4 className="font-18 hd-title">{editData.doctor_specialty}</h4>
									<span className="font-14 hd-subtitle">{dayDateFormateOpenMAR(editData.appointment_date)} {formatAMPM(selectedTime.start)} - {formatAMPM(selectedTime.end)}</span>
								</div>
							</div>
							<div className="apnorht_head_inner">
								<span className="font-14 hd-subtitle">Notification Level</span>
								<h4 className="mb-0 font-14 hd-title text_right_side">{editData.notification_level}</h4>
							</div>
						</div>
						<hr />
						<div className="ortho_item">
							<ul>
								<li>
									<div className="font-14 hd-subtitle">Group Home</div>
									<span className="hd-title font-14">{editData.group_home}</span>
								</li>
								<li>
									<div className="font-14 hd-subtitle">Appointment with</div>
									<span className="hd-title font-14">{appointmentWithName}</span>
								</li>
								<li>
									<div className="font-14 hd-subtitle">Location</div>
									<span className="hd-title font-14">{editData.location}</span>
								</li>
								<li>
									<div className="font-14 hd-subtitle">Phone</div>
									<span className="hd-title font-14">{editData.doctor_mobile}</span>
								</li>
								<li>
									<div className="font-14 hd-subtitle">Status</div>
									<div className="badges"><span className={editData.approval_status}></span>{editData.approval_status}</div>
									{currentUser.id === editData.approval_request_to && editData.approval_status === 'pending' &&
										<button type="button" className="status_button_appointment" onClick={() => onTaggalStatus(editData, 'approval_status')}>Change</button>
									}
								</li>
							</ul>
							<ul>
								<li>
									<div className="font-14 hd-subtitle">Entered By</div>
									<span className="hd-title font-14">{editData.entered_by}</span>
									<div className="apnman">{editData.role}</div>
								</li>
								<li>
									<div className="font-14 hd-subtitle">Specialty</div>
									<span className="hd-title font-14">{editData.doctor_specialty}</span>
								</li>
								<li>
									<div className="font-14 hd-subtitle">Address</div>
									<span className="hd-title font-14">{editData.doctor_address}</span>
								</li>
								<li className="apn_chng">
									<div>
										<div className="font-14 hd-subtitle">Appointment Status</div>
										<div className="status_div">
											<h4 className="sch"><span className={editData.appointment_status}></span> {appointmentStatus ? appointmentStatus.lable : ''}</h4>
											{userPermissions && userPermissions.includes('Edit') && editData.approval_status !== 'pending' &&
												<button type="button" className="status_button_appointment" onClick={() => onTaggalStatus(editData, 'appointment_status')}>Change</button>
											}
										</div>
									</div>

								</li>
							</ul>
						</div>

						<div className="apnt_resion_cont">
							<h4 className="font-14 hd-title">Reason for Appointment</h4>
							<p className="font-14 hd-subtitle">
								{isExpanded ? editData?.appointment_reason : editData?.appointment_reason?.length > 50 ? `${editData?.appointment_reason?.substring(0, 100)}...` : editData?.appointment_reason}
								{editData?.appointment_reason?.length > 50 &&
									<a href="#" onClick={toggleExpand}>
										{isExpanded ? 'View Less' : 'View More'}
									</a>
								}
							</p>
							{isExpanded && (
								<div className="full-content">
									{/* Render full content here, if different from description */}
									<p>{editData?.appointment_reason}</p>
								</div>
							)}
						</div>
						<div className="apn_mem_head">
							<h4 className="font-18 hd-title">Members</h4>
							{selectedMember && selectedMember.length > 4 &&
								<a href="#" className="font-14" onClick={handleViewAllClick}>{showAll ? 'Less View' : 'View All'}</a>
							}
						</div>
						<div className="apnt_para">
							<p className="font-14 hd-subtitle">You’ve scheduled an appointment for the members below</p>
						</div>
						<div className="apnt_member row">
							{membersToDisplay && membersToDisplay.map((item, index) => {
								let shortName = item?.first_name?.charAt(0)?.toUpperCase() + item?.last_name?.charAt(0)?.toUpperCase() || '';
								return (
									<div key={index} className="profile-sec appointment-member col-md-2">
										<span className="name">
											{item?.profile_pic ?
												<img src={item?.profile_pic} alt={`${item.first_name} ${item.last_name}`} /> :
												<img
                                      src="/pro.png"
                                      alt={`'s profile`}
                                    />
											}
										</span>
										<div className="profile-info">
											<p className="font-14 hd-title">{item?.first_name}</p>
										</div>
									</div>
								)
							})}
						</div>
					</div>
				</div>
			</div>
		</div>
	)

}
export default ViewModal