import React, { useEffect } from 'react';

export const Pagination = ({ currentPage, totalPages, onPageChange }) => {
    useEffect(() => {
        if (totalPages === 0 && currentPage !== 1) {
            onPageChange(1);
        }
    }, [totalPages,currentPage]);

    return (
        <div className="paging">
            <button
                type="button"
                className="btn btn-light"
                onClick={() => onPageChange(currentPage - 1)}
                disabled={currentPage === 1}
            >
                Previous
            </button>

            <div className="page-num">Page {currentPage} of {totalPages || 1}</div>

            <button
                type="button"
                className="btn btn-light"
                onClick={() => onPageChange(currentPage + 1)}
                disabled={currentPage === totalPages || totalPages === 0}
            >
                Next
            </button>
        </div>
    );
};
